import { Pressable, StyleSheet, Text, View, Dimensions, ScrollView } from 'react-native';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { infoBodyFlag } from "../../../../redux/actions/creatorActions";
import { t } from "i18n-js";
import InfoSimulationBody from './InfoSimulationBody';
import PlayerSimulationBody from './PlayerSimulationBody';
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { setErrorMessage } from '../../../../redux/actions';

export default function InfoContainer({ infoObj, setInfoObj, setLoad, editGameInfo, err, setErr }) {



  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();

  const infoBody = useSelector(state => state.build.infoBody);

  const images = useSelector(state => state.style.images);
  const background = useSelector(state => state.style.background);
  const color = useSelector(state => state.style.color);

  return (
    <View style={styles.container}>
      {/* header */}
      <View style={[styles.header, { backgroundColor: background.infoHeader }]}>
        <View style={styles.headerLeft}>
          <Pressable
            style={[styles.btnNav, !infoBody && { borderBottomColor: color.infoHeaderNavBorder, borderBottomWidth: widthS * 1.5 }]}
            onPress={() => dispatch(infoBodyFlag())}
          >
            <Text style={[styles.btnNavText, { color: color.infoHeaderNavText }]}>{t("menu_info").toUpperCase()}</Text>
          </Pressable>
          <Pressable
            style={[styles.btnNav, infoBody && { borderBottomColor: color.infoHeaderNavBorder, borderBottomWidth: widthS * 1.5 }]}
            onPress={() => dispatch(infoBodyFlag())}
          >
            <Text style={[styles.btnNavText, { color: color.infoHeaderNavText }]}>{t("players").toUpperCase()}</Text>
          </Pressable>
        </View>
        <View style={styles.headerRight}>
          <Pressable style={[styles.btnSave, { backgroundColor: background.infoSaveBtn }]}
            onPress={() => {
              if (infoObj.simulationName.length > 0 && infoObj.game_goals.length > 0 && infoObj.game_objectives.length > 0) {
                setErr(false);
                editGameInfo();
              } else {
                setErr(true);
              }
            }}
          >
            <Text style={[styles.btnTextSave, { color: color.text2 }]}>{t("save")}</Text>
          </Pressable>
        </View>
      </View>
      {/* body */}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[styles.body, { backgroundColor: background.infoBody }]}
        contentContainerStyle={{ minHeight: "100%" }}
      >
        {!infoBody ? <InfoSimulationBody infoObj={infoObj} setObject={(obj) => setInfoObj(obj)} editGameInfo={editGameInfo} err={err} setErr={setErr} />
          : <PlayerSimulationBody setLoad={setLoad} />}
      </ScrollView>
    </View>
  )
}

const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: heightS * 51
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: widthS * 30,
    },
    headerRight: {
      paddingRight: widthS * 17,
    },
    btnNav: {
      paddingHorizontal: widthS * 12,
      marginRight: widthS * 35,
    },
    btnNavText: {
      fontSize: widthS * 13,
      fontFamily: "Catamaran_500Medium"
    },
    btnSave: {
      paddingHorizontal: "1vw",
      height: heightS * 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 8
    },
    btnTextSave: {
      fontSize: widthS * 12.6,
      fontFamily: "Catamaran_500Medium"
    },
    body: {
      flex: 1,
      height: "100%",
    }
  })
};