import {
  View,
  Text,
  Pressable,
  StyleSheet,
  Image,
  ScrollView,
  TextInput,
} from "react-native";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18n-js";
import * as DocumentPicker from "expo-document-picker";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import { useRef } from "react";
import { getContent } from "../../../../modules/getContent";
import { convertByte } from "../../../../modules/ConvertByte";
import { setErrorMessage } from "../../../../../redux/actions";
import FileLimitPopup from "../../../../Elements/FileLimitPopup";
import { MAX_FILES_SIZE } from "@env";
import EditorTiny from "../../../../editor/EditorTiny";

export default function NewsAction({
  checkActionFields,
  setIsEditing,
  isEditing,
  inject,
  setActionPost,
  isCompletedForm,
  platform_type,
  setAction,
  editAction,
  isPossibletoClose,
  saveRef,
  moveToPlayers,
  closePopup
}) {

  const dispatch = useDispatch();
  const contentRef = useRef(null);

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color, direction);

  const images = useSelector((state) => state.style.images);
  const players = [...useSelector((state) => state.players.realPlayers), ...useSelector((state) => state.players.repPlayers)];

  const postRef = useRef(null);
  const fileRef = useRef(null);

  const [news, setNews] = useState({
    ...inject?.inject || inject,
    to_list: players.map((player) => player.name_for_game).join(","),
    to_list_ids: players.map((player) => player.id).join(","),
    from: {
      name_for_game: sessionStorage.getItem("user_game_name"),
    },
    from_id: sessionStorage.getItem("id_in_game"),
    body: inject?.inject?.body || inject?.body || ""
  });
  const [inputHeight, setInputHeight] = useState(heightS * 75);
  const [err, setErr] = useState("");
  const [showFileLimit, setShowFileLimit] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  let isCompletedNewsForm =
    news.subject && news.body && (news.file?.uri || news.file?.link) && isCompletedForm
      ? true
      : false;

  const checkFields = () => {
    let errText = err;
    if (news.subject.length < 2) {
      if (errText.indexOf("subject,") < 0) {
        errText += "subject,";
      }
    } else {
      errText = errText.replace("subject,", "");
    }
    if (news.body.length < 2) {
      if (errText.indexOf("body,") < 0) {
        errText += "body,";
      }
    } else {
      errText = errText.replace("body,", "");
    }

    if (!news.file?.uri && !news.file?.link) {
      if (errText.indexOf("file,") < 0) {
        errText += "file,";
      }
    } else {
      errText = errText.replace("file,", "");
    }
    setErr(errText);
    if (errText.length === 0) {
      return true
    } else {
      return false;
    }
  }

  const newsImage = (
    <View
      style={[
        styles.newsPhoto,
        {
          backgroundColor: background.infoBody,
          zIndex: 1,
        },
        !platform_type && { marginTop: "0.5vw" },
        err?.indexOf("file,") >= 0 && !news?.file && styles.mail_error
      ]}>
      <Image source={{ uri: images.newsFake }} style={[styles.logo]} />
      {news?.file ?
        (
          <>
            {(isEditing || !platform_type) && (
              <Pressable
                style={[styles.btn_image, { backgroundColor: color.infoBorder }]}
                onPress={() => {
                  setNews({ ...news, file: "" });
                }}
              >
                <Text style={{ color: color.buildTitle, fontSize: widthS * 8 }}>
                  X
                </Text>
              </Pressable>
            )}
            {news.file.mimeType === "video/mp4" || news.file?.type === "/mp4" ?
              <video
                style={{
                  width: "100%",
                  objectFit: "fill",
                  height: heightS * 140,
                }}
                controls
              >
                <source src={news.file?.uri ? news.file.uri : news.file?.link} />
              </video>
              : <Image
                source={{ uri: news.file.uri ? news.file.uri : news.file?.link }}
                style={{ width: "100%", height: "100%", zIndex: 3 }}
                resizeMode="cover"
              />}
          </>
        )
        :
        (<Pressable
          ref={fileRef}
          style={{ zIndex: 5 }}
          disabled={news.file}
          onPress={() =>
            news.file
              ? {}
              : openFilesBrowser(
                (res) => { setNews({ ...news, file: res }); setAction({ ...inject.inject, file: res }) },
                "image/jpg, image/jpeg, image/png, video/mp4",
              )
          }
        >
          {({ hovered }) => (
            <>
              {hovered && (
                <FileLimitPopup
                  refObj={fileRef}
                />
              )}
              <Image
                source={{ uri: images.greyPlusIcon }}
                style={styles.newsPlusIcon}
              />
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  source={{ uri: images.filePhotoIcon }}
                  style={styles.newsPhotoIcon}
                  resizeMode="contain"
                />
                <Text
                  style={[
                    styles.newsAddPhotoText,
                    {
                      color: color.infoHeaderNavText,
                    },
                  ]}
                >{`${t("add_attachment")}`}</Text>
              </View>
            </>
          )}
        </Pressable>)
      }
      <View style={styles.breaking_view}>
        <Text seletable={false} style={styles.breaking_text}>{t("breaking-news")}</Text>
        <View
          style={[
            styles.breaking_triangular,
            { borderBottomColor: background.newsLogo },
          ]}
        />
      </View>
    </View>
  );

  const openFilesBrowser = (
    setValue,
    type = "image/png, image/jpeg, audio/mp3, video/mp4, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, text/tex"
  ) => {
    DocumentPicker.getDocumentAsync({
      type: type,
      allowsEditing: true,
      aspect: [4, 3],
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (size > MAX_FILES_SIZE) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      }
      if (type.indexOf(res.mimeType) < 0) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("news_err_type_files")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      }
      setValue(res)
    });
  };

  const checkOverflow = () => {
    const content = contentRef.current;
    setHasOverflow(content.scrollHeight > content.clientHeight);
  }

  useEffect(() => {
    if (inject.inject) {
      let newNews = {
        ...inject?.inject || inject,
        to_list: players.map((player) => player.name_for_game).join(","),
        to_list_ids: players.map((player) => player.id).join(","),
        from: {
          name_for_game: sessionStorage.getItem("user_game_name"),
        },
        from_id: sessionStorage.getItem("id_in_game"),
        body: inject?.inject?.body || inject?.body || ""
      }
      setNews(newNews);
    }
  }, []);

  useEffect(() => {
    if (saveRef.current) {
      let completed_action = checkActionFields();
      if (checkFields() && completed_action && isCompletedNewsForm) {
        setActionPost(news, "", true).then(() => moveToPlayers()).catch(() => moveToPlayers());;
        saveRef.current = false;
      } else {
        saveRef.current = false;
      }
    }
  }, [saveRef.current, isEditing]);

  return (
    <ScrollView
      onContentSizeChange={checkOverflow}
      style={[styles.bodyC(!news?.is_done && platform_type, hasOverflow), !isEditing && platform_type && styles.bodyCMax]}
      nativeID="mail-players"
      ref={contentRef}
    >
      {isEditing || !platform_type ? (
        <>
          <View
            style={
              isEditing && [
                styles.previewPostMain,
                {
                  backgroundColor: color.infoBorder,
                },
              ]
            }
          >
            {newsImage}
            <View style={isEditing && styles.postBody}>
              {err?.indexOf("subject,") >= 0 && news.subject?.length < 2 && <Text style={styles.errText}>{t("new_game_err_name")}</Text>}
              <View style={[err?.indexOf("subject") >= 0 && news?.subject?.length < 2 ? {} : styles.newsInputContainer]}>
                <TextInput
                  placeholder={t("headline")}
                  placeholderTextColor={color.infoHeaderNavText}
                  value={news.subject || ""}
                  style={[
                    styles.input,
                    {
                      color: color.infoHeaderNavText,
                      backgroundColor: background.infoBody,
                    },
                    err?.indexOf("subject") >= 0 && news?.subject?.length < 2 && styles.mail_error,
                  ]}
                  maxLength={75}
                  onChangeText={(e) => setNews({ ...news, subject: e })
                  }
                />
                <View
                  style={{
                    alignItems: "flex-end",
                    paddingRight: widthS * 5,
                  }}
                >
                  <Text
                    style={[
                      styles.inputLength,
                      {
                        color: color.buildTitle,
                      },
                    ]}
                  >{`${news.subject?.length || 0}/75`}</Text>
                </View>
              </View>
              {err?.indexOf("body,") >= 0 && news.body?.length < 2 && <Text style={styles.errText}>{t("new_game_err_name")}</Text>}
              <View style={[err?.indexOf("body,") >= 0 && news.body?.length < 2 ? {} : styles.newsInputContainer]}>
                <View
                  nativeID="social"
                  style={[
                    styles.input,
                    {
                      height: inputHeight,
                      minHeight: "",
                      resize: "none",
                      color: color.infoHeaderNavText,
                      backgroundColor: background.infoBody,
                      paddingVertical: heightS * 10,
                      overflow: "auto"
                    },
                    err?.indexOf("body,") >= 0 && news?.body?.length < 2 && styles.mail_error,
                  ]}
                >
                  <EditorTiny
                    colorSkin={true}
                    placeholder={t("article")}
                    id={"social-editor"}
                    setText={(txt) => {
                      setNews({ ...news, body: txt });
                    }}
                    refCurrent={postRef}
                    textCurrent={news.body || ""}
                  />
                </View>
              </View>
            </View>
          </View>
          {(isEditing || !platform_type) &&
            <View style={{ flexDirection: "row", alignSelf: "flex-end", alignItems: "center", justifyContent: "center", marginBottom: "1vw" }}>
              {/* <Pressable
                onPress={() => {
                  if (isEditing) {
                    setIsEditing(false);
                  } else {
                    closePopup();
                  }
                }}
                style={[
                  styles.saveBtn, [
                    {
                      borderWidth: 0,
                    },
                  ],
                  { margin: 0 },
                ]}
              >
                <Text
                  style={[
                    styles.saveBtn_text,
                    {
                      color: color.infoHeaderNavText,
                    },
                  ]}
                >
                  {t("cancel")}
                </Text>
              </Pressable> */}
              <Pressable
                onPress={() => {
                  let completed_action = checkActionFields();
                  if (checkFields() && completed_action && isCompletedNewsForm) {
                    setActionPost(news, "", true);
                  }
                }}
                style={[
                  styles.saveBtn,
                  {
                    backgroundColor: background.infoSaveBtn,
                  }
                  ,
                  { margin: 0 },
                ]}
              >
                <Text
                  style={[
                    styles.saveBtn_text, {
                      color: color.text2,
                    }
                  ]}
                >
                  {t("save")}
                </Text>
              </Pressable>
            </View>}
        </>
      ) : (
        <View
          style={[
            styles.previewPostMain,
            {
              backgroundColor: color.infoBorder,
            },
          ]}
        >
          {news.file && newsImage}
          <View style={styles.newsPreviewBody}>
            <Text
              style={[
                styles.newsPreviewBodyTitle,
                { color: color.infoHeaderNavText },
              ]}
            >
              {news?.subject?.toUpperCase()}
            </Text>
            <Text
              style={[
                styles.newsPreviewBodyText,
                { color: color.infoHeaderNavText },
              ]}
            >
              {<div dangerouslySetInnerHTML={{ __html: `<div>${news.body}</div>` }} />}
            </Text>
          </View>
        </View>
      )}
    </ScrollView>
  );
}

const stylesR = (width, height, heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    bodyC: (flag, hasOverflow) => ({
      width: "100%",
      marginTop: flag ? "2vw" : "0.5vw",
      paddingLeft: "3vw",
      paddingRight: hasOverflow ? "2vw" : "3vw"
    }),
    bodyCMax: {
      maxHeight: "29vw"
    },
    rightActionInputs: {
      paddingTop: heightS * 20,
    },
    rightActionInputContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    rightActionInputLabel: {
      fontSize: widthS * 8,
      flex: 1,
    },
    input: {
      height: heightS * 30,
      fontSize: widthS * 8,
      marginVertical: heightS * 5,
      borderRadius: widthS * 9,
      paddingVertical: heightS * 15,
      paddingHorizontal: widthS * 10,
      outlineStyle: "none",
      writingDirection: direction
    },
    newsAddPhotoText: {
      fontSize: widthS * 8,
    },
    newsPhoto: {
      width: "100%",
      height: heightS * 140,
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      height: heightS * 40,
      width: widthS * 56,
      position: "absolute",
      resizeMode: "contain",
      top: heightS * -5,
      left: widthS * 4,
      zIndex: 4,
    },
    newsPhotoIcon: {
      width: widthS * 12,
      height: widthS * 12,
      marginRight: widthS * 7,
    },
    newsSaveBtnContainer: {
      marginTop: heightS * 20,
      borderTopWidth: widthS * 1,
      paddingTop: heightS * 15,
      alignItems: "flex-end",
    },
    breaking_triangular: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderRightWidth: "4vh",
      borderBottomWidth: "4vh",
      borderRightColor: "transparent",
      position: "absolute",
      right: "-3.8vh",
    },
    breaking_view: {
      paddingLeft: widthS * 8,
      paddingRight: widthS * 10,
      backgroundColor: background.newsLogo,
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 3,
      height: "4vh",
      alignItems: "center",
      justifyContent: "center"
    },
    breaking_text: {
      color: "white",
      fontSize: widthS * 11,
      fontFamily: "Catamaran_700Bold",
    },
    saveBtn: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 4,
      borderRadius: widthS * 5,
      alignItems: "center",
      justifyContent: "center",
      marginRight: widthS * 10,
    },
    saveBtn_text: {
      fontSize: 10 * widthS,
    },

    saveBtnUncompleted: {
      borderWidth: 1,
    },
    newsPlusIcon: {
      width: widthS * 50,
      height: widthS * 50,
      marginBottom: heightS * 7,
    },
    newsInputContainer: {
      paddingTop: heightS * 8,
    },
    inputLength: {
      fontSize: widthS * 6,
    },
    newsPreviewBody: {
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 8,
    },
    newsPreviewBodyTitle: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_600SemiBold",
    },
    newsPreviewBodyText: {
      fontSize: widthS * 8,
      paddingTop: heightS * 15,
      fontFamily: "Catamaran_400Regular",
    },
    previewPostMain: {
      borderBottomRightRadius: widthS * 10,
      borderBottomLeftRadius: widthS * 10,
      marginVertical: "1vw",
    },
    postBody: {
      paddingHorizontal: widthS * 10,
      paddingBottom: heightS * 10,
    },
    btn_image: {
      position: "absolute",
      right: widthS * 5,
      top: heightS * 5,
      borderRadius: "50%",
      width: widthS * 15,
      height: widthS * 15,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 4,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: widthS * 10,
    },
    errText: {
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular",
      textAlign: "end"
    },
  });
