import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import i18n, { t } from "i18n-js";
import { useDispatch, useSelector } from "react-redux";

import Login from "./componnets/login/Index";
import Main from "./Main";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DocsProvider from "./ContextData/Docs";
import GameInfoProvider from "./ContextData/GameInfo";
import SystemProvider from "./ContextData/System";
import MailProvider from "./ContextData/Mail";
import NewsProvider from "./ContextData/News";
import SocialProvider from "./ContextData/Social";
import ChatProvider from "./ContextData/Chat";
import GameListProvider from "./ContextData/GamesList";
import ActionsProvider from "./ContextData/Actions";

import { addDomain, addLogo, addFavicon, addProductName, setColors, setInGame, setToken, setCostumColorsClient, addLogoClient, changeLangType, addCountDownVideo, addProductNameForPrivacy, changeLangDomain } from "./redux/actions/index";
import { _F_PHPSESSID_, F_PHPSESSID, GAME_ID } from "./config.inc";
import { DOMAIN, URL_PATH, GET_CLIENT, NOTIFICATION_EMAIL } from "@env";

import SwimlaneChartProvider from "./ContextData/Swimlanechart";
import ResponsiveProvider from "./ContextData/Responsive";
import ResponsiveScreenProvider from "./ContextData/ResponsiveScreen";
import StatisticsFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/StatisticsFunc";
import HeatMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/HeatMapFunc";
import InteractionMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/InteractionMapFunc";
import WordMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/WordsMapFunc";
import AnalyticsProvider from "./ContextData/Analytics";
import AnalyticstimesProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/Analyticstimes";
import { SocketManager } from "./utils/SocketManager";
import { setStatusPlayers } from "./redux/actions/creatorActions";
import { Linking, PanResponder, View } from "react-native";
import { LightenDarkenColor } from "./componnets/modules/LightenDarkenColor";
import { UserContext } from "./ContextData/User";
import UpdateProvider from "./ContextData/Update";
import { getLang } from "./getLang";
import { setErrorMessage } from './redux/actions';
import Satisfaction from "./componnets/login/Satisfaction";

export default function WebSocketProvider() {

	/// Inactivity
	const timerId = useRef(false);
	const { checkSession } = useContext(UserContext);
	const [timeForInactivityInSecond] = useState(900000);
	const dispatch = useDispatch();
	const lang = useSelector(state => state.setting.setting.domainLang);
	const token = useSelector(state => state.loginType.token);
	const loginType = useSelector(state => state.loginType.type);
	const startGame = useSelector(state => state.loginType.startGame);
	const images = useSelector((state) => state.style.images);
	const gameInfoLang = useSelector(state => state.gameInfoArr.gameInfoArr?.game_lang);
	const [typeRefresh, setTypeRefresh] = useState(""); // for type data to refresh
	const [loggedInUsers, setLoggedInUsers] = useState(""); // for logged in users
	const [domain, setDomain] = useState(DOMAIN);
	const gameId = useMemo(() => window.sessionStorage.getItem(GAME_ID), [window.sessionStorage.getItem(GAME_ID)]); // for type data to refresh
	const productName = useSelector(state => state.style.productName);
	const favicon = useSelector(state => state.style.favicon);
	const [reconnect, setReconnect] = useState(false)
	const waitingForPong = useRef(false);
	let numberOfPings = 0;
	const manager = new SocketManager();


	const resetInactivityTimeout = () => {
		clearTimeout(timerId.current)
		timerId.current = setTimeout(() => {
			checkSession().then((res) => {
				if (!res) {
					sessionStorage.clear()
					window.location.reload(false);
				} else {
					resetInactivityTimeout();
				}
			})
		}, timeForInactivityInSecond)
	}

	const getUserDetails = () => ({
		id: window.sessionStorage.getItem("id_in_game") || "default",
		name: window.sessionStorage.getItem("user_game_name") || "default_name",
		room: gameId || "cinten",
	});

	const joinRoom = () => {
		const userDetails = getUserDetails();
		const socket = manager.getSocketInstance();
		socket.emit('join-room', JSON.stringify(userDetails));
		console.log(`Joining room: ${userDetails.room} with ID ${userDetails.id}`);
	};

	const sentMessageWebSocket = (type) => {
		try {
			const { room } = getUserDetails();

			const socket = manager.getSocketInstance();
			socket.emit("refresh", JSON.stringify({ type, room }));

			console.log(`Sending message to room ${room} with type ${type}`);
		} catch (e) {
			console.log(`Sending message failed: ${e}`);
		}
	};

	const refreshConnection = () => {
		console.log("Calling refresh")
		notifySocketIssue();
		// dispatch(
		// 	setErrorMessage(
		// 		true,
		// 		t("websocket_error_title"),
		// 		t("websocket_error_body"),
		// 		null,
		// 		refreshNetwork,))
	}
	const notifySocketIssue = () => {
		const url = `${URL_PATH}${NOTIFICATION_EMAIL}`;
		const body = new FormData();
		body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
		body.append("gameId", gameId);
		body.append("message", "Web-socket is having connection issue, please check server.");
		try {
			fetch(url, {
				method: "POST",
				body: body,
			})
				.then((res) => res.text())
				.then((res) => {
					///in the future we will add some logs
				})
		} catch (e) {
			console.log(e)
		}
	}
	const refreshNetwork = () => {
		window.location.reload();
	}


	const testWebSocket = () => {

		waitingForPong.current = true;
		console.log('Setting waiting for pong to TRUE');
		const socket = manager.getSocketInstance();
		socket.send("ping");
		console.log('Ping was sent on - ', new Date().toLocaleTimeString());

		const intervalRoundelay = setInterval(() => {
			console.log("Timer - ", new Date().toLocaleTimeString())
			if (waitingForPong.current === true) {

				console.log("waitingForPong was TRUE");
				numberOfPings = numberOfPings + 1;
				console.log("Update Pings to - ", numberOfPings);
				if (numberOfPings > 5) {
					refreshConnection();
				} else if (numberOfPings > 1) {
					reconnectWebSocket();
				}
			} else {
				console.log("waitingForPong was FALSE");
				numberOfPings = 0;

				console.log("NumberOfPings setting to 0 from -", numberOfPings);
				clearInterval(intervalRoundelay); // Make sure to stop the interval when your condition is met
			}
		}, 5000);
	};

	const closeWebSocket = () => {
		manager.disconnectSocket();
		console.log("Socket disconnected!")
		console.log("Removed completed")
	};

	const reconnectWebSocket = () => {
		console.log('Calling reconnect');
		console.log('Attempting to reconnect...');
		manager.reconnectSocket();
		joinRoom();
	};

	const handlePong = () => {
		console.log(" Pong received Current time:", new Date().toLocaleTimeString());
		console.log(" set to waiting to pong to false");
		waitingForPong.current = false;
		numberOfPings = 0;
	};

	const pullRouter = (e) => {
		console.log(`In pullRouter: ${e}`);
		setTypeRefresh(e);
	};

	const updateUsersOnline = (e) => {
		console.log("Updating users online:", e);
		dispatch(setStatusPlayers([e]));
	};

	///use effect
	useEffect(() => {
		// Get the current URL of the app
		Linking.getInitialURL().then(domainUrl => {
			if (domain != domainUrl) {
				console.log('Current URL:', domainUrl);
				const url = `${URL_PATH}${GET_CLIENT}`;
				const body = new FormData();
				body.append("domain", domainUrl);
				try {
					fetch(url, {
						method: "POST",
						body: body,
					})
						.then((res) => res.text())
						.then((res) => {
							try {
								res = JSON.parse(res);
								if (res?.language) {
									if (!gameId) {
										dispatch(changeLangType(getLang(res?.language)));
									}
									dispatch(changeLangDomain(getLang(res?.language)));
								}
								if (res?.name) {
									if (res?.domain === "terna.cyberjumanji.innovery.net") {
										dispatch(addDomain("Innovery"));
									} else {
										dispatch(addDomain(res.name));
									}
								} else {
									dispatch(addDomain("cinten"));
								}
								if (res?.logo) {
									dispatch(addLogoClient(res?.logo));
								}
								if (res?.countdown_about_video) {
									dispatch(addCountDownVideo(res?.countdown_about_video));
								}
								dispatch(addFavicon(res?.favicon));
								dispatch(addProductNameForPrivacy(res?.product_name));
								if (res?.domain === "cyberjumanji.innovery.net" || res?.domain === "terna.cyberjumanji.innovery.net") {
									dispatch(addProductName(null));
								}
								if (res?.colors) {
									let colors = res.colors.split(",");
									dispatch(setCostumColorsClient({
										color1: `#${colors[0]}`,
										colorShade1: LightenDarkenColor(`#${colors[0]}`, 0),
										colorCurrent1: `#${colors[0]}`,
										color2: `#${colors[1]}`,
										colorShade2: LightenDarkenColor(`#${colors[1]}`, 0),
										colorCurrent2: `#${colors[1]}`,
										color3: `#${colors[2]}`,
										colorShade3: LightenDarkenColor(`#${colors[2]}`, 0),
										colorCurrent3: `#${colors[2]}`,
									}));
								} else {
									dispatch(setColors());
								}
							} catch (es) {
								console.log(es)
								return;
							}
						})
				} catch (e) {
					console.log(e)
				}
			}


		});

	}, [DOMAIN, loginType, gameId])

	useEffect(() => {
		//set the new name
		if (productName) {
			document.title = productName;
		}
		const currentFavicon = document.querySelector("link[rel='icon']");
		if (currentFavicon) {
			currentFavicon.parentNode.removeChild(currentFavicon);
		}
		if (favicon) {
			const newFavicon = document.createElement('link');
			newFavicon.rel = 'icon';
			newFavicon.href = favicon;
			document.getElementsByTagName('head')[0].appendChild(newFavicon)
		}
	}, [productName, favicon])

	useEffect(() => {
		const currentLang = loginType === "in" ? getLang(gameInfoLang) : lang;
		if (currentLang) {
			i18n.translations = {
				[currentLang]: require(`../translations/${currentLang}.json`),
			};
			i18n.locale = currentLang;
		}
	}, [lang, loginType, gameInfoLang]);

	useEffect(() => {
		if (sessionStorage.getItem(F_PHPSESSID)) {
			resetInactivityTimeout()
		}
	}, [sessionStorage.getItem(F_PHPSESSID)]);

	useEffect(() => {
		console.log("Waiting for pong was change to -  ", waitingForPong.current)
		return;
	}, [waitingForPong.current]);


	// Socket event listeners
	useEffect(() => {
		if (gameId !== null) {

			const socket = manager.getSocketInstance();
			joinRoom();

			// Handling game session
			if (window.sessionStorage.getItem('F_PHPSESSID') && window.sessionStorage.getItem('GAME_ID')) {
				dispatch(setToken(true));
				dispatch(setInGame());
			}
			//declaring socket actions
			socket.on('connect', handlePong);
			socket.on('users', updateUsersOnline);
			socket.on('response', pullRouter);
			socket.on('error', error => { console.error(error); });
			socket.on('connect_error', error => { console.error(error); });
			socket.on('pong', handlePong);
			socket.on('reconnect', handlePong);
			socket.on('disconnect', () => console.log('Disconnect from socket'));
			socket.on('connect_timeout', () => console.log('connect_timeout from socket'));
			socket.on('reconnect_failed', () => console.log('reconnect_failed from socket'));

			const intervalId = setInterval(() => {
				if (numberOfPings === 0) {
					console.log('Condition is true');
					testWebSocket();
				}
			}, 24000); // Interval set for 24 seconds

			return () => {
				clearInterval(intervalId)
				socket.off('connect', () => console.log('off Connected'));
				socket.off('users', () => console.log('off users'));
				socket.off('response', () => console.log('off response'));
				socket.off('error', () => console.log('off error'));
				socket.off('connect_error', () => console.log('off connect_error'));
				socket.off('pong');

				socket.off('reconnect', () => console.log('off users'));
				socket.off('disconnect', () => console.log('off disconnect'));
				socket.off('connect_timeout', () => console.log('off connect_timeout'));
				socket.off('reconnect_failed', () => console.log('off reconnect_failed'));

			};
		}
	}, [gameId]);

	useEffect(() => {
		if (
			window.sessionStorage.getItem(F_PHPSESSID) &&
			window.sessionStorage.getItem(GAME_ID) &&
			loginType !== "end"
		) {
			dispatch(setToken(true));
			dispatch(setInGame());

		}
		if (loginType !== "in" && loginType !== "end") {
			dispatch(setToken(false));

		}
		return;
	}, [loginType]);

	const mainPage = (token, startGame) => {
		return token && startGame
			?
			<SwimlaneChartProvider
				sentMessageWebSocket={sentMessageWebSocket}
				typeRefresh={typeRefresh}
				setTypeRefresh={setTypeRefresh}
				loginType={loginType}
				loggedInUsers={loggedInUsers}
			>
				<SystemProvider
					sentMessageWebSocket={sentMessageWebSocket}
					typeRefresh={typeRefresh}
					loginType={loginType}
				>
					<DocsProvider
						sentMessageWebSocket={sentMessageWebSocket}
						typeRefresh={typeRefresh}
						loginType={loginType}
					>
						<SocialProvider
							sentMessageWebSocket={sentMessageWebSocket}
							typeRefresh={typeRefresh}
							loginType={loginType}
						>
							<MailProvider
								sentMessageWebSocket={sentMessageWebSocket}
								typeRefresh={typeRefresh}
								loginType={loginType}
							>
								<NewsProvider
									sentMessageWebSocket={sentMessageWebSocket}
									typeRefresh={typeRefresh}
									loginType={loginType}
								>
									<ChatProvider
										sentMessageWebSocket={sentMessageWebSocket}
										typeRefresh={typeRefresh}
										loginType={loginType}
									>
										<ActionsProvider
											sentMessageWebSocket={sentMessageWebSocket}
											typeRefresh={typeRefresh}
											loginType={loginType}
										>
											<AnalyticstimesProvider>
												<StatisticsFuncProvider>
													<HeatMapFuncProvider>
														<InteractionMapFuncProvider>
															<WordMapFuncProvider>
																<Main closeWebSocket={closeWebSocket} />
															</WordMapFuncProvider>
														</InteractionMapFuncProvider>
													</HeatMapFuncProvider>
												</StatisticsFuncProvider>
											</AnalyticstimesProvider>
										</ActionsProvider>
									</ChatProvider>
								</NewsProvider>
							</MailProvider>
						</SocialProvider>
					</DocsProvider>
				</SystemProvider>
			</SwimlaneChartProvider> : <Login />
	}

	// version log
	console.log("Version (3.1.3)");

	return (
		<ResponsiveProvider>
			<ResponsiveScreenProvider>
				<UpdateProvider
					loginType={loginType}
					typeRefresh={typeRefresh}
					setTypeRefresh={setTypeRefresh}
				>
					<AnalyticsProvider typeRefresh={typeRefresh}>
						<GameInfoProvider
							sentMessageWebSocket={sentMessageWebSocket}
							typeRefresh={typeRefresh}
							loginType={loginType}
							loggedInUsers={loggedInUsers}
						>
							<GameListProvider>
								{/* <Router>
									<Routes>
										<Route path="/satisfaction" Component={Satisfaction} />
										<Route path="/" Component={(props) => <MainPage {...props} startGame={startGame} token={token} />} />
									</Routes>
								</Router> */}
								{mainPage(startGame, token)}
							</GameListProvider>
						</GameInfoProvider>
					</AnalyticsProvider>
				</UpdateProvider>
			</ResponsiveScreenProvider>
		</ResponsiveProvider>
	);
}

