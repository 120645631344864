import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  URL_PATH,
  GET_EVENT_LIST,
  ADD_OR_EDIT_FLOW_PHASE,
  ADD_OR_ADIT_EVENT,
  GET_FLOW_OBJECT_LIST,
  SEND_MESSAGE_FROM_DRAFT,
  SEND_SOCIAL_MEDIA_FROM_DRAFT,
  SEND_BREAKING_NEWS_FROM_DRAFT,
  START_GAME,
  END_GAME,
  RECALL_ACTION,
  RESET_GAME
} from "@env";
import {
  F_PHPSESSID,
  GAME_ID,
  USER_ID_IN_GAME,
  _ACTIVE_,
  _BCC_LIST_,
  _CC_LIST_,
  _EVENT_,
  _EVENT_ID_,
  _FILE0_,
  _FLOW_BODY_,
  _FLOW_FILE_,
  _FLOW_SUBJECT_,
  _FLOW_TITLE_,
  _FLOW_TYPE_,
  _FROM_ID_,
  _F_PHPSESSID_,
  _ID_,
  _IS_DELETE_,
  _IS_KPI_,
  _MESSAGE_ID_,
  _PHASE_LOCATION_,
  _PHASE_MAX_,
  _PHASE_NUMBER_,
  _PLATFORME_TYPE_,
  _TO_LIST_,
} from "../config.inc";
import {
  setAction,
  setActionCreator,
  setEventsCreator,
  setKpi,
} from "../redux/actions/creatorActions";
import { AnalyticsContext } from "./Analytics";
import { loading, setErrorMessage } from "../redux/actions";
import { t } from "i18n-js";
import { UpdateContext } from "./Update";
import { generateRandomHash } from "../componnets/modules/HashGenerator";

export const SwimlaneChartContext = createContext();

export default function SwimlaneChartProvider(props) {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const events = useSelector((state) => state.events.events);

  const { getSurveyAnalytics } = useContext(AnalyticsContext);
  const { getUpdateData } = useContext(UpdateContext);

  const findTo = (ids) => {
    return ids
      .split(",")
      .map(
        (id) =>
          gameInfo.players.find((player) => player.id === id)?.name_for_game
      )
      .filter((name) => name)
      .join(",");
  };

  const checkIdsInPlayers = (item, gameInfo) => {
    let flag = "";
    if (gameInfo?.players?.length > 0) {
      item.to_list.split(",").forEach((e) => {
        let idExist = false;
        gameInfo.players.forEach((player) => {
          if (e === player.id || e.length == 0) {
            idExist = true;
            return;
          }
        });
        if (!idExist) {
          if (item.to_list.indexOf(`${e},`) >= 0) {
            item.to_list = item.to_list.replace(`${e},`, "");
          } else {
            item.to_list = item.to_list.replace(e, "");
          }
        }
      });
    }
    if (item.to_list.length < 4) {
      flag = "delete";
    } else {
      flag = "edit";
    }
    return {
      newItem: item,
      flag: flag
    }
  }

  const mapActions = (arr) => {
    let kpi = [];
    if (events.length > 1) {
      arr.forEach(async (item, i) => {
        let color = events.find((e) => e.id === item.event_id)?.color;
        item.eventColor = color;
        let from = gameInfo.players.find(
          (player) => player.id === item.from_id
        )?.name_for_game;
        item.from = from;
        let to = findTo(item.to_list);
        item.to = to;
        if (item.cc_list) {
          let cc = findTo(item.cc_list);
          item.cc = cc;
        }
        if (item.bcc_list) {
          let bcc = findTo(item.bcc_list);
          item.bcc = bcc;
        }
        //
        let { newItem, flag } = checkIdsInPlayers(item, gameInfo)
        if (flag) {
          // await addAction(newItem, arr.length - 1, flag === "edit" ? false : true);
        }
        //
        if (item.kpi === "1" || item.kpi && item.kpi !== "0") {
          kpi.push(item);
        }
      });
    }
    return {
      kpi: kpi,
      action: arr,
    };
  };

  const replaceUrlWithHash = (html, filesArr, body) => {
    const div = document.createElement('div');
    if (/^<\w+>.*<\/\w+>$/.test(html?.trim())) {
      div.innerHTML = html;
    } else {
      div.innerHTML = `<div>${html}</div>`;
    }

    const images = div.querySelectorAll('img');

    const imageHashes = [];

    images.forEach((image, i) => {
      const hash = generateRandomHash();
      image.setAttribute('src', hash);

      // Add file contents to object
      const file = filesArr[i];
      imageHashes[i] = hash;

      // Add file to form data
      body.append(`body_image${i}`, file);
    });

    return {
      html: div.innerHTML,
      imageHashes
    };
  };

  const addQuestionnaireToBody = (action, body) => {
    body.append("question_count", action.question_count);
    body.append("questions", JSON.stringify(action.questionsArr || action.questionArr));
    // for (let i = 1; i <= +action.question_count; i++) {
    //   body.append(`q${i}_answer_type`, action[`q${i}_answer_type`]);
    //   body.append(`q${i}_question`, action[`q${i}_question`]);
    //   body.append(`q${i}_answer`, action[`q${i}_answer`]);
    // }
  }

  const getEventsData = () => {
    return new Promise((reject, reslove) => {
      try {
        const url = `${URL_PATH}${GET_EVENT_LIST}`;
        const body = new FormData();
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        const requestOptions = { method: "POST", body: body };
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((response) => {
            response = JSON.parse(response);
            dispatch(setEventsCreator(response.events_list));
            reject(true);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reslove(false);
      }
    });
  };
  const getActionData = () => {
    return new Promise((reject, reslove) => {
      try {
        const url = `${URL_PATH}${GET_FLOW_OBJECT_LIST}`;
        const body = new FormData();
        body.append(_F_PHPSESSID_, sessionStorage.getItem("F_PHPSESSID"));
        const requestOptions = {
          method: "POST",
          body: body,
        };
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((response) => {
            response = JSON.parse(response);
            if (events?.length > 1) {
              const { action, kpi } = mapActions(response.flow_objects);
              dispatch(setActionCreator(action));
              dispatch(setKpi(kpi));
            }
            reject(true);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reslove(false);
      }
    });

  };
  const addEventContext = (event, isDelete, move) => {
    return new Promise((reslove, reject) => {
      let body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      if (event.id && event.id?.indexOf("$") < 0) {
        body.append("id", event.id);
        body.append("action", "edit");
      } else {
        body.append("action", "add");
      }
      if (isDelete) {
        body.append("action", "delete");
      } else if (!move) {
        body.append("name", event.title);
        body.append("description", encodeURIComponent(event.description));
      }
      if (event?.position) {
        body.append("position", event.position);
      }
      if (move) {
        body.append("action", "move");
      }
      try {
        const url = `${URL_PATH}${ADD_OR_ADIT_EVENT}`;
        const requestOptions = {
          method: "POST",
          body: body,
        };
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((response) => {
            response = JSON.parse(response);
            getEventsData();
            props.sentMessageWebSocket("event", "all");
            reslove({ id: response.event_id, name: event.title });
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reslove(false);
      }
    });
  };


  const addAction = (action, length, deleteF) => {
    return new Promise((reslove, reject) => {
      try {
        const body = new FormData();
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        body.append(_TO_LIST_, action.to_list_ids);
        body.append(_EVENT_, action.event);
        body.append(_ACTIVE_, "1");
        body.append(_PHASE_MAX_, length);
        body.append(_FLOW_TYPE_, action.type_action);
        body.append(_EVENT_ID_, action.event_id);
        if (action.from_id !== 'creator') {
          body.append(_FROM_ID_, action.from_id);
        }
        if (deleteF === "delete") {
          body.append(_IS_DELETE_, 1);
        } else {
          body.append(_IS_DELETE_, "0");
        }
        body.append(_FLOW_SUBJECT_, encodeURI(action.subject));
        body.append(_FLOW_TITLE_, encodeURI(action.subject));
        body.append(_PHASE_NUMBER_, action.time);
        body.append(_PHASE_LOCATION_, action.phase_location ? action.phase_location : "0");
        if (action.body_images?.length > 0) {
          let { imageHashes, html } = replaceUrlWithHash(action.body, action.body_images, body);
          body.append("image_hashes", JSON.stringify(imageHashes));
          body.append(_FLOW_BODY_, encodeURI(html));
        } else {
          body.append(_FLOW_BODY_, encodeURI(action.body));
        }
        body.append(_PLATFORME_TYPE_, action.platform_type);

        if (action.id) {
          body.append(_ID_, action.id);
        }
        body.append(_CC_LIST_, action.cc_list_ids);
        body.append(_BCC_LIST_, action.bcc_list_ids);
        if (action.message_id && action.message_id !== action.id && action.type_action !== "cfaQuestionnaire") {
          body.append(_MESSAGE_ID_, action.message_id);
        }
        if (action.is_kpi) {
          body.append(_IS_KPI_, action.platform_type === "sm" ? "1" : "on");
        } else {
          body.append(_IS_KPI_, "0");
        }
        // file
        if (action.platform_type === "mail") {
          if (+action.has_attachments > 0) {
            for (let i = 0; i < +action.has_attachments; i++) {
              body.append(`file${i}`, action.file[i].file);
            }
          }
          if (action?.files_to_remove?.length > 0) {
            body.append("files_to_remove", action.files_to_remove);
          }
        } else {
          if (action.file?.file || Array.isArray(action.file) && action.file[0]?.file) {
            body.append(_FLOW_FILE_, action.file.file || action.file[0]?.file);
          } else if (!action.file || action.file?.length === 0) {
            body.append("remove_file", "1");
          }
        }
        // if (Array.isArray(action.file)) {
        //     action.file.forEach((file, i) => {
        //     body.append(`file${i}`, file.file);
        //   })
        //   body.append(_FLOW_FILE_, action.file.file);
        // } else if (action.file?.file) {
        //   body.append(_FLOW_FILE_, action.file.file);
        // } else if (action.file) {
        //   body.append("file_link", action.file.uri ? action.file.uri : action.file);
        // }
        // \\
        if (action.type_action === "cfaQuestionnaire") {
          addQuestionnaireToBody(action, body);
        }
        const url = `${URL_PATH}${ADD_OR_EDIT_FLOW_PHASE}`;
        const requestOptions = {
          method: "POST",
          body: body,
        };
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((response) => {
            response = JSON.parse(response);
            if (response.phase_saved !== "true") {
              reject(false);
            } else {
              getUpdateData(events);
              props.sentMessageWebSocket("swimlane", "all");
              // getActionData();
              reslove(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reslove(false);
      }
    });
  };

  const editActionData = (action, types) => {
    return new Promise((reslove, reject) => {
      const body = new FormData();
      types.forEach((e) => {
        switch (e) {
          case "is_kpi":
            return body.append("is_kpi", action.is_kpi);
          case "type_action":
            return body.append("type_action", action.type_action);
          case "event_id":
            return body.append("event_id", action.event_id);
          case "from_id":
            return body.append("from_id", action.from_id);
          case "to_list_ids":
            return body.append("to_list_ids", action.to_list_ids);
          case "cc_list_ids":
            return body.append("cc_list_ids", action.cc_list_ids);
          case "time":
            return body.append("time", action.time);
          case "subject":
            return body.append("subject", encodeURI(action.subject));
          case "platform_type":
            return body.append("platform_type", action.platform_type);
          case "file":
            return body.append("file0", action.file.file);
          case "body":
            return body.append("message_body", encodeURI(action.body));
        }
      });
      body.append("id", action.id);
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append(_IS_DELETE_, "0");
      const url = `${URL_PATH}${ADD_OR_EDIT_FLOW_PHASE}`;
      const requestOptions = {
        method: "POST",
        body: body,
      };
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((response) => {
          response = JSON.parse(response);
          if (response.phase_saved !== "true") {
            reject(false);
          } else {
            getUpdateData(events);
            props.sentMessageWebSocket("swimlane", "all");
            // getActionData();
            reslove(true);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  const sendAction = (obj) => {
    return new Promise((reslove, reject) => {
      let url = `${URL_PATH}`;
      if (obj.platform_type === "mail") {
        url += `${SEND_MESSAGE_FROM_DRAFT}`;
      } else if (obj.platform_type === "news") {
        url += `${SEND_BREAKING_NEWS_FROM_DRAFT}`;
      }
      else {
        url += `${SEND_SOCIAL_MEDIA_FROM_DRAFT}`;
      }
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append("message_id", obj.message_id);
      const bodyOptions = { method: "POST", body: body };
      fetch(url, bodyOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res?.error?.indexOf("32") >= 0) {
            dispatch(
              setErrorMessage(
                true,
                t("oops"),
                t("error_flow_32")
              )
            )
            reject(false);
          } else {
            // getActionData();
            getUpdateData(events);
            reslove(true);
            props.sentMessageWebSocket("swimlane", "all");
            if (obj.platform_type === "mail") {
              props.sentMessageWebSocket("mail", "all");
            } else if (obj.platform_type === "news") {
              props.sentMessageWebSocket("news", "all");
            } else if (obj.platform_type === "sm") {
              props.sentMessageWebSocket("social_media", "all");
            }
          }
        }).catch((e) => {
          reject(e);
        })
    })
  }

  const deleteActionsByEvent = (id, type, actions) => {
    return new Promise(async (reslove, reject) => {
      if (actions.length > 0) {
        switch (type) {
          case "delete":
            await actions.forEach(async (action) => {
              if (action.event_id === id) {
                await addAction(action, action.length - 1, "delete");
              }
            });
            return reslove(true);
          case "event":
            await actions.forEach(async (action) => {
              if (action.event_id === id) {
                action.event_id = "";
                action.event = "";
                await addAction(action, action.length - 1, "edit");
              }
            });
            return reslove(true);
        }
      } else {
        reject(false);
      }
    })
  }

  const startGameData = () => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${START_GAME}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      fetch(url, {
        method: "POST",
        body: body,
      }).then((res) => {
        props.sentMessageWebSocket("game_info", "all");
        reslove(true);
      }).catch(() => {
        reject(false);
      })
    });
  }

  const endGameDate = () => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${END_GAME}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      fetch(url, {
        method: "POST",
        body: body,
      }).then((res) => {
        props.sentMessageWebSocket("game_info", "all");
        reslove(true);
      }).catch(() => {
        reject(false);
      })
    });
  }

  const recallFlowObject = (obj) => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${RECALL_ACTION}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append("flow_id", obj.id);
      fetch(url, {
        method: "POST",
        body: body,
      }).then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res?.error?.indexOf("(1)") >= 0) {
            dispatch(setErrorMessage(true, t("oops"), t("error_creator_none_permission")));
            dispatch(loading(false));
          } else {
            if (res?.message_recall !== "false") {
              getUpdateData(events);
              props.sentMessageWebSocket("swimlane", "all");
              if (obj.platform_type === "mail") {
                props.sentMessageWebSocket("mail", "all");
                if (obj?.type_action === "cfaQuestionnaire") {
                  getSurveyAnalytics();
                }
              } else if (obj.platform_type === "news") {
                props.sentMessageWebSocket("news", "all");
              } else if (obj.platform_type === "sm") {
                props.sentMessageWebSocket("social_media", "all");
              }
              reslove(true);
            } else {
              reslove(false);
            }
          }
        }).catch(() => {
          reject(false);
        })
    });
  };

  const resetGame = (obj) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${RESET_GAME}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append("user_action", "reset_game");
      if (obj.delete.checked) {
        body.append("hide_game", "1");
      }
      else if (obj.simulation.checked) {
        body.append("reset_simulation", "1");
      } else {
        if (obj.messages.checked) {
          body.append("reset_messages", "1");
        }
        else {
          if (obj.news.checked) {
            body.append("reset_news", "1");
          }
          if (obj.mail.checked) {
            body.append("reset_emails", "1");
          }
          if (obj.social.checked) {
            body.append("reset_social", "1");
          }
          if (obj.chat.checked) {
            body.append("reset_chats", "1");
          }
        }
        if (obj.challenge.checked) {
          body.append("reset_actions", "1");
        }
        if (obj.questionnaire.checked) {
          body.append("reset_survey", "1");
        }
      }
      if (obj.flow.checked) {
        body.append("reset_flow", "1");
      }
      fetch(url, {
        method: "POST",
        body: body,
      }).then((res) => res.text())
        .then((res) => {
          if (res?.status !== "false") {
            resolve(true);
          } else {
            reject(false);
          }
        }).catch(() => {
          reject(false);
        })
    });
  }

  useEffect(() => {
    let interval;
    if (props.typeRefresh === "event") {
      console.log("refreshing event")
      getEventsData();
      props.setTypeRefresh();
    }
    if (props.typeRefresh === "true") {
      interval = setInterval(() => {
        getActionData();
        getEventsData();
      }, 15000);
    }
    return () => {
      clearInterval(interval);
    }
  }, [props.typeRefresh, events]);

  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setActionCreator([]));
      dispatch(setKpi([]));
      dispatch(setEventsCreator([]));
    }
  }, [props.loginType]);

  useEffect(() => {
    if (gameInfo.game_name && events.length > 1) {
      getActionData();
    }
  }, [gameInfo, events]);

  useEffect(() => {
    if (gameInfo.game_name) {
      getActionData();
      getEventsData();
    }
  }, [gameInfo]);
  return (
    <SwimlaneChartContext.Provider
      value={{
        getActionData,
        getEventsData,
        addEventContext,
        addAction,
        sendAction,
        deleteActionsByEvent,
        editActionData,
        startGameData,
        endGameDate,
        recallFlowObject,
        resetGame
      }}
    >
      {props.children}
    </SwimlaneChartContext.Provider>
  );
}
