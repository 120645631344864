import {
  MARK_CHAT,
  NEW_CHAT,
  NEW_GROUP,
  NEW_MSG_CHAT,
  SET_CHAT,
  SET_CHAT_FLAG,
  DELETE_CHAT,
  SET_GROUPS
} from "../actions/index";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';

const findName = (id, players) => {
  return players?.find((e) => e.id === id);
};

const createObjectChats = (messages, groupsData, players, stateChats, isPlayer) => {
  function checkIfRead(msg) {
    if (!isPlayer && (msg?.is_admin_read === "0" || !msg?.is_admin_read)) {
      return 1
    }
    if (isPlayer && msg?.status === "0") {
      return 1
    }
    return 0;
  }
  let chats = { ...stateChats };
  let count = 0;
  groupsData?.length > 0 && groupsData.forEach((group, index) => {
    group.members.forEach((e) => {
      if (!findName(e, players)?.name_for_game || isPlayer && e !== isPlayer) return;
      if (!chats[e] || !chats[e][group.id]) {
        chats[e] = {
          ...chats[e],
          [group.id]: {
            group: true,
            name: group.name,
            creator_id: group?.creator_id,
            count: null,
            date: null,
            message: null,
            members: group.members,
            members_names: group.members.map((e) => findName(e, players)?.name_for_game && `${findName(e, players)?.name_for_game}, `)
          }
        }
      }
      if (chats[e][group.id].name !== group.name || chats[e][group.id].members !== group.members) {
        chats[e][group.id].name = group.name;
        chats[e][group.id].members = group.members;
        chats[e][group.id].members_names = group.members.map((e) => findName(e, players)?.name_for_game && `${findName(e, players)?.name_for_game}, `);
      }
    });
    if (isPlayer && !group.members.find((e) => e === isPlayer) && chats[isPlayer] && chats[isPlayer][group.id]) {
      delete chats[isPlayer][group.id];
    }
  });
  if (messages?.length > 0) {
    messages.forEach((msg, index) => {
      if (isPlayer && msg.recipient_id !== isPlayer && msg.sender_id !== isPlayer) return;
      if (msg.group_id === "0") {
        if (!findName(msg.recipient_id, players)?.name_for_game) return;
        if ((!chats[msg.sender_id] || !chats[msg.sender_id][msg.recipient_id] || !chats[msg.sender_id][msg.recipient_id]["date"])) {
          chats[msg.sender_id] = { ...chats[msg.sender_id], [msg.recipient_id]: {} };
          chats[msg.sender_id][msg.recipient_id]['date'] = msg.time;
          chats[msg.sender_id][msg.recipient_id]['name'] = findName(msg.recipient_id, players)?.name_for_game;
          chats[msg.sender_id][msg.recipient_id]['message'] = checkIfIsEncodeText(msg.message_body, "string");
          chats[msg.sender_id][msg.recipient_id]['count'] = 0;
          if (!chats[msg.sender_id]['count']) {
            chats[msg.sender_id]['count'] = 0;
          }
        } else {
          if (new Date(msg.time).getTime() > new Date(chats[msg.sender_id][msg.recipient_id].date).getTime()) {
            chats[msg.sender_id][msg.recipient_id].date = msg.time;
            chats[msg.sender_id][msg.recipient_id].message = checkIfIsEncodeText(msg.message_body, "string");
            if (!chats[msg.sender_id][msg.recipient_id].count) {
              chats[msg.sender_id][msg.recipient_id].count = 0;
            }
          }
        }
        if (!chats[msg.recipient_id] || !chats[msg.recipient_id][msg.sender_id]) {
          chats[msg.recipient_id] = { ...chats[msg.recipient_id], [msg.sender_id]: {} };
          chats[msg.recipient_id][msg.sender_id]['date'] = msg.time;
          chats[msg.recipient_id][msg.sender_id]['name'] = findName(msg.sender_id, players)?.name_for_game;
          chats[msg.recipient_id][msg.sender_id]['message'] = checkIfIsEncodeText(msg.message_body, "string");
          if (!chats[msg.recipient_id][msg.sender_id]['count']) {
            chats[msg.recipient_id][msg.sender_id]['count'] = 0;
          }
          if (!chats[msg.recipient_id]["count"]) {
            chats[msg.recipient_id]["count"] = 0;
          }
          chats[msg.recipient_id][msg.sender_id]['count'] = checkIfRead(msg);
          chats[msg.recipient_id]['count'] += checkIfRead(msg);
        } else {
          if (new Date(msg.time).getTime() > new Date(chats[msg.recipient_id][msg.sender_id].date).getTime() || !chats[msg.recipient_id][msg.sender_id].date) {
            chats[msg.recipient_id][msg.sender_id].date = msg.time;
            chats[msg.recipient_id][msg.sender_id].message = checkIfIsEncodeText(msg.message_body, "string");
            chats[msg.recipient_id][msg.sender_id].count += checkIfRead(msg);
            chats[msg.recipient_id]['count'] += checkIfRead(msg);
          }
        }
      } else {
        let group = groupsData.find((e) => e.id === msg.group_id);

        group?.members.forEach((key) => {

          if (msg.group_id === group.id) {
            if (!findName(key, players)?.name_for_game || isPlayer && key !== isPlayer) return;
            if (!chats[key] || !chats[key][msg.group_id]?.date) {
              if (!chats[key][msg.group_id]) {
                chats[key] = { ...chats[key], [msg.group_id]: {} }
              }
              chats[key][msg.group_id].date = msg.time;
              chats[key][msg.group_id]['name'] = group?.name;
              chats[key][msg.group_id]['message'] = checkIfIsEncodeText(msg.message_body, "string");
              if (!chats[key]['count']) {
                chats[key]['count'] = 0;
              }
              if (msg.sender_id !== key) {
                chats[key]['count'] += checkIfRead(msg);
                chats[key][msg.group_id]['count'] += checkIfRead(msg);
              }
            } else {
              if (msg.sender_id !== key) {
                chats[key]['count'] += checkIfRead(msg);
                chats[key][msg.group_id]['count'] += checkIfRead(msg);
              }
              if (new Date(msg.time).getTime() > new Date(chats[key][msg.group_id].date).getTime()) {
                chats[key][msg.group_id].date = msg.time;
                chats[key][msg.group_id].message = checkIfIsEncodeText(msg.message_body, "string");
              }
            }
          }
        });
      }
    });
  }
  if (isPlayer) {
    let countPlayer = 0;
    chats[isPlayer] && Object.keys(chats[isPlayer]).forEach((e) => {
      if (e === "count") return;
      if (!chats[isPlayer][e].count || chats[isPlayer][e].count < 0) return;
      countPlayer += chats[isPlayer][e].count;
    });
    count = countPlayer;
  }
  else {
    Object.keys(chats).forEach((e) => {
      if (!chats[e].count || chats[e].count < 0) return;
      count += chats[e].count;
    });
  }
  Object.keys(chats).forEach((key) => {
    let sort = { ...chats[key] };
    sort = Object.entries(sort)
      .sort((a, b) => {
        if (a[0] === "count" || b[0] === count) return;
        if (new Date(a[1].date).getTime() < new Date(b[1].date).getTime()) {
          return 1
        } else {
          return -1
        }

      }).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    chats[key] = { ...sort };
  });
  // chats = chats;
  return {
    chats,
    countAll: count,
    groups: groupsData
  }
}

const filterDuplicates = (arr) => {
  return arr.filter((item, index) => {
    return arr.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item)) === index;
  });
}

export const chatReducer = (
  state = { chats: {}, unReadMessages: 0, flag: false, allMessages: [], groups: [] },
  action
) => {
  switch (action.type) {
    case SET_CHAT: {
      let newMessages = action.payload?.messages;
      if (state.allMessages.length > 0 && newMessages?.length > 0) {
        newMessages = newMessages.filter((e) => !state.allMessages.find((m) => m.id === e.id));
      }
      let groupsState = state.groups;
      if (action.payload.groups) {
        groupsState = [...action.payload.groups, ...groupsState];
      }
      let { chats, countAll, groups } = createObjectChats(newMessages, groupsState, action.players, state.chats, action.is_player);
      let allMessages = [...state.allMessages];
      if (action.payload?.messages?.length > 0) {
        allMessages = [...allMessages, ...action.payload?.messages.filter((e) => !state.allMessages.find((m) => m.id === e.id))];
      }
      allMessages = filterDuplicates(allMessages);
      allMessages?.sort((a, b) => new Date(a?.time).getTime() - new Date(b?.time));
      allMessages = allMessages.map((m) => {
        m.message_body = checkIfIsEncodeText(m.message_body, "string")
        if (action.is_player && !('status' in m)) {
          return { ...m, status: "0" };
        } else if (!action.is_player && !('is_admin_read' in m)) {
          return { ...m, is_admin_read: "0" };
        }
        return m;
      });
      return { ...state, chats: chats, unReadMessages: countAll, allMessages: [...allMessages], groups: groups };
    }
    case NEW_CHAT: {
      if (state.chats[action.player.sender_id]) {
        state.chats[action.player.sender_id] = {
          [action.player.recipient_id]: {
            name: action.player.name,
            count: 0
          }, ...state.chats[action.player.sender_id]
        }
      } else {
        state.chats[action.player.sender_id] = {
          [action.player.recipient_id]: {
            name: action.player.name,
            count: 0
          }
        };
      }
      return { ...state, chats: { ...state.chats } };
    }
    case DELETE_CHAT: {
      let index = state.chatArr.findIndex((e) => e.players_id === action.payload);
      state.chatArr.splice(index, 1);
      state.listIds = state.listIds.replace(`${action.payload}, `, "");
      return { ...state, chatArr: [...state.chatArr], listIds: state.listIds };
    }
    case MARK_CHAT: {
      if (action.isAdmin && action.group) {
        Object.keys(state.chats).forEach((key) => {
          if (state.chats[key].count && state.chats[key][action.to]) {
            state.chats[key].count -= state.chats[key][action.to].count;
            state.unReadMessages -= state.chats[key][action.to].count;
            state.chats[key][action.to].count = 0;
          }
        })
      } else {
        if (state.chats[action.from] && state.chats[action.from][action.to]) {
          state.chats[action.from].count -= state.chats[action.from][action.to].count;
          state.unReadMessages -= state.chats[action.from][action.to].count;
          state.chats[action.from][action.to].count = 0;

          state.allMessages.forEach(message => {
            // בדיקה אם ההודעה נשלחה מ-to ל-from
            if (message.sender_id === action.to && message.recipient_id === action.from) {
              // בדיקה אם המפתח is_admin_read או is_read קיים, ועדכון שלו בהתאם
              if (action.isAdmin && 'is_admin_read' in message) {
                message.is_admin_read = "1";
              } else if (!action.isAdmin && 'status' in message) {
                message.status = "1";
              }
            }
          });

          return { ...state, unReadMessages: state.unReadMessages, chats: { ...state.chats }, allMessages: [...state.allMessages] };
        }
      }
      return state;
    }
    case NEW_MSG_CHAT: {
      let temp = [];
      temp = [...state.chatArr];
      let chat = {};
      temp.forEach((e, i) => {
        if (e.id === action.id) {
          chat = { ...e };
        }
      });
      chat.newMsg.msgs.push(action.payload);
      temp = temp.filter((e) => e.id !== action.id);
      temp.unshift(chat);
      return { ...state, chatArr: [...temp] };
    }
    case SET_CHAT_FLAG: {
      return { ...state, flag: action.flag };
    }
    default: {
      return state;
    }
  }
};
