import React, { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL_PATH, GET_UPDATE } from "@env";
import { F_PHPSESSID, GAME_ID, USER_GAME_NAME, USER_ID_IN_GAME, _CREATOR_ID_, _FILE0_, _F_PHPSESSID_, _LAST_ID_, _MESSAGE_BODY_, _MESSAGE_ID_, _MESSAGE_PARENT_ID_, _SUBJECT_, _TO_LIST_ } from "../config.inc";
import { addNewSystem, setSystemMessages, addReply, markSystem, setChat, setMail, setSocialMedia, setAction, setNews } from "../redux/actions/index";
import { mapMails } from "./contextFunction";
import { setActionCreator, setKpi } from "../redux/actions/creatorActions";
import { useRef } from "react";

export const UpdateContext = createContext();

export default function UpdateProvider(props) {
    const dispatch = useDispatch();
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const gameInfoPermission = useSelector((state) => state.gameInfoArr.permission);
    const lang = useSelector(state => state.setting.setting.lang);
    const eventsArr = useSelector(state => state.events.events);

    const [events, setEvents] = React.useState([]);

    const isfuncCall = useRef(false);

    const findTo = (ids) => {
        return ids
            .split(",")
            .map(
                (id) =>
                    gameInfo.players.find((player) => player.id === id)?.name_for_game
            )
            .filter((name) => name)
            .join(",");
    };

    const checkIdsInPlayers = (item, gameInfo) => {
        let flag = "";
        if (gameInfo?.players?.length > 0) {
            item.to_list.split(",").forEach((e) => {
                let idExist = false;
                gameInfo.players.forEach((player) => {
                    if (e === player.id || e.length == 0) {
                        idExist = true;
                        return;
                    }
                });
                if (!idExist) {
                    if (item.to_list.indexOf(`${e},`) >= 0) {
                        item.to_list = item.to_list.replace(`${e},`, "");
                    } else {
                        item.to_list = item.to_list.replace(e, "");
                    }
                }
            });
        }
        if (item.to_list.length < 4) {
            flag = "delete";
        } else {
            flag = "edit";
        }
        return {
            newItem: item,
            flag: flag
        }
    }

    const mapActions = (arr, eventsArr) => {
        let kpi = [];
        if (eventsArr?.length > 1) {
            arr.forEach(async (item, i) => {
                let color = eventsArr.find((e) => e.id === item.event_id)?.color;
                item.eventColor = color;
                let from = gameInfo.players.find(
                    (player) => player.id === item.from_id
                )?.name_for_game;
                item.from = from;
                let to = findTo(item.to_list);
                item.to = to;
                if (item.cc_list) {
                    let cc = findTo(item.cc_list);
                    item.cc = cc;
                }
                if (item.bcc_list) {
                    let bcc = findTo(item.bcc_list);
                    item.bcc = bcc;
                }
                //
                let { newItem, flag } = checkIdsInPlayers(item, gameInfo)
                if (flag) {
                    // await addAction(newItem, arr.length - 1, flag === "edit" ? false : true);
                }
                //
                if (item.kpi === "1" || item.kpi && item.kpi !== "0") {
                    kpi.push(item);
                }
            });
        }
        return {
            kpi: kpi,
            action: arr,
        };
    };

    const getUpdateData = (eventsArr) => {
        if (!isfuncCall.current) {
            isfuncCall.current = true;
            return new Promise((resolve, reject) => {
                try {
                    const url = `${URL_PATH}${GET_UPDATE}`;
                    let body = new FormData();

                    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
                    if (sessionStorage.getItem("last_id")) {
                        body.append(_LAST_ID_, sessionStorage.getItem("last_id"));
                    }
                    if (sessionStorage.getItem("last_id_actions")) {
                        body.append("last_id_actions", sessionStorage.getItem("last_id_actions"));
                    }
                    if (sessionStorage.getItem("last_update_time")) {
                        body.append("last_server_update", sessionStorage.getItem("last_update_time"));
                    }
                    const requestOptions = {
                        method: "POST",
                        body: body
                    };
                    fetch(url, requestOptions)
                        .then((res) => res.json())
                        .then((res) => {
                            isfuncCall.current = false;
                            sessionStorage.setItem("last_update_time", res.last_server_update);
                            for (let key in res) {
                                switch (key) {
                                    case "groups":
                                        if (res?.groups?.length > 0) {
                                            dispatch(
                                                setChat(
                                                    { groups: res.groups },
                                                    gameInfoPermission !== "1" ? sessionStorage.getItem(USER_ID_IN_GAME) : false,
                                                    [...gameInfo.players],
                                                )
                                            );
                                        }
                                        break;
                                    case "chat_messages":
                                        if (res?.chat_messages?.length > 0) {
                                            sessionStorage.setItem("last_id", res.chat_messages[res.chat_messages?.length - 1]?.id)
                                            dispatch(
                                                setChat(
                                                    { messages: res.chat_messages },
                                                    gameInfoPermission !== "1" ? sessionStorage.getItem(USER_ID_IN_GAME) : false,
                                                    [...gameInfo.players],
                                                )
                                            );
                                        }
                                        break;
                                    case "messages":
                                        if (res?.messages?.length > 0) {
                                            const playerId = sessionStorage.getItem(USER_ID_IN_GAME);
                                            const { mailMessages, systemMessages } = mapMails(
                                                res.messages,
                                                gameInfo?.players,
                                                playerId,
                                                gameInfoPermission
                                            );
                                            dispatch(setMail(mailMessages, playerId, gameInfoPermission, lang));
                                        }
                                        break;
                                    case "social":
                                        if (res?.social?.length > 0) {
                                            let is_admin = sessionStorage.getItem("create_game_permission") === "1" || gameInfoPermission === "1";
                                            dispatch(setSocialMedia(res.social, sessionStorage.getItem(USER_ID_IN_GAME), gameInfo?.players, is_admin));
                                        }
                                        break;
                                    case "news":
                                        if (res?.news?.length > 0) {
                                            dispatch(setNews(res.news));
                                        }
                                    case "flow_objects":
                                        if (res?.flow_objects?.length > 0) {
                                            const { action, kpi } = mapActions(res?.flow_objects, eventsArr)
                                            if (action?.length > 0) {
                                                dispatch(setActionCreator(action));
                                            }
                                            if (kpi?.length > 0) {
                                                dispatch(setKpi(kpi));
                                            }
                                        }
                                        break;
                                    // case "actions":
                                    //     if (res?.actions?.length > 0) {
                                    //         sessionStorage.setItem("last_id_actions", res.actions[0].id);
                                    //         dispatch(setAction(res.actions, gameInfoPermission, eventsArr));
                                    //     }
                                    //     break;
                                }
                            }
                        }).catch((error) => { isfuncCall.current = false; });
                } catch (e) { isfuncCall.current = false; }
            })
        }
    };

    useEffect(() => {
        setEvents(eventsArr);
    }, [eventsArr]);

    useEffect(() => {
        let myInterval;
        if (props.typeRefresh === "social_media" ||
            props.typeRefresh === "mail" ||
            props.typeRefresh === "news" ||
            props.typeRefresh === "swimlane" ||
            props.typeRefresh === "chat"
            // || props.typeRefresh === "actions"
        ) {
            getUpdateData(events);
            clearInterval(myInterval);
            props.setTypeRefresh()
        }
        if (gameInfo?.game_name?.length > 0 && props.loginType === "in") {
            myInterval = setInterval(() => {
                getUpdateData(events);
            }, 10000);
        }
        return () => {
            clearInterval(myInterval);
        }
    }, [gameInfo?.game_name, props.loginType, props.typeRefresh, events]);

    return <UpdateContext.Provider value={{
        getUpdateData,
    }}>
        {props.children}
    </UpdateContext.Provider>;
}
