import io from 'socket.io-client';
import {PORT, HOST} from '@env';

class SocketManager {
	static instance;
	socket = null;

	constructor() {
		if (!SocketManager.instance) {
			SocketManager.instance = this;
			this._connectSocket();
		}
		return SocketManager.instance;
	}

	_connectSocket() {
		if (this.socket && this.socket.connect()) {
			return;
		}
		this.socket = io(`https://${HOST}:${PORT}`, {
			transports: ['websocket'],
			secure: true,
			timeout: 2000,
			reconnection: false
		});
	}

	reconnectSocket() {
		// this.disconnectSocket();
		this._connectSocket();
	}

	getSocketInstance() {
		return this.socket;
	}

	disconnectSocket() {
		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
			this.socket = null;
		}
	}
}

export {SocketManager};

