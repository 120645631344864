import { Image, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useState, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import { t } from 'i18n-js';
import InjectItem from './InjectItem';
import { useEffect } from 'react';
import CreateAction from '../build/event-components/CreateAction';
import { useRef } from 'react';
import { loading, setErrorMessage } from '../../../../redux/actions';
import onClickEndGameCheck from '../../../Elements/onClickEndGameCheck';
import { editActionCreator } from '../../../../redux/actions/creatorActions';
import { SwimlaneChartContext } from '../../../../ContextData/Swimlanechart';
import AnimationLoading from '../../../modules/AnimationLoading';

export default function Injects() {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const realPlayers = useSelector(state => state.players.realPlayers);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const { sendAction, recallFlowObject } = useContext(SwimlaneChartContext);

    const editAction = useSelector((state) => state.swimlane.editAction);
    const eventsState = useSelector((state) => state.events.events);

    const isPossibletoClose = useRef(null);
    const eventContentRef = useRef(null);

    const { checkEnd } = onClickEndGameCheck();

    const actionsArrState = useSelector((state) => state.swimlane.actions);

    const [flowObjects, setFlowObjects] = useState([]);
    const [flowObjectsSend, setFlowObjectsSend] = useState([]);
    const [showEvent, setShowEvent] = useState(0);
    const [active, setActive] = useState(false);
    const [loadingFlag, setLoading] = useState(true);

    const hideAction = (id) => {
        // let temp = [...actionsArrState];
        // let action = actionsArrState.findIndex((e) => +e.id === +id);
        // temp[action].show = !temp[action].show;
        // filterActions(temp);
    }

    const isReal = (id, players) => {
        return players?.findIndex(p => p.id === id) != -1;
    }

    const filterActions = (arrS) => {
        let sendArr = [...arrS.filter((e) => e.is_done || isReal(e.from_id, realPlayers))];
        let arr = [...arrS.filter((e) => !e.is_done && !isReal(e.from_id, realPlayers))];
        setFlowObjects([...arr]);
        setFlowObjectsSend([...sendArr]);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const recallAction = (action) => {
        dispatch(
            setErrorMessage(
                true,
                t("oops"),
                t("war_recall_action"),
                async (e) => {
                    if (e) {
                        dispatch(loading(true));
                        recallFlowObject(action).then(() => {
                            dispatch(loading(false));
                        }).catch(() => {
                            dispatch(loading(false));
                        });
                    }
                }))
    }

    const sendInject = (action) => {
        dispatch(loading(true));
        sendAction(action).then((res) => {
            if (res) {
                dispatch(loading(false));
            }
        }).catch((err) => {
            dispatch(loading(false));
            dispatch(
                setErrorMessage(
                    true,
                    t("oops"),
                    t("alert_mail_error")
                )
            );
        });
    };

    useEffect(() => {
        if (Array.isArray(actionsArrState)) {
            filterActions(actionsArrState);
        }
    }, [actionsArrState]);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerChild}>
                    <TouchableOpacity style={styles.sendBtn} disabled={true}>
                        <Ionicons
                            name="md-send-outline"
                            style={styles.iconSend}
                        />
                    </TouchableOpacity>
                    <Text style={styles.headerText}>{t("actions").toUpperCase()}</Text>
                </View>
                <TouchableOpacity style={styles.addBtn} onPress={() => {
                    checkEnd(() => {
                        setShowEvent(3);
                        setActive(false);
                        dispatch(
                            editActionCreator({
                                // x: widthS * 65,
                                // y: heightS * 72,
                                message_id: Math.random(),
                            })
                        );
                    })
                }}>
                    <Image source={{ uri: images.plusIcon }} style={styles.icon} />
                </TouchableOpacity>
            </View>
            <View style={styles.main}>
                <ScrollView nativeID='mail-players' style={styles.scroll} contentContainerStyle={styles.scrollBody}>
                    {loadingFlag && flowObjects.length === 0 && flowObjectsSend.length === 0 ?
                        <AnimationLoading flag={true} widget={true} /> :
                        <>
                            {flowObjectsSend?.map((object, index) => {
                                return <InjectItem isReal={isReal(object?.from_id, realPlayers)} objectData={object} index={index} hideAction={(id) => hideAction(id)} setShowEvent={setShowEvent} recall={recallAction} />
                            })}
                            {flowObjects && flowObjects?.length > 0 && <View style={styles.borderBottom} />}
                            {flowObjects?.map((object, index) => {
                                return <InjectItem isReal={isReal(object?.from_id, realPlayers)} objectData={object} index={index} hideAction={(id) => hideAction(id)} setShowEvent={setShowEvent} sendInject={sendInject} recall={recallAction} />
                            })}
                        </>}
                </ScrollView>
            </View>
            <Modal visible={showEvent === 3 || (isNaN(showEvent) && showEvent !== 0) || editAction} transparent={true} animationType="fade">
                <View style={styles.popupContainer}>
                    <CreateAction
                        isPossibletoClose={isPossibletoClose}
                        setLoad={(flag) => dispatch(loading(flag))}
                        key={editAction}
                        show={showEvent}
                        setShow={setShowEvent}
                        events={eventsState}
                        eventContentRef={eventContentRef}
                        setActive={setActive}
                        dashboard={true}
                    />
                </View>
            </Modal>
        </View >
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxHeight: "36vw",
            writingDirection: direction,
        },
        main: {
            backgroundColor: background.infoBox,
            flex: 1,
            borderRadius: "0.8vw",
        },
        scroll: {
            marginVertical: "1vw"
        },
        scrollBody: {
            minHeight: "101%"
        },
        borderBottom: {
            backgroundColor: color.border,
            height: "0.1vw",
            alignSelf: "flex-end",
            width: "97%",
            marginVertical: "0.5vw"
        },
        header: {
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: "0.3vw"
        },
        headerChild: {
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5vw"
        },
        sendBtn: {
            backgroundColor: background.infoBox,
            borderRadius: "100%",
            width: "1.2vw",
            height: "1.2vw",
            alignItems: "center",
            justifyContent: "center"
        },
        addBtn: {
            backgroundColor: color.logoColor,
            borderRadius: "100%",
            width: "1.2vw",
            height: "1.2vw",
            alignItems: "center",
            justifyContent: "center"
        },
        icon: {
            width: "0.6vw",
            height: "0.6vw",
            resizeMode: "contain"
        },
        iconSend: {
            color: color.text2,
            fontSize: "0.6vw"
        },
        headerText: {
            color: color.text2,
            fontSize: "1vw",
            fontFamily: "Catamaran_600SemiBold",
        },
        popupContainer: {
            width: "100%",
            height: "100vh",
            backgroundColor: "#00000075",
            alignItems: "center",
            justifyContent: "center"
        }
    })
}