import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';

import { SET_DOCS, ADD_DOC, SHOW_FILE, SHOW_FILE_FLAG, SET_SHOW_DOCS } from '../actions/index';
export const docsReducer = (state = { filesArr: [], fileShow: {name: "", type: "", link: ""}, flag: false}, action) => {
      switch(action.type){
            case SET_DOCS: {
                  action.payload.forEach((e) => {
                        e.name = checkIfIsEncodeText(e.name, "string");
                        e.description = checkIfIsEncodeText(e.description, "string");
                  })
                  return { ...state, filesArr: action.payload };
            }
            case ADD_DOC: {
                  action.payload.name = checkIfIsEncodeText(action.payload.name, "string");
                  state.filesArr.push(action.payload);
                  return { ...state, filesArr: [...state.filesArr] };
            }
            case SHOW_FILE: {
                  if(action.file){
                        let file = {
                              name: action.file.name,
                              type: action.file.type,
                              link: action.file.link,
                        }
                        return {...state, fileShow: {...file}}
                  }else{
                        return {...state, fileShow: {name: "", type: "", link: ""}};
                  }
            }
            case SET_SHOW_DOCS: {
                  return {...state , flag : action.flag}
            }
            default: {
                  return state;
            }
      }
}