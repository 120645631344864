import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Reducers from './src/redux/reducers/index';
import UserProvider from "./src/ContextData/User";
import { useFonts, Catamaran_200ExtraLight, Catamaran_300Light, Catamaran_400Regular, Catamaran_500Medium, Catamaran_600SemiBold, Catamaran_700Bold, Catamaran_800ExtraBold, Catamaran_900Black } from "@expo-google-fonts/catamaran";

import WebSocketProvider from './src/WebSocketProvider';

const store = createStore(Reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default function App() {

  const [fonts] = useFonts({
    Catamaran_200ExtraLight,
    Catamaran_300Light,
    Catamaran_400Regular,
    Catamaran_500Medium,
    Catamaran_600SemiBold,
    Catamaran_700Bold,
    Catamaran_800ExtraBold,
    Catamaran_900Black
  });
  if (!fonts) {
    return null;
  }
  return (
    <Provider store={store}>
      <UserProvider>
        <WebSocketProvider />
      </UserProvider>
    </Provider>
  );
}

