import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View, ScrollView, Pressable, Text } from "react-native";
import { useSelector } from "react-redux";
import { t } from "i18n-js";
import InputRadio from "../../../InputRadio";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { USER_ID_IN_GAME } from "../../../config.inc";

export default function ListSharePost({
  shareIds,
  setShareIds,
  shareAll,
  shareReal,
  showShareList,
  setShowShareList,
  id,
  buttonRef,
  sharePost,
  triangleStyle,
}) {
  const popupRef = useRef(null);
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const [players, setPlayers] = useState([]);
  const background = useSelector((state) => state.style.background);
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const addAllToShare = (real) => {
    let ids = [];
    if (real) {
      if (!shareReal) {
        ids = [...shareIds];
        const filteredPlayers = players.filter(player => player.is_represented === "0" && !shareIds.includes(player.id));
        filteredPlayers.forEach((e) => {
          if (e.id !== sessionStorage.getItem(USER_ID_IN_GAME)) {
            ids.push(e.id);
          }
        });
        setShareIds(ids);
      } else {
        setShareIds([]);
      }
    } else {
      if (!shareAll) {
        players.forEach((e) => {
          if (e.id !== sessionStorage.getItem(USER_ID_IN_GAME)) {
            ids.push(e.id);
          }
        });
        setShareIds(ids);
      } else {
        setShareIds([]);
      }
    }
  };

  const addToShare = (id) => {
    let ids = shareIds;
    let index = ids.findIndex((e) => e === id);
    if (index >= 0) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setShareIds([...ids]);
  };

  const mapPlayers = () => {
    if (gameInfo) {
      let playersArr = [];
      gameInfo.players.forEach((e) => {
        if (e.permission_for_game === "15") {
          playersArr.push({ id: e.id, name: e.name_for_game, is_represented: e.is_represented });
        }
      });
      setPlayers(playersArr);
    }
  };

  const printOption = (option) => {
    if (option && option.length > 15) {
      return `${option.slice(0, 15)}...`;
    }
    return option;
  };

  useEffect(() => {
    mapPlayers();
  }, [gameInfo]);
  useEffect(() => {
    mapPlayers();
  }, []);
  useEffect(() => {
    window.addEventListener(
      "mousedown",
      (e) => {
        if (
          showShareList &&
          popupRef?.current &&
          buttonRef.current &&
          !buttonRef.current.contains(e.target) &&
          !popupRef?.current?.contains(e.target)
        ) {
          setShowShareList();
        }
      }
      // listenerAddBT(popupRef, buttonRef, showShareList, setShowShareList)
    );
    return () => {
      window.removeEventListener("mousedown", (e) => {
        if (
          showShareList &&
          popupRef?.current &&
          buttonRef.current &&
          !buttonRef.current.contains(e.target) &&
          !popupRef?.current?.contains(e.target)
        ) {
          setShowShareList();
        }
      });
    };
  }, [showShareList]);
  return (
    <View style={styles.container} ref={popupRef}>
      <View
        style={[
          styles.triangle,
          { borderBottomColor: background.socialSearch },
          triangleStyle,
        ]}
      />
      <View
        style={[
          styles.scroll_container,
          { backgroundColor: background.socialSearch },
        ]}
      >
        <ScrollView
          style={[styles.scroll_container_child]}
          contentContainerStyle={styles.scroll_main}
          nativeID="popup-share"
        >
          {players.length > 0 &&
            players.map((e, i) => {
              if (e.id == sessionStorage.getItem(USER_ID_IN_GAME)) return;
              let index = shareIds.findIndex((item) => item === e.id)
              return (
                <Pressable
                  style={styles.share_item}
                  key={i}
                  onPress={() => addToShare(e.id)}
                >
                  {() => (
                    <View
                      style={[
                        styles.share_item_view,
                        { direction: lang === "he" ? "rtl" : "ltr" },
                      ]}
                    >
                      <InputRadio
                        background={background.socialCircleActive}
                        border={background.socialCircle}
                        backgroundBorder={background.socialCircle}
                        borderWidth={widthS * 9}
                        checkd={index >= 0 ? true : false}
                        circleWidth={widthS * 5.5}
                      />
                      <Text selectable={false} style={styles.share_text}>
                        {e.name}
                      </Text>
                    </View>
                  )}
                </Pressable>
              );
            })}
          <View style={[styles.share_all_view, { direction: direction }]}>
            <View>
              <Pressable
                style={[
                  styles.share_all,
                  { direction: lang === "he" ? "rtl" : "ltr" },
                ]}
                onPress={() => addAllToShare()}
              >
                <View style={[styles.share_item_view]}>
                  <InputRadio
                    background={background.socialCircleActive}
                    border={background.socialCircle}
                    backgroundBorder={background.socialCircle}
                    borderWidth={widthS * 9}
                    checkd={shareAll}
                    circleWidth={widthS * 5.5}
                  />
                  <Text selectable={false} style={styles.share_text}>
                    {printOption(t("all_players"))}
                  </Text>
                </View>
              </Pressable>
              <Pressable
                style={[
                  styles.share_all,
                  { direction: lang === "he" ? "rtl" : "ltr" },
                ]}
                onPress={() => addAllToShare(true)}
              >
                <View style={[styles.share_item_view]}>
                  <InputRadio
                    background={background.socialCircleActive}
                    border={background.socialCircle}
                    backgroundBorder={background.socialCircle}
                    borderWidth={widthS * 9}
                    checkd={shareReal}
                    circleWidth={widthS * 5.5}
                  />
                  <Text selectable={false} style={styles.share_text}>
                    {printOption(t("real_players"))}
                  </Text>
                </View>
              </Pressable>
            </View>
            <Pressable
              style={[
                styles.share_btn,
                { backgroundColor: background.socialCircleActive },
                // lang === "he" ? { left: widthS * 10 } : { right: 0 },
              ]}
              onPress={() => {
                sharePost(id, shareIds);
                setShowShareList();
              }}
            >
              <Text selectable={false} style={styles.share_btn_text}>
                {t("share")}
              </Text>
            </Pressable>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) => {
  return StyleSheet.create({
    container: {
      position: "absolute",
      top: heightS * 24,
      right: widthS * -30,
      width: widthS * 120,
      paddingHorizontal: widthS * 2,
      zIndex: 1001,
    },
    triangle: {
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: widthS * 27,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: widthS * 5,
      borderRightWidth: widthS * 5,
      borderBottomWidth: widthS * 8,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 1002,
    },
    scroll_container: {
      maxHeight: heightS * 140,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 6,
      shadowOpacity: 0.6,
      zIndex: 1001,
      marginTop: heightS * 8,
      borderRadius: widthS * 11,
    },
    scroll_container_child: {
      zIndex: 1001,
      borderRadius: widthS * 11,
      paddingVertical: heightS * 3,
      marginVertical: heightS * 3,
    },
    scroll_main: {
      display: "flex",
      flexDirection: "column",
    },
    share_item: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    share_item_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      paddingVertical: heightS * 3,
      paddingLeft: widthS * 7,
    },
    share_all_view: {
      borderTopColor: "#3c3742",
      borderTopWidth: 1,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    share_all: {
      paddingTop: heightS * 2,
    },
    share_text: {
      fontWeight: "normal",
      fontSize: widthS * 8,
      color: "white",
      fontFamily: "Catamaran_400Regular",
    },
    share_btn: {
      // position: "absolute",
      marginHorizontal: "0.2vw",
      padding: widthS * 5,
      borderRadius: widthS * 5,
      paddingVertical: heightS * 2,
    },
    share_btn_text: {
      color: "white",
      fontWeight: "normal",
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 8,
    },
  });
};
