const colorsMain = [
    "#520065",
    "#DE82D8",
    "#FCF000",
    "#007493",
    "#00B192",
    "#D98000",
    "#4D320F",
    "#724673",
    "#006ABC",
];

export const colorForEvent = (index) => {
    if (index >= (colorsMain.length - 1)) {
        return colorsMain[index - (colorsMain.length - 1)];
    } else {
        return colorsMain[index];
    }
}