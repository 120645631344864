import { USER_ID_IN_GAME } from "../../config.inc";
import { SET_GAME_INFO, SET_GAME_PARMISSION, SET_SHOW_GAME_INFO, REMOVE_BACKGROUND_FILE, REMOVE_COUNTDOWN_VIDEO, SET_CLIENTS_FOR_USER } from "../actions/index";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import moment from 'moment-timezone';

const checkStatusPlayer = (player) => {
	switch (player.active) {
		case "false":
			return "#A99BA8";
		case "true":
		case "inactive":
			return "#de82d8";
		case "active":
			return "#25af94";
	}
}

const mapPlayers = (players) => {
	let temp = players;
	// .filter((e) => e.id !== sessionStorage.getItem(USER_ID_IN_GAME));
	temp.forEach((e) => {
		e.color_status = checkStatusPlayer(e);
		e.player_description = e.player_description ? checkIfIsEncodeText(e.player_description, "string") : "";
		e.name_for_game = checkIfIsEncodeText(e.name_for_game, "string");
	})
	return temp;
}

const convertDate = (dateString, timeZone) => {
	// המרת התאריך לאובייקט מסוג moment באמצעות הפונקציה moment.utc
	const utcDate = moment.utc(dateString);

	// בדיקת תקינות התאריך
	if (!utcDate.isValid()) {
		return null; // אם התאריך אינו תקין, החזרת ערך null
	}

	// המרת התאריך לאזור זמן אחר באמצעות הפונקציה tz
	const localDate = utcDate.tz(timeZone);

	// החזרת התאריך בתבנית המבוקשת
	return localDate.format('YYYY-MM-DD HH:mm:ss');
}

const files = [
	{
		type: "audio/mpeg",
		link: "https://s3.eu-west-1.amazonaws.com/dev.be-strategic.solutions/games/1564/d54225b31f8cc3d0c8a12f15dc966118c5f99f0c.mp3",
		name: "audio.mp3"
	},
	{
		type: "video/mp4",
		link: "https://s3.eu-west-1.amazonaws.com/dev.be-strategic.solutions/games/1564/bb589416c631586735c1604c11151c85db29c78c.mp4",
		name: "video.mp4"
	},
	{
		type: "image/png",
		link: "https://s3.eu-west-1.amazonaws.com/dev.be-strategic.solutions/games/1564/e0072b4b9c6d2910cb8ed0611731eab30cbc42be.jpg",
		name: "image.png"
	},
	{
		type: "application/pdf",
		link: "https://s3.eu-west-1.amazonaws.com/dev.be-strategic.solutions/games/1564/b85b507f988f588fb5c34448c0a737d42fb3a88e.pdf",
		name: "Dry-run.pdf"
	},
]

export const gameInfoReducer = (
	state = {
		gameInfoArr: false,
		flag: false,
		permission: "",
		clients: []
	}, action) => {
	switch (action.type) {
		case SET_GAME_INFO: {
			try {
				let gameInfo = {
					background_story: action.payload.background_story ? checkIfIsEncodeText(action.payload.background_story, "string") : " ",
					id: action.payload.id,
					game_name: action.payload.game_name ? checkIfIsEncodeText(action.payload.game_name, "line") : "",
					game_lang: action.payload.game_lang,
					game_goals: action.payload.game_goals ? checkIfIsEncodeText(action.payload.game_goals, "string") : " ",
					game_logo: action.payload.game_logo + '?timestamp=' + new Date().getTime(),
					game_objectives: action.payload.game_objectives?.length > 0 ? checkIfIsEncodeText(action.payload.game_objectives, "line") : " ",
					start_time: convertDate(action.payload.start_time, action.payload?.game_time_zone),
					start_time_current_zone: convertDate(action.payload.start_time, moment.tz.guess()),
					scheduale_start_time: convertDate(action.payload.scheduale_start_time, action.payload?.game_time_zone),
					scheduale_start_time_current_zone: convertDate(action.payload.scheduale_start_time, moment.tz.guess()),
					end_time: convertDate(action.payload.end_time, action.payload?.game_time_zone),
					user_image: false,
					is_demo: sessionStorage.getItem("is_demo") === "true" ? true : false,
					countdown_video: action.payload.countdown_video,
					players: action.payload.players && action.payload.players.length > 0 ? mapPlayers(action.payload.players) : [],
					// \\
					files: action.payload.background_files ? action.payload.background_files : [],
					game_colors: action.payload.game_colors ? action.payload.game_colors : null,
					is_demo: action.payload?.is_demo === "0" ? false : true,
					client_id: action.payload?.client_id ? action.payload?.client_id : null,
					game_time_zone: action.payload?.game_time_zone ? action.payload.game_time_zone : null,
					// \\
				}
				return { ...state, gameInfoArr: gameInfo };
			} catch (e) {
				console.log("//////////////////////////////////////////");
				console.log(e);
				console.log("//////////////////////////////////////////");
			}

		}
		case SET_SHOW_GAME_INFO: {
			return { ...state, flag: action.flag }
		}
		case SET_GAME_PARMISSION: {
			return { ...state, permission: action.permission }
		}
		case REMOVE_BACKGROUND_FILE: {
			let newGameInfo = { ...state.gameInfoArr, files: state.gameInfoArr.files.filter((x) => { return x.id !== action.id }) }
			return { ...state, gameInfoArr: newGameInfo }
		}
		case REMOVE_COUNTDOWN_VIDEO: {
			let parser = document.createElement("a");
			parser.href = state.gameInfoArr.countdown_video;
			let path = parser.pathname.split("/");
			path.pop();
			path = path.join("/") + "/";
			let url = parser.protocol + "//" + parser.hostname + parser?.port + path;
			let newGameInfo = { ...state.gameInfoArr, countdown_video: url };
			return { ...state, gameInfoArr: newGameInfo }
		}
		case SET_CLIENTS_FOR_USER: {
			if (action.payload?.length > 0) {
				return { ...state, clients: action.payload };
			}
			return { ...state, clients: [] };
		}
		default: {
			return state;
		}
	}
};
