import React, { useEffect, useRef, useState, useMemo, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Modal,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import "./scroll.css";
import { EvilIcons, FontAwesome, Ionicons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import ChatList from "./ChatList";
import ChatSendMessage from "./ChatSendMessage";
import ChatFullMessages from "./ChatFullMessages";
import { ChatContext } from "../../../ContextData/Chat";
import { t } from "i18n-js";
import AddChat from "./AddChat";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import { removeType, setTypeMinimize } from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { USER_GAME_NAME, USER_ID_IN_GAME, _LAST_ID_ } from "../../../config.inc";
import ChatCreatorList from "./ChatCreatorList";
import GroupChatPopup from "./GroupChatPopup";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { editDate } from "../../../EditDate";
import AnimationLoading from "../../modules/AnimationLoading";

export default function Chat({ flag, setFlag, setDrag, dashboard }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();


  const dispatch = useDispatch();
  const { sendMessageData, getChatData } = useContext(ChatContext);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color, direction);

  const images = useSelector((state) => state.style.images);

  const popupRef = useRef(null);
  const modalRef = useRef(null);
  const fullChatRef = useRef(null);
  const fullChatViewRef = useRef(null);
  const chatsListRef = useRef(null);
  const btnRefGroup = useRef(null);
  const groupPopupRef = useRef(null);

  const chatDataState = useSelector((state) => state.chatArr.chats);
  const groupsData = useSelector((state) => state.chatArr.groups);
  const allMessages = useSelector((state) => state.chatArr.allMessages);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const playersRep = useSelector(state => state.players.repPlayers);
  const playersReal = useSelector(state => state.players.realPlayers);

  const chatData = useMemo(() => chatDataState, [chatDataState]);

  const [chatToPrint, setChatToPrint] = useState({});
  const [groupsToPrint, setGroupsToPrint] = useState({});
  const [allMessagesState, setAllMessagesState] = useState([]);
  const [indexMessage, setIndexMessage] = useState();
  const [newData, setNewData] = useState({});
  const [dataToPrint, setDataToPrint] = useState({ from: sessionStorage.getItem(USER_ID_IN_GAME), to: null });
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [creatorUserId, setCretorUserId] = useState(sessionStorage.getItem("creatorUserId") ? sessionStorage.getItem("creatorUserId") : sessionStorage.getItem(USER_ID_IN_GAME));
  const [loading, setLoading] = useState(false);

  const search = (txt) => {
    const enteredText = txt.toLowerCase();
    const temp = chatData.filter(
      (e) =>
        e.from_name.toLowerCase().indexOf(enteredText) >= 0 ||
        e.text.toLowerCase().indexOf(enteredText) >= 0
    );
    setChatToPrint(temp);
  };

  const scrollTo = () => {
    setTimeout(() => {
      if (fullChatRef.current && fullChatViewRef.current) {
        fullChatRef.current.scrollTo({
          y: fullChatViewRef.current.getBoundingClientRect().height + 100,
          x: 0,
          behavior: "smooth",
        });
      }
    }, 100);
  }

  const groups = (arr) => {
    let newArr = [];
    arr.map((e) => {
      if (e.group && e.players_id.includes(creatorUserId)) {
        newArr.push(e);
      } else if (!e.group) {
        newArr.push(e);
      }
    })
    return newArr;
  }

  useMemo(() => {
    let newObj = { ...chatData };
    delete newObj.count;
    setChatToPrint(chatData);
    setGroupsToPrint(groupsData);
    setAllMessagesState(allMessages);
  }, [
    chatData,
    groupsData,
    allMessages,
  ]);

  useEffect(() => {
    setIndexFunc();
  }, [indexMessage?.length]);

  const setIndexFunc = (index) => {
    if (indexMessage?.length > 0 || index?.length > 0) {
      let user_id = sessionStorage.getItem(USER_ID_IN_GAME);
      console.log(dataToPrint.from);
      if (Object.keys(chatData)?.length > 0) {
        if (playerPermission === "1") {
          setDataToPrint({ ...dataToPrint, to: indexMessage ? indexMessage : index });
        } else {
          setDataToPrint({ from: user_id, to: indexMessage ? indexMessage : index });
        }
      } else {
        if (playerPermission === "1") {
          setDataToPrint({ ...dataToPrint, to: null });
        } else {
          setDataToPrint({ from: user_id, to: null });
        }

      }
    }
  }

  const createUserDataObj = (id, players) => {
    let obj = {};
    if (players?.length > 0) {
      let player = players.find((e) => e.id === id);
      obj = { ...player };
    }
    return obj
  }

  const resetIndex = (id) => {
    let user_id = id ? id : sessionStorage.getItem(USER_ID_IN_GAME);
    let newObj = { ...chatData[user_id] };
    delete newObj.count;
    let keys = Object.keys(newObj);
    if (keys > 0) {
      setIndexFunc(keys[0]);
      return keys[0];
    } else if (id) {
      return null;
    } else {
      newObj = { ...chatData };
      delete newObj["count"];
      let KeysA = Object.keys(newObj);
      let newObjA = { ...chatData[KeysA[0]] };
      delete newObjA.count;
      let keys = Object.keys(newObjA);
      setIndexFunc(keys[0]);
      return keys[0];
    }
  }

  const printDate = (time, type) => {
    if (time) {
      let timeM = new Date(time);
      let dateNow = new Date().getTime() - timeM.getTime();
      let pestDays = Math.floor(dateNow / 1000 / 60 / 60 / 24);
      if (type === "label") {
        if (pestDays > 0) {
          return editDate(time, "mail", "mail");
        } else {
          return t("today");
        }
      }
      if (pestDays > 0 && type === "list") {
        return [timeM.getDate(), timeM.getMonth() + 1, timeM.getFullYear()].join(".");
      } else {
        return editDate(time, "mail", "clock");

      }
    }
  }

  const HeaderPopup = (
    <TouchableOpacity
      style={{ cursor: "grabbing" }}
      onPressIn={() => setDrag(true)}
    >
      <View style={[styles.header, { borderBottomColor: color.border }]}>
        <View style={styles.headerLeft}>
          <View
            style={[
              styles.logo_view,
              { backgroundColor: background.chatLogoColor },
            ]}
          >
            <Image
              source={{ uri: images.chat }}
              style={styles.logo}
              resizeMode="contain"
            />
          </View>
          <Text selectable={false} style={styles.textLogo}>
            {t("chat")}
          </Text>
        </View>
        <HeaderPopupButtons
          close={() => {
            setFlag();
            dispatch(removeType("chat"));
          }}
          minimize={() =>
            dispatch(
              setTypeMinimize({ name: "chat", type: 1, show: false })
            )}
        />
      </View>
    </TouchableOpacity>
  )

  useEffect(() => {
    if (chatToPrint) {
      setIndexMessage(resetIndex());
    }
  }, []);

  const header = (isGroup) => {
    return isGroup?.group ? (
      <View style={styles.groupHeaderView}>
        <Pressable
          style={
            styles.chatHeader
          }
        >
          {({ hovered }) => (
            <>
              <Text selectable={false} style={styles.groupName}>
                {isGroup.name}
              </Text>
              <Text
                selectable={false}
                numberOfLines={1}
                style={styles.name_players}
              >
                {isGroup.members_names[isGroup.members_names.length - 2] === ","
                  ? isGroup.members_names.slice(0, isGroup.members_names.length - 2)
                  : isGroup.members_names}
              </Text>
              {!showGroupPopup && <View style={[styles.tooltiphoverHeader, { backgroundColor: background.popup1, opacity: hovered ? 1 : 0, display: hovered ? "" : "none" }]}>
                <Text
                  selectable={false}
                  style={styles.name_players}
                >
                  {isGroup.members_names[isGroup.members_names.length - 2] === ","
                    ? isGroup.members_names.slice(0, isGroup.members_names.length - 2)
                    : isGroup.members_names}
                </Text>
              </View>}
            </>
          )}
        </Pressable>
        {(playerPermission === "1" || chatData[dataToPrint?.from] && sessionStorage.getItem(USER_ID_IN_GAME) === chatData[dataToPrint?.from][dataToPrint?.to]?.creator_id)
          &&
          <TouchableOpacity
            ref={btnRefGroup}
            style={{ position: "relative", alignItems: "center", zIndex: 4 }}
            onPress={() => setShowGroupPopup(!showGroupPopup)}
          >
            <Ionicons name={!showGroupPopup ? "settings-outline" : "settings"} size={20} color="white" />
          </TouchableOpacity>}
        {showGroupPopup &&
          <GroupChatPopup
            type={"group"}
            flag={showGroupPopup}
            setFlag={() => setShowGroupPopup(false)}
            btnRef={btnRefGroup}
            setIndex={setIndexMessage}
            setNewData={setNewData}
            chatRef={groupPopupRef}
            fromAndTo={dataToPrint}
            name={chatData[dataToPrint.from][dataToPrint.to].name}
            idsGroup={chatData[dataToPrint.from][dataToPrint.to].members.join(",")}
            right={"-3.5vw"}
            setTo={(data) => {
              setNewData({ ...newData, ...data });
            }}
            resetIndex={resetIndex}
          />}
      </View>
    ) : (
      <View style={[styles.chatHeader]}>
        <Text selectable={false} style={styles.name}>
          {chatToPrint && dataToPrint?.from && dataToPrint?.to && chatToPrint[dataToPrint.from] && chatToPrint[dataToPrint.from][dataToPrint.to]?.name || null}
        </Text>
      </View>
    );
  }

  const Top = (flag) => (
    <View style={styles.top}>
      {playerPermission === "1" && !flag && <View style={styles.rightControlle}>
        <Text selectable={false} style={styles.name}>{t("select_from_player")}</Text>
      </View>}
      <View style={[{ flex: 1 }, dashboard && styles.addChatDashbord]}>
        {playerPermission !== "17" && playerPermission !== "10" &&
          <AddChat
            dashboard={dashboard}
            data={chatToPrint}
            fromAndTo={dataToPrint}
            setIndex={() => setIndexMessage(resetIndex())}
            setTo={(to) => {
              setDataToPrint({ ...dataToPrint, to: to });
              setIndexMessage(to);
            }}
            setNewData={(data) => {
              setNewData({ ...newData, ...data });
            }}
            resetIndex={resetIndex}
          />}
      </View>
      <View style={[styles.headerDragging, flag && styles.topDetailes]}>
        {dataToPrint?.from && dataToPrint?.to && header(chatToPrint[dataToPrint.from] && chatToPrint[dataToPrint.from][dataToPrint.to])}
      </View>
    </View>
  );

  return (
    <View
      style={[styles.main, { backgroundColor: background.popup1 }]}
      ref={popupRef}
    >
      <View style={styles.headerContainer}>
        {/* header */}
        {!dashboard && HeaderPopup}
        {/* top */}
        {!dashboard && Top()}
        {/* body */}
        <View style={styles.body}>
          {/* left */}
          {playerPermission === "1" &&
            <View style={styles.left(dashboard)}>
              <ChatCreatorList
                dashboard={dashboard}
                chatDate={chatData}
                data={[...playersReal, ...playersRep]}
                active={dataToPrint.from}
                setActive={async (id) => {
                  if (chatToPrint[id] && Object.keys(chatToPrint[id]).length > 0) {
                    let newObj = { ...chatData[id] };
                    delete newObj.count;
                    setDataToPrint({ from: id, to: Object.keys(newObj)[0] });
                    setIndexMessage(Object.keys(newObj)[0]);
                  } else {
                    await setDataToPrint({ from: id, to: null });
                    setIndexMessage(resetIndex(id));
                  }
                }
                }
              />
            </View>
          }
          {/* middle/left */}
          <View style={styles.leftMiddle}>
            {<ChatList
              dashboard={dashboard}
              data={chatToPrint}
              index={indexMessage}
              setNewData={(data) => setNewData(data)}
              setIndex={(index) => setIndexMessage(index)}
              scrollTo={scrollTo}
              chatsListRef={chatsListRef}
              dataToPrint={dataToPrint}
              scrollChatsList={() => chatsListRef.current?.scrollTo({ y: 0 })}
              chatData={chatData}
              setTo={(to) => {
                setDataToPrint({ ...dataToPrint, to: to })
                setIndexMessage(to);
              }}
              userData={(id) => createUserDataObj(id, [...playersReal, ...playersRep])}
              printDate={printDate}
            />}
          </View>
          {/* right */}
          <View
            style={[styles.right, { backgroundColor: background.chatBody }]}
          >
            {dashboard && Top(dashboard)}
            {(() => {
              return Object.keys(chatToPrint)?.length > 0 && dataToPrint?.from && dataToPrint?.to && (
                <ChatFullMessages
                  dashboard={dashboard}
                  data={allMessagesState}
                  fromAndTo={dataToPrint}
                  index={indexMessage}
                  fullChatRef={fullChatRef}
                  fullChatViewRef={fullChatViewRef}
                  scrollTo={scrollTo}
                  printDate={printDate}
                  chatCurrent={chatData[dataToPrint.from] && chatData[dataToPrint.from][dataToPrint.to]}
                />
              )
            })()
            }
            {Object.keys(chatToPrint)?.length > 0 && dataToPrint?.to && playerPermission !== "17" && playerPermission !== "10" && (
              <ChatSendMessage
                data={dataToPrint}
                setLoading={setLoading}
                loading={loading}
                isGroup={chatData[dataToPrint.from] && chatData[dataToPrint.from][dataToPrint.to]}
                scrollTo={scrollTo}
                dashboard={dashboard}
              />
            )}
          </View>
        </View>
      </View>
      <AnimationLoading flag={loading} widget={dashboard} action={true} />
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    main: {
      display: "flex",
      zIndex: 1001,
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily: "Roboto",
      width: "100%",
      height: "100%",
      borderRadius: widthS * 15,
    },
    headerContainer: {
      width: "100%",
      height: "100%"
    },
    header: {
      paddingLeft: widthS * 20,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    top: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      // paddingVertical: heightS * 6,
      zIndex: 200,
    },
    headerDragging: {
      cursor: "default",
      flex: 1.75,
      width: "100%"
    },
    topDetailes: {
      backgroundColor: background.popup1,
      borderTopRightRadius: "1vw",
      height: "3.6vw"
    },
    rightControlle: {
      width: widthS * 130,
      borderRightColor: color.border,
      borderRightWidth: widthS * 1,
      alignItems: "center",
      height: "100%",
      justifyContent: "center"
    },
    body: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      zIndex: 100,
      borderRadius: 25,
      writingDirection: "ltr"
    },
    left: (flag) => ({
      minWidth: flag ? "15vw" : "0",
      width: !flag ? "13vw" : "15vw",
      display: "flex",
      zIndex: 101,
    }),
    leftMiddle: {
      flex: 1,
      display: "flex",
      zIndex: 101,
    },
    right: {
      flex: 1.75,
      borderBottomRightRadius: widthS * 15,
      zIndex: 200
    },
    groupHeaderView: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1vw"
    },
    chatHeader: {
      paddingLeft: widthS * 19,
      maxWidth: "90%",
      paddingRight: widthS * 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      zIndex: 2,
      minHeight: heightS * 35,
      flex: 1
    },
    tooltiphoverHeader: {
      top: "1.8vw",
      left: "1vw",
      zIndex: 2,
      width: "27vw",
      minHeight: "3vw",
      borderRadius: "0.6vw",
      position: "absolute",
      padding: "0.4vw",
      shadowColor: "black",
      shadowOpacity: 0.7,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.5s"
    },
    groupName: {
      fontSize: "1vw",
      lineHeight: widthS * 12,
      fontFamily: "Catamaran_600SemiBold",
      color: "white",
    },
    name: {
      fontSize: "1vw",
      lineHeight: "1.2vw",
      fontFamily: "Catamaran_600SemiBold",
      color: "white",
    },
    name_players: {
      fontSize: "0.8vw",
      lineHeight: "0.9vw",
      fontFamily: "Catamaran_300Light",
      color: "white",
    },
    addChatDashbord: {
      position: "absolute",
      top: "-2.5vw",
      ...(
        direction !== "rtl" ?
          { right: "0.5vw", } :
          { left: "-32.5vw", }
      ),
      zIndex: 1,
      width: "20vw"
    },
  });
