import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  ImageBackground,
} from "react-native";
import Dragged from "../modules/Dragged";
import i18n from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import {
  changePosition,
  setTypeMinimize,
  setErrorMessage,
  setNewSystemFlag,
  setShowActions,
  setShowDocs,
  setShowGameInfo,
  setShowSystemMessages,
  setIndexCurrent,
  setPositions,
} from "../../redux/actions";
import { changeBuildFlag, changeBuildSimulationFlag, changeDashboardFlag, changeInfoFlag, showAnalytics } from "../../redux/actions/creatorActions";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import { t } from "i18n-js";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { isColorTooLight } from "../modules/CheckIfColorTooLight";

export default function Left_Menu({ backgroundRef }) {
  const view = useRef(null);

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const items = useSelector((state) => state.position.items);
  const showGameInfo = useSelector((state) => state.gameInfoArr.flag);
  const showActions = useSelector((state) => state.actionsArr.flag);
  const showSystem = useSelector((state) => state.systemMessages.flag);
  const showDocs = useSelector((state) => state.filesArr.flag);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const buildflag = useSelector((state) => state.build.flag);
  const popupsCount = useSelector((state) => state.loginType.popupsCount);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const [itemsArr, setItemsArr] = useState([]);
  const [opacity, setOpacity] = useState(false);
  const [colorBtn, setColorBtn] = useState("");

  const popupBtnClick = (flag, setFlag, type, num, setNewMailFlag = false) => {
    if (!flag && popupsCount >= 3) {
      return dispatch(
        setErrorMessage(
          true,
          t("oops"),
          t("popup_error")
        )
      );
    }
    if (minimizePopups.find((e) => e.name === type && !e.show)) {
      dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: num }));
      dispatch(setIndexCurrent(num));
    } else if (minimizePopups.find((e) => e.name === type && e.show)) {
      dispatch(setTypeMinimize({ name: type, type: 1, show: false, num: num }));
    } else {
      dispatch(setFlag(true));
      dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: num }));
      dispatch(setIndexCurrent(num));
    }
  };

  const printTitle = (title) => {
    if (title && title.length > 19) {
      return `${title.slice(0, 19)}...`;
    } else {
      return title;
    }
  };

  const printBtn = (type) => {
    switch (type) {
      case "simulation":
        return (
          <Pressable
            style={{
              width: widthS * 80,
            }}
            onPress={() => {
              if (playerPermission === "1") {
                dispatch(changeBuildFlag(false));
                dispatch(showAnalytics(false));
                dispatch(changeInfoFlag(true));
                dispatch(changeBuildSimulationFlag(!buildflag));
              } else {
                popupBtnClick(showGameInfo, setShowGameInfo, "simulation", 4)
              }
            }
            }
          >
            {({ pressed, hovered, focused }) => (
              <View style={styles.view_btn}>
                <View
                  style={[
                    styles.btn_img,
                    { backgroundColor: colorBtn },
                  ]}
                >
                  <ImageBackground
                    source={{ uri: images.simulationInfo }}
                    style={styles.img}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  selectable={false}
                  style={hovered ? styles.text_hover : styles.text}
                >
                  {printTitle(i18n.t("menu_info"))}
                </Text>
              </View>
            )}
          </Pressable>
        );
      case "dashboard":
        if (playerPermission === "1") return (
          <Pressable
            style={{
              width: widthS * 80,
            }}
            onPress={() => {
              dispatch(changeInfoFlag(false));
              dispatch(showAnalytics(false));
              dispatch(changeBuildFlag(false));
              dispatch(changeDashboardFlag(true));
              dispatch(changeBuildSimulationFlag(!buildflag));
            }}
          >
            {({ pressed, hovered, focused }) => (
              <View style={[styles.view_btn]}>
                <View
                  style={[
                    styles.btn_img,
                    { backgroundColor: colorBtn },
                  ]}
                >
                  <ImageBackground
                    source={{ uri: images.grid }}
                    style={styles.img}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  selectable={false}
                  style={hovered ? styles.text_hover : styles.text}
                >
                  {printTitle(
                    `${i18n.t("menu_dashboard")}`
                  )}
                </Text>
              </View>
            )}
          </Pressable>
        );
        return;
      case "actions":
        return (
          <Pressable
            style={{
              width: widthS * 80,
            }}
            onPress={() => popupBtnClick(showActions, setShowActions, "challenges", 5)}
          >
            {({ pressed, hovered, focused }) => (
              <View style={[styles.view_btn]}>
                <View
                  style={[
                    styles.btn_img,
                    { backgroundColor: colorBtn },
                  ]}
                >
                  <ImageBackground
                    source={{ uri: images.action }}
                    style={styles.img}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  selectable={false}
                  style={hovered ? styles.text_hover : styles.text}
                >
                  {printTitle(i18n.t("menu_challenge"))}
                </Text>
              </View>
            )}
          </Pressable>
        );
      // case "system":
      // return (
      //   <Pressable
      //     style={{
      //       width: widthS * 80,
      //     }}
      //     onPress={() =>
      //       popupBtnClick(showSystem, setShowSystemMessages, "system", 7, setNewSystemFlag)
      //     }
      //   >
      //     {({ pressed, hovered, focused }) => (
      //       <View style={[styles.view_btn]}>
      //         <View
      //           style={[
      //             styles.btn_img,
      //             { backgroundColor: color.buttonMenuLeft },
      //           ]}
      //         >
      //           <ImageBackground
      //             source={{ uri: images.system }}
      //             style={styles.img}
      //             resizeMode="contain"
      //           />
      //         </View>
      //         <Text
      //           selectable={false}
      //           style={hovered ? styles.text_hover : styles.text}
      //         >
      //           {printTitle(
      //             `${i18n.t("menu_messages")} ${i18n.t("menu_system")}`
      //           )}
      //         </Text>
      //       </View>
      //     )}
      //   </Pressable>
      // );
      case "files":
        return (
          <Pressable
            style={{
              width: widthS * 80,
            }}
            onPress={() => popupBtnClick(showDocs, setShowDocs, "files", 8)}
          >
            {({ pressed, hovered, focused }) => (
              <View style={[styles.view_btn]}>
                <View
                  style={[
                    styles.btn_img,
                    { backgroundColor: colorBtn },
                  ]}
                >
                  <ImageBackground
                    source={{ uri: images.files }}
                    style={styles.img}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  selectable={false}
                  style={hovered ? styles.text_hover : styles.text}
                >
                  {printTitle(i18n.t("menu_my-files"))}
                </Text>
              </View>
            )}
          </Pressable>
        );
      case "build":
        if (playerPermission === "1") return (
          <Pressable
            style={{
              width: widthS * 80,
            }}
            onPress={() => {
              dispatch(changeInfoFlag(false));
              dispatch(showAnalytics(false));
              dispatch(changeBuildFlag(true));
              dispatch(changeBuildSimulationFlag(!buildflag));
            }}
          >
            {({ pressed, hovered, focused }) => (
              <View style={[styles.view_btn]}>
                <View
                  style={[
                    styles.btn_img,
                    { backgroundColor: colorBtn },
                  ]}
                >
                  <ImageBackground
                    source={{ uri: images.build }}
                    style={styles.img}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  selectable={false}
                  style={hovered ? styles.text_hover : styles.text}
                >
                  {printTitle(
                    i18n.t("menu_build")
                  )}
                </Text>
              </View>
            )}
          </Pressable>
        );
      case "analysis":
        if (playerPermission === "1") {
          return (
            <Pressable
              style={{
                width: widthS * 80,
              }}
              onPress={() => {
                if (playerPermission === "1") {
                  dispatch(changeBuildFlag(false));
                  dispatch(changeInfoFlag(false));
                  dispatch(showAnalytics(true));
                  dispatch(changeBuildSimulationFlag(!buildflag));
                } else {
                  popupBtnClick(showGameInfo, setShowGameInfo, "simulation", 4)
                }
              }
              }
            >
              {({ pressed, hovered, focused }) => (
                <View style={styles.view_btn}>
                  <View
                    style={[
                      styles.btn_img,
                      { backgroundColor: colorBtn },
                    ]}
                  >
                    <ImageBackground
                      source={{ uri: images.analyticsIcon }}
                      style={styles.img}
                      resizeMode="contain"
                    />
                  </View>
                  <Text
                    selectable={false}
                    style={hovered ? styles.text_hover : styles.text}
                  >
                    {printTitle(`${i18n.t("analysis")}`)}
                  </Text>
                </View>
              )}
            </Pressable>
          );
        }
    }
  };

  const checkPlaceX = (obj, index) => {
    switch (obj.type) {
      case "left":
        return itemsArr[index].x - obj.x >= 0 ? itemsArr[index].x - obj.x : 0;
      case "right":
        return itemsArr[index].x + obj.x <= 8 ? itemsArr[index].x + obj.x : 8;
    }
  };
  const checkPlaceY = (obj, index) => {
    switch (obj.type) {
      case "up":
        return itemsArr[index].y - obj.y >= 0 ? itemsArr[index].y - obj.y : 0;
      case "down":
        return itemsArr[index].y + obj.y <= 4 ? itemsArr[index].y + obj.y : 4;
    }
  };

  useEffect(() => {
    if (playerPermission?.length > 0) {
      dispatch(setPositions(playerPermission === "15"));
    }
  }, [playerPermission]);

  useEffect(() => {
    setItemsArr([...items]);
  }, [items]);

  useEffect(() => {
    if (color.buttonMenuLeft) {
      // let middle = isColorTooLight(color.buttonMenuLeft);
      setColorBtn(color.buttonMenuLeft);
    }
  }, [color.buttonMenuLeft]);
  return (
    <View style={styles.container} ref={view}>
      {
        // backgroundRef?.current &&
        itemsArr.map((e, i) => {
          return (
            <Dragged
              x={e.x}
              y={e.y}
              items={itemsArr}
              key={i}
              index={i}
              backgroundRef={backgroundRef}
              opacity={opacity && e.type === opacity ? 0.3 : opacity}
              setOpacity={() => setOpacity("")}
              changePositionApp={(x, y, index) => {
                dispatch(changePosition(x, y, index));
              }}
              checkPlaceX={checkPlaceX}
              checkPlaceY={checkPlaceY}
              renderItem={printBtn(e.type)}
            />
          );
        })}
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      height: "100%",
      width: "100%",
      flex: 1,
      paddingTop: heightS * 15,
    },
    view_btn: {
      display: "flex",
      alignItems: "center",
      borderRadius: 3,
    },
    view_btn_hover: {
      display: "flex",
      alignItems: "center",
      // paddingTop: size * 10,
      // width: size * 80,
      // paddingVertical: size * 3,
      // paddingHorizontal: size * 3
    },
    btn_img: {
      width: widthS * 40,
      height: widthS * 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 50,
      marginBottom: heightS * 3,
    },
    img: {
      height: widthS * 18,
      width: widthS * 18,
    },
    text: {
      color: "white",
      fontWeight: "normal",
      width: widthS * 55,
      textAlign: "center",
      fontSize: widthS * 8.5,
      lineHeight: heightS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    text_hover: {
      color: "white",
      fontWeight: "500",
      width: widthS * 55,
      textAlign: "center",
      fontSize: widthS * 8.5,
      lineHeight: heightS * 10,
      fontFamily: "Catamaran_600SemiBold",
    },
  });
};
