import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Pressable,
  TextInput,
  Platform,
  Image,
  Linking,
  TouchableOpacity,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { WebView } from "react-native-webview";
import { Feather } from "@expo/vector-icons";
import { editDate } from "../../../EditDate";
import { t } from "i18n-js";
import { GenerateId } from "../../modules/GenerateId";
import { USER_GAME_NAME } from "../../../config.inc";
import {
  minimizeNewSystem,
  minimizeNewMail,
  showFile,
  setTypeMinimize,
  setShowActions,
  showNewAction,
  setErrorMessage,
  loading,
  minimizeDashbord,
} from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import PlayerSelect from "../../Elements/PlayerSelect";
import QuestionairePlayer from "./QuestionairePlayer";
import { checkIfIsEncodeText } from '../../modules/removeSpacialCherectersAndSpaes';
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import TextCustomize from "../../Elements/TextCustomize";
import { MailContext } from "../../../ContextData/Mail";

export default function FullMail({
  message,
  type,
  inboxSentDrafts,
  setType,
  searchMain,
  setSearchResult,
  setMessagesArr,
  setIndexMessage,
  setShowShearchPopup,
  showSearchPopup,
  dashboard,
  showFullMessage,
  setActiveMessageDashboard,
  setShowNewMailDashboard,
  minimizeMail
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const { sentMail } = useContext(MailContext);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color, direction);

  const { checkEnd } = onClickEndGameCheck();

  const toPlayersTextRef = useRef(null);
  const ccPlayersTextRef = useRef(null);
  const bccPlayersTextRef = useRef(null);
  const scrollRef = useRef(null);
  const popupRefList = useRef(null);
  const searchRef = useRef(null);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);
  const popupsCount = useSelector((state) => state.loginType.popupsCount);
  const events = useSelector((state) => state.events.events);
  const minimizeNewMailState = useSelector(
    (state) => state.minimize.minimizeNewMail
  );
  const minimizeNewSystemState = useSelector(
    (state) => state.minimize.minimizeNewSystem
  );
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const players =
    useSelector((state) => state.gameInfoArr.gameInfoArr.players) || [];

  const [isEllipsisTo, setIsEllipsisTo] = useState(false);
  const [isEllipsisCc, setIsEllipsisCc] = useState(false);
  const [isEllipsisBcc, setIsEllipsisBcc] = useState(false);
  const [isScrollableMessage, setIsScrollableMessage] = useState(false);


  const checkNewMessageOpening = (arr, doFunc) => {
    if (arr?.length > 1) {
      dispatch(
        setErrorMessage(
          true,
          undefined,
          `${t("err_max_newMail_popup")}`,
        )
      );
    } else {
      doFunc();
    }
  }

  const checkIfIsCallForAction = (messageBody) => {
    let div = document.createElement("div");
    div.innerHTML = messageBody;
    div.querySelectorAll('a[href="javascript:void(openNewActionIframe(1));"]').forEach(anchor => {
      anchor.id = "callChallenges";
    });
    return div.innerHTML;
  };

  // components

  const header = (dashboard) => {
    return !dashboard ? (
      <View
        style={styles.header(dashboard)}
      >

        {/* search */}
        <Pressable style={styles.search_press_btn}>
          {({ pressed, hovered, focused }) => (
            <View
              style={[
                styles.search_press,
                !showSearchPopup?.flag ? styles.showSearchPopup : styles.hideSearchPopup
              ]}
            >
              <TextInput
                selectTextOnFocus={false}
                placeholder={`${t("search")}`}
                placeholderTextColor={"#a89da8"}
                ref={searchRef}
                onKeyPress={(e) => {
                  if (e?.code === "Enter" && showSearchPopup?.subject?.length > 0) {
                    setShowShearchPopup({ ...showSearchPopup, flag: false, type: "subject" });
                  }
                }}
                style={styles.search_input}
                onChangeText={(txt) => {
                  let temp = searchMain(txt);
                  if (txt?.length === 0) {
                    setMessagesArr(temp.arr);
                    setIndexMessage(temp.arr[0]?.id);
                    setShowShearchPopup({ ...showSearchPopup, flag: false });
                  } else {
                    setShowShearchPopup({ ...temp, flag: true });
                  }
                }}
              />
              <Image
                style={styles.search_icon}
                source={{ uri: images.searchIcon }}
              />
              {showSearchPopup?.flag &&
                <View style={styles.popupSearch}>
                  <PlayerSelect
                    styleType={{ dark: false, type: "search", check: false }}
                    styles={styles}
                    playersList={showSearchPopup}
                    setShowShearchPopup={() => setShowShearchPopup({ ...showSearchPopup, flag: false })}
                    setSearchResult={(arr, type, id) => setShowShearchPopup({ ...showSearchPopup, type: type, id: id, flag: false })}
                    popupRefList={popupRefList}
                    setIndexMessage={setIndexMessage}
                  />
                </View>}
            </View>
          )}
        </Pressable>
        {/* add-btn */}
        {playerPermission !== "17" && playerPermission !== "10" && (
          <Pressable
            style={styles.new_btn}
            onPress={() => {
              checkEnd(() => {
                if (type === "system") {
                  checkNewMessageOpening(minimizeNewSystemState, () => {
                    dispatch(
                      minimizeNewSystem({
                        show: true,
                        id: GenerateId(),
                        type: "",
                        message: null,
                      })
                    );
                  });
                } else if (type === "mail") {
                  checkNewMessageOpening(minimizeNewMailState, () => {
                    dispatch(
                      minimizeNewMail({
                        maximize: false,
                        show: true,
                        id: GenerateId(),
                        type: "",
                        message: null,
                      })
                    );
                  });
                }
              })
            }}
          >
            <Text
              selectable={false}
              style={styles.new_text}
            >
              +
            </Text>
            <Text selectable={false} style={styles.new_text}>{`${t(
              "new"
            )}`}</Text>
          </Pressable>
        )}
      </View>
    )
      : (<View style={[styles.header(dashboard), { borderBottomColor: color.border }]}>
        <View style={styles.headerLeft}>
          <View
            style={[
              styles.logo_view,
              { backgroundColor: background.mailLogoColor },
            ]}
          >
            <Image source={{ uri: images.mail }} style={styles.logo} />
          </View>
          <Text selectable={false} style={styles.textLogo}>
            {t("message")}
          </Text>
        </View>
        <HeaderPopupButtons
          close={() => setActiveMessageDashboard(null)}
          type={true}
        /></View>)
  }

  const from = () => {
    return message && (inboxSentDrafts === "Inbox" || (inboxSentDrafts === "Drafts" && playerPermission === "1")) && (
      <View style={styles.headers_view}>
        <Text
          selectable={true}
          style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
        >
          {t("from")}:
        </Text>
        <Text selectable={true} style={styles.headers_details}>
          {message.sender?.id ? (checkIfIsEncodeText(message.sender?.name, "string") || checkIfIsEncodeText(players.find(player => player.id === message.sender?.id)?.name_for_game, "string") ||
            checkIfIsEncodeText(sessionStorage.getItem(USER_GAME_NAME), "string")) : ""}
        </Text>
      </View>
    )
  }

  const to = () => {
    if (
      (message && type === "mail") ||
      (message && inboxSentDrafts === "Sent")
    ) {
      return (
        <View style={styles.to_headers_view}>
          <Pressable
            style={styles.to_btn}
          >
            {({ hovered }) => (
              <>
                <View
                  style={styles.to_btn_view(hovered, isEllipsisTo)}
                >
                  <Text
                    style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
                  >
                    {t("to")}:
                  </Text>
                  <Text
                    ref={toPlayersTextRef}
                    numberOfLines={1}
                    selectable={false}
                    style={styles.headers_details}
                  >
                    {message.to_list_names ? message.to_list_names[message.to_list_names.length - 2] ===
                      ","
                      ? message.to_list_names.slice(
                        0,
                        message.to_list_names.length - 2
                      )
                      : checkIfIsEncodeText(message.to_list_names, "string") : message.to_list
                        .split(",")
                        .map(
                          (id) =>
                            players.find((player) => player.id === id)?.name_for_game
                        ).filter(name => checkIfIsEncodeText(name, "string"))
                        .join(",")}
                  </Text>
                </View>
                {isEllipsisTo && hovered && (
                  <View
                    style={[styles.to_popup(hovered), styles.shadow]}
                  >
                    <Text
                      style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
                    >
                      {t("to")}:
                    </Text>
                    <Text
                      selectable={false}
                      style={styles.headers_details}
                    >
                      {message.to_list_names[message.to_list_names.length - 2] ===
                        ","
                        ? message.to_list_names.slice(
                          0,
                          message.to_list_names.length - 2
                        )
                        : checkIfIsEncodeText(message.to_list_names, "string")}
                    </Text>
                  </View>
                )}
              </>
            )}
          </Pressable>
        </View>
      );
    }
  }

  const cc = () => {
    return type === "mail" && message && message.cc_list_names && (
      <View style={styles.cc_headers_view}>
        <Pressable style={styles.to_btn}>
          {({ hovered }) => (
            <>
              <View
                style={styles.to_btn_view(hovered, isEllipsisCc)}
              >
                <Text
                  style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
                >
                  {t("cc")}:
                </Text>
                <Text
                  ref={ccPlayersTextRef}
                  numberOfLines={1}
                  selectable={false}
                  style={styles.headers_details}
                >
                  {message.cc_list_names[message.cc_list_names.length - 2] === ","
                    ? message.cc_list_names.slice(
                      0,
                      message.cc_list_names.length - 2
                    )
                    : message.cc_list_names}
                </Text>
              </View>
              {isEllipsisCc && (
                <View
                  style={[styles.to_popup(hovered), styles.shadow]}
                >
                  <Text
                    style={styles.headers(lang === " he" ? "marginLeft" : "marginRight")}
                  >
                    {t("cc")}:
                  </Text>
                  <Text
                    selectable={false}
                    style={styles.headers_details}
                  >
                    {message.cc_list_names[message.cc_list_names.length - 2] === ","
                      ? message.cc_list_names.slice(
                        0,
                        message.cc_list_names.length - 2
                      )
                      : message.cc_list_names}
                  </Text>
                </View>
              )}
            </>
          )}
        </Pressable>
      </View>
    )
  }

  const bcc = () => {
    return type === "mail" && message && message.bcc_list_names && (
      <View style={styles.bcc_headers_view}>
        <Pressable style={{ flexDirection: "row", cursor: "default" }}>
          {({ hovered }) => (
            <>
              <View
                style={styles.to_btn_view(hovered, isEllipsisBcc)}
              >
                <Text
                  style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
                >
                  {t("bcc")}:
                </Text>
                <Text
                  ref={bccPlayersTextRef}
                  numberOfLines={1}
                  selectable={false}
                  style={styles.headers_details}
                >
                  {message.bcc_list_names[message.bcc_list_names.length - 2] === ","
                    ? message.bcc_list_names.slice(
                      0,
                      message.bcc_list_names.length - 2
                    )
                    : message.bcc_list_names}
                </Text>
              </View>
              {isEllipsisCc && (
                <View
                  style={[styles.to_popup(hovered), styles.shadow]}
                >
                  <Text
                    style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
                  >
                    {t("bcc")}:
                  </Text>
                  <Text
                    selectable={false}
                    style={styles.headers_details}
                  >
                    {message.bcc_list_names[message.bcc_list_names.length - 2] === ","
                      ? message.bcc_list_names.slice(
                        0,
                        message.bcc_list_names.length - 2
                      )
                      : message.bcc_list_names}
                  </Text>
                </View>
              )}
            </>
          )}
        </Pressable>
      </View>
    )
  }

  const event = () => {
    return message?.event?.name !== "null" &&
      (<View style={styles.event_header_view}>
        <View
          style={styles.event_view_body}
        >
          <Text
            style={styles.headers(lang === " he" ? "marginRight" : "marginLeft")}
          >
            {t("event")}:
          </Text>
          <View style={styles.event_color_view(events.find((e) => e.id === message?.event?.id)?.color)} />
          <Text
            ref={bccPlayersTextRef}
            numberOfLines={1}
            selectable={false}
            style={styles.headers_details}
          >
            {message?.event?.name}
          </Text>
        </View>
      </View>)
  }

  const attachments = () => {
    return (
      <View
        style={styles.attach_view(message && message.has_attachments)}
      >
        {message && message.has_attachments && (
          <View style={styles.attach_container}>
            {message.attachments.map((file, i) => {
              return (
                <View
                  style={styles.attach_body}
                >
                  <Image
                    source={{ uri: images.attachmentIconBody }}
                    style={styles.attach_icon}
                  />
                  <Pressable
                    key={i}
                    onPress={() => {
                      if (
                        file.type.toLowerCase().indexOf("jpg") > 0 ||
                        file.type.toLowerCase().indexOf("jpeg") > 0 ||
                        file.type.toLowerCase().indexOf("png") > 0 ||
                        file.type.toLowerCase().indexOf("svg") > 0 ||
                        file.type.toLowerCase().indexOf("webp") > 0
                      ) {
                        console.log(file.link)
                        Linking.openURL(file.link)
                      } else {
                        dispatch(
                          showFile({
                            name: file.name,
                            type: file.type,
                            link: file.link,
                          })
                        )
                      }
                    }
                    }
                  >
                    <Text
                      style={styles.attach_text}
                    >
                      {file.name}
                    </Text>
                  </Pressable>
                </View>
              )
            })}
          </View>
        )}
        {message?.is_survey === "0" && <Text selectable={true} style={styles.time}>
          {message &&
            `${editDate(message.time, "mail", "mail")} | ${editDate(
              message.time,
              "mail",
              "clock"
            )}`}
        </Text>}
      </View>
    )
  }

  const displayBody = (message, Platform) => {
    if (message?.is_survey === "0" && message?.body) {
      const messageBody = checkIfIsCallForAction(message.body);

      if (Platform.OS === "web") {
        return (
          <View style={styles.body_content}>
            <Text selectable={true} style={styles.body_text}>
              <div dangerouslySetInnerHTML={{ __html: messageBody }} />
            </Text>
          </View>
        );
      } else {
        return (
          <View style={styles.body_content}>
            <WebView
              style={styles.container}
              selectable={true}
              originWhitelist={["*"]}
              source={{ html: messageBody }}
            />
          </View>
        );
      }
    } else {
      return (
        <View style={styles.body_content}>
          <QuestionairePlayer isDoneSent={true} questionObj={message?.questions} />
        </View>
      )
    }
  };


  const footer = () => {
    return (showFullMessage || dashboard) && message && message?.is_survey === "0" && playerPermission !== "17" && playerPermission !== "10" && (
      <View style={styles.footer}>
        {inboxSentDrafts === "Inbox" && (
          <Pressable
            style={[styles.footer_btn, styles.left_footer_btn]}
            onPress={() => {
              checkEnd(() => {
                if (type === "mail") {
                  if (dashboard) {
                    if (minimizeMail?.length === 2) {
                      dispatch(setErrorMessage(true, false, t("dashboard_mail_error")));
                    } else {
                      checkNewMessageOpening(minimizeNewMailState, () => {
                        dispatch(minimizeDashbord({ show: true, id: GenerateId(), message: message, typeMessage: "reply" }));
                      });
                    }
                    return;
                  } else {
                    checkNewMessageOpening(minimizeNewMailState, () => {
                      dispatch(minimizeNewMail({ show: true, id: GenerateId(), message: message, type: "reply" }));
                    });
                  }
                } else {
                  checkNewMessageOpening(minimizeNewSystemState, () => {
                    dispatch(minimizeNewSystem({ show: true, id: GenerateId(), message: message, type: "reply" }));
                  });
                }
                return;
              });
            }}
          >
            {({ focused, hovered }) =>
              !hovered ? (
                <>
                  <Image
                    nativeID="icon"
                    style={[styles.footer_icons_reply, styles.footer_icons]}
                    source={images.replyIconHover}
                  />
                  <Text
                    style={[
                      styles.footer_btn_text,
                      { color: color.mailFooter },
                    ]}
                  >
                    {t("reply")}
                  </Text>
                </>
              ) : (
                <>
                  <Image
                    nativeID="icon"
                    style={[styles.footer_icons_reply, styles.footer_iconsHover]}
                    source={images.replyIcon}
                  />
                  <Text
                    selectable={false}
                    style={[styles.footer_btn_text]}
                  >
                    {t("reply")}
                  </Text>
                </>
              )
            }
          </Pressable>
        )}
        {(() => {
          if (
            inboxSentDrafts === "Inbox" &&
            message &&
            type !== "system"
          ) {
            return (
              <Pressable
                style={styles.footer_btn}
                onPress={() => {
                  checkEnd(() => {
                    if (dashboard) {
                      if (minimizeMail?.length === 2) {
                        dispatch(setErrorMessage(true, false, t("dashboard_mail_error")));
                      } else {
                        checkNewMessageOpening(minimizeNewMailState, () => {
                          dispatch(minimizeDashbord({ show: true, id: GenerateId(), message: message, typeMessage: "reply All" }));
                        });
                        return;
                      }
                    } else {
                      checkNewMessageOpening(minimizeNewMailState, () => {
                        dispatch(minimizeNewMail({ show: true, maximize: false, id: GenerateId(), message: message, type: "reply All" }))
                      });
                    }
                  });
                }}
              >
                {({ focused, hovered }) =>
                  !hovered ? (
                    <>
                      <Image
                        nativeID="icon"
                        style={[styles.footer_icons, styles.footer_icons_reply_all]}
                        source={images.replyAllIconHover}
                      />
                      <Text
                        style={[
                          styles.footer_btn_text,
                          { color: color.mailFooter },
                        ]}
                      >
                        {t("reply-all")}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Image
                        nativeID="icon"
                        style={[styles.footer_iconsHover, styles.footer_icons_reply_all]}
                        source={images.replyAllIcon}
                      />
                      <Text
                        selectable={false}
                        style={[styles.footer_btn_text]}
                      >
                        {t("reply-all")}
                      </Text>
                    </>
                  )
                }
              </Pressable>
            );
          }
        })()}
        {
          inboxSentDrafts !== "Drafts" && type !== "system" && (
            <Pressable
              style={styles.footer_btn}
              onPress={() => {
                checkEnd(() => {
                  if (dashboard) {
                    if (minimizeMail?.length === 2) {
                      dispatch(setErrorMessage(true, false, t("dashboard_mail_error")));
                    } else {
                      checkNewMessageOpening(minimizeNewMailState, () => {
                        dispatch(minimizeDashbord({ show: true, id: GenerateId(), message: message, typeMessage: "forward" }));
                      });
                      return;
                    }
                  } else {
                    checkNewMessageOpening(minimizeNewMailState, () => {
                      dispatch(minimizeNewMail({ show: true, maximize: false, id: GenerateId(), message: message, type: "forward" }));
                    });
                  }
                });
              }}
            >
              {({ focused, hovered }) =>
                !hovered ? (
                  <>
                    <Image
                      nativeID="icon"
                      style={[styles.footer_icons, styles.footer_icons_forward]}
                      source={images.forwardIconHover}
                    />
                    <Text
                      style={[
                        styles.footer_btn_text,
                        { color: color.mailFooter },
                      ]}
                    >
                      {t("forward")}
                    </Text>
                  </>
                ) : (
                  <>
                    <Image
                      nativeID="icon"
                      style={[styles.footer_iconsHover, styles.footer_icons_forward]}
                      source={images.forwardIcon}
                    />
                    <Text
                      selectable={false}
                      style={[styles.footer_btn_text]}
                    >
                      {t("forward")}
                    </Text>
                  </>
                )
              }
            </Pressable >
          )
        }
        {
          inboxSentDrafts === "Drafts" && (
            <View style={styles.editBtnView}>
              <TouchableOpacity style={styles.editBtn}
                onPress={() => {
                  if (type === "mail") {
                    checkNewMessageOpening(minimizeNewMailState, () => {
                      dispatch(minimizeNewMail({ show: true, maximize: false, id: GenerateId(), message: message, type: "drafts" }))
                    });
                  } else {
                    checkNewMessageOpening(minimizeNewSystemState, () => {
                      dispatch(minimizeNewSystem({ show: true, id: GenerateId(), message: message, type: "drafts" }))
                    });
                  }
                  return;
                }}
              >
                <Image
                  source={{ uri: images.eyeIcon }}
                  resizeMode="contain"
                  style={{ width: "1.2vw", height: "1.2vw", tintColor: "black" }}
                />
              </TouchableOpacity>
              <TouchableOpacity style={styles.editBtn}
                onPress={() => {
                  dispatch(
                    setErrorMessage(
                      true,
                      undefined,
                      `${t("would_you_like_to_delete_this_email")}?`,
                      (e) => {
                        if (e) {
                          message.is_delete = true;
                          dispatch(loading(true));
                          sentMail(message).then(() => {
                            setIndexMessage("");
                            dispatch(loading(false));
                          }).catch((e) => {
                            dispatch(loading(false));
                          });
                        }
                      }
                    )
                  );
                }}
              >
                <Image
                  source={{ uri: images.deleteIconBlack }}
                  resizeMode="contain"
                  style={{ width: "1.2vw", height: "1.2vw" }}
                />
              </TouchableOpacity>
            </View>
          )
        }
      </View >
    );
  }

  useEffect(() => {
    if (
      toPlayersTextRef.current?.offsetWidth <
      toPlayersTextRef.current?.scrollWidth
    ) {
      return setIsEllipsisTo(true);
    }
    setIsEllipsisTo(false);
  }, [toPlayersTextRef.current, message]);

  useEffect(() => {
    if (
      ccPlayersTextRef.current?.offsetWidth <
      ccPlayersTextRef.current?.scrollWidth
    ) {
      return setIsEllipsisCc(true);
    }
    setIsEllipsisCc(false);
  }, [ccPlayersTextRef.current, message]);

  useEffect(() => {
    if (
      bccPlayersTextRef.current?.offsetWidth <
      bccPlayersTextRef.current?.scrollWidth
    ) {
      return setIsEllipsisBcc(true);
    }
    setIsEllipsisBcc(false);
  }, [bccPlayersTextRef.current, message]);

  useEffect(() => {
    if (scrollRef.current?.offsetHeight < scrollRef.current?.scrollHeight) {
      return setIsScrollableMessage(true);
    }
    setIsScrollableMessage(false);
  }, [scrollRef.current, message]);

  useEffect(() => {
    const element = document.getElementById("callChallenges");

    if (element) {
      const handleClick = () => {
        dispatch(setShowActions(true));
        dispatch(setTypeMinimize({ name: "challenges", type: 1, show: true, num: 5 }));
        dispatch(showNewAction(true));
      };

      element.addEventListener("click", handleClick);

      // Cleanup effect
      return () => {
        element.removeEventListener("click", handleClick);
      };
    }

    // Empty dependency array is intentional, ensuring the effect runs only once
    // If you find that you need to re-run this effect based on some variables, 
    // then those variables should be added to the dependency array
  }, []);

  useEffect(() => {
    const handleClick = () => {
      dispatch(setShowActions(true));
      dispatch(setTypeMinimize({ name: "challenges", type: 1, show: true, num: 5 }));
      dispatch(showNewAction(true));
    };
    const timeout = setTimeout(() => {
      const element = document.getElementById("callChallenges");
      if (element) {
        element.addEventListener("click", handleClick);
      }
    }, 700);

    // Cleanup effect
    return () => {
      clearTimeout(timeout);
    };

  }, [message]);


  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, [inboxSentDrafts]);

  return (
    <View style={styles.container(dashboard)}>
      {header(dashboard)}
      {/* body */}
      <ScrollView
        nativeID="mail"
        style={styles.bodyC}
        contentContainerStyle={styles.body_m}
      >
        {(showFullMessage || dashboard) && message && <View style={styles.body}>
          {/* main */}
          <View
            style={styles.body_child_c}
          >
            {/* top-message */}
            <View
              style={styles.top(message, lang)}
            >
              <Text
                selectable={false}
                style={styles.subject(lang)}
              >
                {message?.is_survey === "1" && `${t("questionaire")}:`} {message && message.subject}
              </Text>
              {from()}
              {to()}
              {cc()}
              {bcc()}
              {event()}
              {attachments()}
            </View>
            {/* body-message */}
            {displayBody(message, Platform)}
          </View>
        </View>}
        {footer()}
      </ScrollView>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    container: (flag) => ({
      height: flag ? "90%" : "100%",
      width: flag ? "60%" : "",
    }),
    header: (flag) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: flag ? "space-between" : "flex-end",
      minHeight: heightS * 30,
      paddingRight: flag ? "" : widthS * 18,
      zIndex: 10,
      backgroundColor: flag ? background.popup1 : background.mailBodyHeader,
      borderTopLeftRadius: flag ? "1vw" : "",
      borderTopRightRadius: flag ? "1vw" : "",
    }),
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: "0.5vw",
      paddingLeft: "1vw"
    },
    logo_view: {
      width: "2.6vw",
      height: "2.6vw",
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: "1.3vw",
      height: "1.3vw",
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: "1.5vw",
      paddingLeft: "1vw",
    },
    x: {
      fontFamily: "Catamaran_300Light",
      fontSize: widthS * 14,
    },
    new_btn: {
      minWidth: widthS * 45,
      paddingHorizontal: widthS * 3,
      paddingVertical: heightS * 1.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: widthS * 6,
      backgroundColor: background.mailBtnNew
    },
    new_text: {
      color: "#ffffff",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    search_press_btn: {
      alignItems: "center"
    },
    search_press: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginHorizontal: widthS * 4,
      paddingHorizontal: widthS * 4,
      backgroundColor: background.mailSearch,
      writingDirection: direction
    },
    showSearchPopup: {
      borderRadius: widthS * 5,
    },
    hideSearchPopup: {
      borderTopRightRadius: widthS * 5,
      borderTopLeftRadius: widthS * 5,
    },
    search_input: {
      width: widthS * 155,
      paddingLeft: widthS * 10,
      paddingRight: widthS * 8,
      paddingVertical: heightS * 2,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
      color: color.mailSearch,
      outlineStyle: "none",
      writingDirection: direction
    },
    search_icon: {
      width: widthS * 9.8,
      height: widthS * 9.8,
      resizeMode: "contain",
    },
    body: {
      flex: 1,
      width: "100%",
      // minHeight: "100%",
      paddingTop: widthS * 10,
    },
    body_child_c: {
      height: "max-content",
      maxHeight: "100%",
      backgroundColor: background.mailBody
    },
    bodyC: {
      backgroundColor: background.mailBody,
      borderBottomRightRadius: widthS * 10,
    },
    body_m: {
      ////
      minHeight: "101%",
    }, ////
    top: (message, lang) => ({
      position: "relative",
      padding: 10,
      paddingBottom: heightS * 7,
      zIndex: 5,
      borderBottomColor: color.mailBorder,
      borderBottomWidth: !message ? 0 : widthS * 0.5,
      direction: lang === "he" ? "rtl" : "ltr",
      marginHorizontal: widthS * 15,
    }),
    subject: (lang) => ({
      fontSize: widthS * 18,
      fontFamily: "Catamaran_700Bold",
      lineHeight: heightS * 18,
      marginBottom: heightS * 7,
      direction: lang === "he" ? "rtl" : "ltr"
    }),
    hover: {
      position: "absolute",
    },
    headers_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    to_headers_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      zIndex: 5
    },
    to_btn: {
      flexDirection: "row",
      cursor: "default"
    },
    to_btn_view: (hovered, isEllipsis) => ({
      opacity: hovered && isEllipsis ? 0 : 1,
      flexDirection: "row",
      transition: "0.2s all",
    }),
    to_popup: (hovered) => ({
      position: "absolute",
      opacity: hovered ? 1 : 0,
      flexDirection: "row",
      transition: "0.2s all",
      backgroundColor: background.mailBody,
      minHeight: heightS * 33,
      display: hovered ? "block" : "none",
      zIndex: 5,
    }),
    cc_headers_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      zIndex: 4
    },
    bcc_headers_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      zIndex: 3
    },
    event_header_view: {
      zIndex: 2,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    event_view_body: {
      flexDirection: "row",
      transition: "0.2s all",
      alignItems: "center",
    },
    event_color_view: (color) => ({
      height: widthS * 11,
      borderRadius: "0.3vw",
      width: "0.2vw",
      marginHorizontal: "0.2vw",
      backgroundColor: color
    }),
    headers: (key) => ({
      fontSize: widthS * 13,
      lineHeight: heightS * 14,
      fontFamily: "Catamaran_600SemiBold",
      [key]: widthS * 3,
      writingDirection: direction
    }),
    headers_details: {
      fontSize: widthS * 13,
      lineHeight: heightS * 14,
      fontFamily: "Catamaran_400Regular",
      width: widthS * 371.5,
      writingDirection: direction
    },
    attach_view: (flag) => ({
      marginTop: heightS * 3,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 1,
      justifyContent: flag ? "space-between" : "flex-end"
    }),
    attach_container: {
      alignItems: "flex-start",
    },
    attach_icon: {
      width: widthS * 9,
      height: widthS * 9,
      resizeMode: "contain",
      tintColor: color.mailAttachName
    },
    attach_body: {
      maxWidth: "25vw",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      columnGap: "0.3vw"
    },
    attach_text: {
      maxWidth: "25vw",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      textDecorationStyle: "solid",
      textDecorationLine: "underline",
      color: color.mailAttachName
    },
    body_content: {
      paddingVertical: heightS * 15,
      paddingHorizontal: widthS * 15,
      flex: 1,
      // minHeight: "100%",
    },
    body_text: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction,
    },
    file_view: {
      marginVertical: heightS * 3,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    file_text: {
      fontSize: widthS * 9,
      color: "blue",
      fontFamily: "Catamaran_400Regular",
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationColor: "blue",
      marginHorizontal: 5,
    },
    body_content_text: {
      fontFamily: "Catamaran_400Regular",
    },
    time: {
      alignSelf: "flex-end",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    footer: {
      position: "sticky",
      bottom: widthS * -0.4,
      borderTopColor: color.mailBorder,
      backgroundColor: background.mailBody,
      borderBottomColor: background.popup1,
      borderBottomWidth: widthS * 6,
      height: heightS * 32,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderTopWidth: 1,
      width: "100%",
    },
    left_footer_btn: {
      paddingLeft: "5px"
    },
    footer_btn: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: widthS * 15,
    },
    footer_icons_reply: {
      width: widthS * 10,
      height: widthS * 10
    },
    footer_icons_reply_all: {
      width: widthS * 11,
      height: widthS * 11
    },
    footer_icons_forward: {
      width: widthS * 10,
      height: widthS * 10
    },
    footer_icons: {
      resizeMode: "contain",
      marginRight: widthS * 3,
      tintColor: color.mailFooter
    },
    footer_iconsHover: {
      resizeMode: "contain",
      marginRight: widthS * 3,
    },
    footer_btn_text: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_700Bold",
    },
    editBtnView: {
      flex: 1,
      alignItems: "center",
      padding: "0.5vw",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: "0.5vw"
    },
    editBtn: {
      minWidth: "1.5vw",
      height: "1.5vw",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
    },
    popup_list: {
      maxHeight: "20vw"
    },
    popupSearch: {
      position: "absolute",
      top: "100%",
      width: "100%",
      backgroundColor: background.mailSearch,
      borderBottomLeftRadius: "0.5vw",
      borderBottomRightRadius: "0.5vw",
      borderTopWidth: "0.1vw",
      borderTopColor: color.border,
      alignItems: "center",
      justifyContent: "center"
    },
    searchPlayers: {

    },
    searchMessages: {

    },
    searchHeadersText: {
      fontSize: "1vw",
      fontFamily: "Catamaran_600SemiBold",
      marginLeft: "0.5vw"
    },
    profileIcon: {
      width: "2vw",
      height: "2vw",
      borderRadius: "50%",
      resizeMode: "cover"
    },
    itemSearch: {
      flexDirection: "row",
      alignItems: "center",
      padding: "0.5vw",
      paddingHorizontal: "1vw"
    },
    itemSearchRealName: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_400Regular",
      marginLeft: "0.5vw",
      color: color.mailSearch
    },
    itemSearchRepName: {
      fontSize: "0.7vw",
      fontFamily: "Catamaran_400Regular",
      marginLeft: "0.5vw",
      color: color.mailSearch
    },
    messageIconView: {
      backgroundColor: background.infoBox,
      width: "2vw",
      height: "2vw",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center"
    },
    messageIcon: {
      width: "1vw",
      height: "1vw",
      resizeMode: "contain"
    },
    shadow: {
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0,
      },
      shadowOpacity: 0.6,
      shadowRadius: 5,
    }
  });
