import { StyleSheet, Text, View, Pressable } from 'react-native'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../../../redux/actions';
import { t } from 'i18n-js';
import { SwimlaneChartContext } from '../../../../ContextData/Swimlanechart';
import { useContext } from 'react';
import { changeBuildFlag, changeDashboardFlag, changeInfoFlag, showAnalytics } from '../../../../redux/actions/creatorActions';
import { GameInfoContext } from '../../../../ContextData/GameInfo';
import { useMemo } from 'react';
import { Entypo } from '@expo/vector-icons';

export default function EndBtn({ setLoad, swimlane, sendToSocket }) {

    const dispatch = useDispatch();

    const {
        startGameData,
        endGameDate
    } = useContext(SwimlaneChartContext);
    const { getGameInfoData } = useContext(GameInfoContext);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(background, color);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const [startAndEndGame, setStartAndendGame] = useState({ start: "", end: false });

    const controlButton = (startAndEnd, swimlane) => {
        if (startAndEnd.end) {
            return t("ended");
        } else if (startAndEnd.start) {
            return swimlane ? t("end") : t("end_sim");
        } else {
            return swimlane ? t("start") : t("start_sim")
        }
    }
    const controlButtonIcon = (startAndEnd) => {
        if (startAndEnd.start && !startAndEnd.end) {
            return "controller-stop";
        } else if (!startAndEnd.start && !startAndEnd.end) {
            return "controller-play"
        }
    }

    useMemo(() => {
        setStartAndendGame({ start: gameInfo?.start_time_current_zone ? new Date(`${gameInfo?.start_time_current_zone}`) : null, end: gameInfo?.end_time ? new Date(`${gameInfo?.end_time}`) : null });
    }, [gameInfo?.start_time_current_zone, gameInfo?.end_time]);

    const tooltip = (date, startOrEnd) => {
        let currentDate = new Date(date);
        return (
            <View style={styles.tooltipContainer}>
                <View style={styles.traingle} />
                <View style={styles.tooltipMain}>
                    <View style={styles.tooltipTop}>
                        <Text style={styles.tooltipHeader}>{t(startOrEnd === "start" ? "tooltip_dashboard" : "tooltip_dashboard_end")}</Text>
                    </View>
                    <View style={styles.tooltipBottom}>
                        <Text style={styles.dataText}>{`${currentDate.toLocaleTimeString(currentDate.getTimezoneOffset(),
                            { hour12: false, hour: '2-digit', minute: '2-digit', second: "2-digit" })} \n ${currentDate.toDateString().slice(0, 3) + "." + currentDate.toDateString().slice(3).replace(' 202', ', 202')}`}</Text>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <>
            <Pressable
                disabled={startAndEndGame.end}
                style={[swimlane ? styles.btnSave : styles.btnSaveDashboard, { opacity: startAndEndGame.end ? 0.7 : 1, }]}
                onPress={() => {
                    if (startAndEndGame.start) {
                        dispatch(
                            setErrorMessage(
                                true,
                                undefined,
                                `${t("end_game_popup")}?`,
                                (flag) => {
                                    if (flag) {
                                        setLoad(true);
                                        endGameDate().then(() => {
                                            getGameInfoData();
                                            dispatch(changeBuildFlag(false));
                                            dispatch(changeDashboardFlag(false));
                                            dispatch(showAnalytics(true));
                                            dispatch(changeInfoFlag(false));
                                            sendToSocket();
                                            setLoad(false);
                                        }).catch(() => {
                                            setLoad(false);
                                        });
                                    }
                                }
                            )
                        );
                    } else {
                        setLoad(true);
                        startGameData().then(() => {
                            getGameInfoData();
                            setLoad(false);
                        }).catch(() => {
                            setLoad(false);
                        });
                    }
                }
                }
            >
                {({ hovered }) => (
                    <>
                        {!swimlane && (!startAndEndGame.end || !startAndEndGame.start) && <View style={styles.iconView}>
                            {<Entypo name={controlButtonIcon(startAndEndGame)} style={styles.icon} />}
                        </View>}
                        <Text style={[styles.btnTextSave]}>{controlButton(startAndEndGame, swimlane)?.toUpperCase()}</Text>
                        {(hovered && !swimlane && startAndEndGame.start) && tooltip(startAndEndGame?.end ? startAndEndGame?.end : startAndEndGame.start, startAndEndGame.end ? "end" : "start")}
                    </>
                )}
            </Pressable>
            {startAndEndGame.end && <Text style={[
                styles.endDate,
            ]}>{`${t("ended_time")} ${startAndEndGame.end.toDateString()} ${[startAndEndGame.end.getHours({}), startAndEndGame.end.getMinutes({})].join(":")}`}</Text>}
        </>
    )
}

const stylesR = (background, color) => {
    return StyleSheet.create({
        btnSave: {
            paddingVertical: "0.3vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: "1vw",
            borderRadius: "0.6vw",
            backgroundColor: background.infoSaveBtn
        },
        btnSaveDashboard: {
            paddingVertical: "0.3vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: "1vw",
            flexDirection: "row",
            borderRadius: "0.6vw",
            position: "relative",
            borderColor: color.infoHeaderNavText,
            borderWidth: "0.1vw"
        },
        btnTextSave: {
            fontSize: "1vw",
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_400Regular"
        },
        endDate: {
            fontSize: "0.6vw",
            color: color.infoHeaderNavText,
            fontWeight: "Catamaran_400Regular",
        },
        iconView: {
            backgroundColor: color.infoHeaderNavText,
            borderRadius: "100%",
            padding: "0.2vw",
            marginRight: "0.5vw",
        },
        icon: {
            fontSize: "0.8vw",
            color: background.infoBody,
        },
        tooltipContainer: {
            position: "absolute",
            top: "120%",
            alignItems: "center"
        },
        traingle: {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: "0.7vw",
            borderRightWidth: "0.7vw",
            borderBottomWidth: "0.7vw",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: background.infoBox,
        },
        tooltipMain: {
            backgroundColor: background.infoBox,
            width: "10vw",
            borderRadius: "0.7vw",
            alignItems: "center",
            paddingHorizontal: "1vw",
            paddingVertical: "0.5vw"
        },
        tooltipTop: {
            borderBottomColor: color.border,
            borderBottomWidth: "0.1vw",
            width: "100%",
            alignItems: "center"
        },
        tooltipHeader: {
            fontSize: "0.8vw",
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_400Regular"
        },
        tooltipBottom: {
            marginTop: "0.2vw"
        },
        dataText: {
            fontSize: "0.8vw",
            textAlign: "center",
            lineHeight: "1vw",
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_300Light"
        }
    })
} 