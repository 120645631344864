import { View, Text, StyleSheet, Image, Pressable, TouchableHighlight } from "react-native";
import React from "react";
import UseResponsiveSize from "../../../../../modules/UseResponsiveScreen";
import UseResponsiveScreen from "../../../../../modules/UseResponsiveScreen";
import { useSelector } from "react-redux";
import { Ionicons } from "@expo/vector-icons";

export default function PlayerCard({
  from,
  to,
  select,
  multiple,
  onDelete,
  onPress,
  active,
  disabled,
  index
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const images = useSelector((state) => state.style.images);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  return (
    <Pressable
      style={{ marginBottom: select ? 0 : heightS * 5 }}
      onPress={() => onPress && onPress(to)}
    >
      {({ hovered }) => (
        <View
          style={{
            backgroundColor: background.infoBody,
            paddingVertical: heightS * 5,
            borderRadius: select ? 0 : widthS * 7,
            borderBottomLeftRadius: !select ? widthS * 7 : 0,
            borderBottomRightRadius: !select ? widthS * 7 : 0,
          }}
        >
          <View style={styles.playerDetailsContainer}>
            <View style={[styles.left]}>
              {select && multiple && (
                <Pressable
                  onPress={() => {
                    onPress(to);
                  }}
                  style={[
                    styles.checkBox,
                    {
                      borderColor: color.infoBorder,
                      backgroundColor: background.infoBody,
                    },
                  ]}
                >
                  {active && (
                    <View
                      style={[
                        styles.checkBoxInner,
                        {
                          backgroundColor: background.count,
                        },
                      ]}
                    />
                  )}
                </Pressable>
              )}
              <View
                style={[
                  styles.imageContainer,
                  {
                    borderColor: color.logoColor,
                    shadowColor: color.logoColor,
                  },
                ]}
              >
                <Image
                  style={[styles.icon]}
                  source={{ uri: images.user_image }}
                />
              </View>
              {/* <Text style={[styles.title, { color: color.infoHeaderNavText }]}>
                CEO
              </Text> */}
            </View>
            <View
              style={[
                styles.playerDetails,
                { paddingRight: select || disabled ? widthS * 10 : widthS * 10 },
              ]}
            ><Text
                numberOfLines={1}
                style={[styles.name, { color: color.infoHeaderNavText }]}
              >
                {from || to?.name_for_game || to }
              </Text>
            </View>
            {
              <TouchableHighlight
                style={[
                  styles.trashContainer,
                  {
                    backgroundColor: background.popup1,
                    display: hovered && !select && !disabled ? "" : "none",
                  },
                ]}
                onPress={() => onDelete([to], true)}
              >
                <Ionicons
                  name="trash-outline"
                  color={color.infoHeaderNavText}
                  size={widthS * 6}
                />
              </TouchableHighlight>
            }
          </View>
        </View>
      )}
    </Pressable>
  );
}

const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      paddingTop: heightS * 10,
      borderRightWidth: 1,
      flex: 1,
    },
    titleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerTitle: {
      paddingLeft: widthS * 12,
      fontSize: widthS * 13,
    },
    iconContainer: {
      borderRadius: "50%",
      marginRight: widthS * 10,
      padding: widthS * 7,
      width: widthS * 12,
      height: widthS * 12,
      justifyContent: "center",
      alignItems: "center",
    },
    realPlayers: {
      paddingTop: heightS * 15,
      borderBottomWidth: 1,
      paddingLeft: widthS * 12,
    },
    playerContainer: {
      //   height: heightS * 45,
      flexDirection: "row",
      //   justifyContent: "space-between",
      //   alignItems: "center",
    },
    imageContainer: {
      borderRadius: "50%",
      borderWidth: widthS * 1.5,
      marginRight: widthS * 5,
      marginLeft: widthS * 3,
    },
    icon: {
      width: widthS * 15,
      height: widthS * 15,
      borderRadius: "50%",
    },
    playerDetailsContainer: {
      //   alignItems: "center",
      flexDirection: "row",
      //   justifyContent: "center",
    },
    playerDetails: {
      justifyContent: "center",
      flex: 1,
      paddingRight: widthS * 40,
    },
    title: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_700Bold",
      lineHeight: widthS * 9,
    },
    name: {
      fontSize: widthS * 9,
      lineHeight: widthS * 9,
      fontFamily: "Catamaran_300Light",
    },
    left: {
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: widthS * 10,
    },
    trashContainer: {
      position: "absolute",
      right: widthS * 10,
      top: heightS * 4,
      borderRadius: "50%",
      width: widthS * 12,
      height: widthS * 12,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10,
    },
    checkBox: {
      width: 8 * widthS,
      height: 8 * widthS,
      borderRadius: 3,
      borderWidth: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    checkBoxInner: {
      width: 5 * widthS,
      height: 5 * widthS,
      borderRadius: 2,
    },
  });
};
