import { StyleSheet, Text, View, Dimensions, Pressable, TextInput, Image, ScrollView, } from 'react-native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from "i18n-js";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import TimePicker from '../../../modules/TimePicker';
import DatePicker from '../../../modules/DatePicker';
import { listenerAdd } from "../../../../AddListener";
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import AddLogo from '../../../login/AddLogo';
import { changeFlowTimeUnits } from '../../../../redux/actions/creatorActions';
import { changeLangType } from '../../../../redux/actions';
import { getLang } from '../../../../getLang';
import { createFileObject } from "../../../modules/CreateFileObject";
import ShortenWord from "../../../modules/ShortenWord";
import TimesZone from "../../../../../translations/timeZone.json"

export default function SimulationInfoBox({ infoObj, setInfoObj, err, setErr }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const dispatch = useDispatch();

    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const unitsRef = useRef(null);
    const selectZoneRef = useRef(null);
    const selectLangRef = useRef(null);
    const selectClientRef = useRef(null);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const images = useSelector((state) => state.style.images);

    const clientsState = useSelector((state) => state.gameInfoArr.clients);

    const clients = useMemo(() => clientsState, [clientsState]);
    const canEditClient = useMemo(() => clients.map(client => client.id).includes(infoObj?.client_id), [clients]);
    const [showDate, setShowDate] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showUnits, setShowUnits] = useState(false);
    const [showTypeUnits, setShowTypeUnits] = useState("minutes");
    const [showZone, setShowZone] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [showAddLogo, setShowAddLogo] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showClientsPicker, setShowClientsPicker] = useState(false);

    const flags = [
        { name: "English", value: "english" },
        { name: "Deutsch", value: "german" },
        { name: "Español", value: "spanish" },
        { name: "Français", value: "french" },
        { name: "Hebrew", value: "hebrew" },
        { name: "Italiano", value: "italian" },
        { name: "Thai", value: "thai" }
    ]


    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(timeRef, showTime, () => setShowTime(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(timeRef, showTime, () => setShowTime(false)));
        }
    }, [showTime]);

    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(dateRef, showDate, () => setShowDate(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(dateRef, showDate, () => setShowDate(false)));
        }
    }, [showDate]);

    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(unitsRef, showUnits, () => setShowUnits(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(unitsRef, showUnits, () => setShowUnits(false)));
        }
    }, [showUnits]);
    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(selectZoneRef, showZone, () => setShowZone(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(selectZoneRef, showLang, () => setShowZone(false)));
        }
    }, [showZone]);
    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(selectLangRef, showLang, () => setShowLang(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(selectLangRef, showLang, () => setShowLang(false)));
        }
    }, [showLang]);
    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(selectClientRef, showClientsPicker, () => setShowClientsPicker(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(selectClientRef, showClientsPicker, () => setShowClientsPicker(false)));
        }
    }, [showClientsPicker]);

    return (
        <>
            <View style={styles.container}>
                <Text style={[styles.headers, { color: color.infoHeaderNavText }]}> {t("menu_info")}</Text>
                <View style={[styles.simulationInfoBox, { backgroundColor: background.infoBox }]}>
                    {/* top */}
                    <View style={styles.top}>
                        <Pressable style={[styles.checkBoxView, { backgroundColor: background.infoBody }]}
                            onPress={() => {
                                setInfoObj({ ...infoObj, is_demo: !infoObj.is_demo });
                            }}
                        >
                            {infoObj?.is_demo && <View style={[styles.checkBoxfill, { backgroundColor: background.count }]} />}
                        </Pressable>
                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{t("demo")}</Text>
                    </View>
                    {/* simulation name */}
                    <View style={styles.nameView}>
                        <TextInput
                            style={[styles.nameInput, { backgroundColor: background.infoBody, color: color.buildText }, err && !infoObj.simulationName.length > 0 && styles.mail_error]}
                            placeholder={t("menu_game")}
                            placeholderTextColor={color.buildText}
                            onChangeText={(text) => setInfoObj({ ...infoObj, simulationName: text })}
                            value={infoObj.simulationName ? infoObj.simulationName : ""}
                        />
                    </View>
                    {/* date and time*/}
                    <View style={styles.dateAndtimeView}>
                        <Pressable
                            disabled={infoObj.start}
                            // disabled={true}
                            onPress={() => {
                                setShowDate(!showDate)
                            }} style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "53%" }]}>
                            <Text style={[styles.headers2, {
                                color: infoObj.start ? color.placeholder : color.infoHeaderNavText
                                // color: color.placeholder
                            }]}>{infoObj.date ? infoObj.date : t("start_date")}</Text>
                            <Image style={styles.calendar} source={{ uri: images.calendarIcon }} />
                            {showDate && <View ref={dateRef} style={[styles.datePicker, { backgroundColor: background.infoBody }]}>
                                <DatePicker
                                    onChange={(date) => setInfoObj({ ...infoObj, date: date })}
                                    value={infoObj.date}
                                    colorText={color.infoHeaderNavText}
                                    colorTextCurrent={background.infoSaveBtn}
                                    background={background.infoBox}
                                    borderColor={color.border}
                                    calendarClassName={'calender-info'}
                                    close={() => setShowDate(false)}
                                />
                            </View>}
                        </Pressable>
                        <Pressable disabled={infoObj.start} style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "43%" }]} onPress={() => setShowTime(true)}>
                            <Text style={[styles.headers2, { color: infoObj.start ? color.placeholder : color.infoHeaderNavText }]}>{infoObj.time ? infoObj.time : t("start_time")}</Text>
                            <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                            {showTime && <View style={[styles.timePicker, { backgroundColor: background.infoBody }]} ref={timeRef}>
                                <TimePicker
                                    onChange={(time) => setInfoObj({ ...infoObj, time: time })}
                                    value={infoObj.time}
                                    colorText={color.infoHeaderNavText}
                                    colorTextCurrent={background.infoSaveBtn}
                                    background={background.infoBox}
                                    borderColor={color.border}
                                />
                            </View>}
                        </Pressable>
                    </View>
                    <View style={styles.colorsView}>
                        <View style={styles.colorsViewChild}>
                            <Pressable
                                style={[
                                    styles.btnSelect,
                                    {
                                        backgroundColor: background.infoBody,
                                        width: "53%",
                                    },
                                ]}
                                onPress={() => setShowAddLogo(true)}
                            >
                                <Text
                                    style={[
                                        styles.headers2,
                                        {
                                            color: color.infoHeaderNavText
                                        },
                                    ]}
                                >
                                    {infoObj.game_logo ? (infoObj.game_logo?.name ? ShortenWord(infoObj.game_logo?.name, 25, true) : ShortenWord(createFileObject(false, infoObj?.game_logo?.uri || infoObj?.game_logo)?.name, 25, true)) : t("add_logo")}
                                </Text>
                                <Image source={{ uri: images.filePhotoIcon }} style={{ resizeMode: "contain", width: widthS * 12, height: widthS * 12 }} />
                            </Pressable>
                            <Pressable
                                style={[
                                    styles.btnSelect,
                                    {
                                        backgroundColor: background.infoBody,
                                        width: "43%",
                                    },
                                ]}
                                onPress={() => setShowColorPicker(true)}
                            >
                                {infoObj.game_colors ?
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        {infoObj.game_colors.split(",").map((e, i) => {
                                            return (
                                                <View key={i} style={{ borderRadius: "0.4vw", padding: "0.4vw", marginRight: "0.3vw", backgroundColor: background.infoBox }}>
                                                    <View style={{ backgroundColor: `#${e}`, width: "1.2vw", height: "1.2vw" }} />
                                                </View>
                                            )
                                        })}
                                    </View>
                                    :
                                    <Text
                                        style={[
                                            styles.headers2,
                                            {
                                                color: color.infoHeaderNavText
                                            },
                                        ]}
                                    >
                                        {t("costomize_colors")}
                                    </Text>}
                                <MaterialIcons name="colorize" size={widthS * 12} color="white" />
                            </Pressable>
                        </View>
                    </View>
                    <View style={styles.bottom}>
                        {/* <Pressable style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "53%" }]} ref={unitsRef} onPress={() => setShowUnits(!showUnits)}>
                            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.timeUnits ? infoObj.timeUnits : t("time_units")}</Text>
                            <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                            {showUnits && <View style={[styles.unitsPicker, { backgroundColor: background.infoBody }]}>
                                <Pressable style={[styles.selectTop, { borderBottomColor: color.border }]} onPress={() => setShowUnits(false)}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.timeUnits ? infoObj.timeUnits : t("time_units")}</Text>
                                    <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                                </Pressable>
                                <Pressable style={styles.selectItems} onPress={() => setShowTypeUnits("minutes")}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{t("minutes_units")}{showTypeUnits === "minutes" && ":"}</Text>
                                    {showTypeUnits === "minutes" && <View style={styles.selectItemsUnits}>
                                        {["05", "10", "30"].map((typeTime, index) => {
                                            return (
                                                <Pressable style={{ paddingBottom: heightS * 6 }} onPress={() => { dispatch(changeFlowTimeUnits(`${typeTime}m`)); setInfoObj({ ...infoObj, timeUnits: `${t("minutes_units")}: ${typeTime}` }); setShowUnits(false) }}>
                                                    <Text key={index} style={[styles.headers3, { color: color.infoHeaderNavText }]}>{typeTime}</Text>
                                                </Pressable>
                                            )
                                        })}
                                    </View>}
                                </Pressable>
                                <Pressable style={styles.selectItems} onPress={() => setShowTypeUnits("hours")}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{t("hours_units")}{showTypeUnits === "hours" && ":"}</Text>
                                    {showTypeUnits === "hours" && <View style={styles.selectItemsUnits}>
                                        {["1", "2", "3"].map((typeTime, index) => {
                                            return (
                                                <Pressable style={{ paddingBottom: heightS * 6 }} onPress={() => { dispatch(changeFlowTimeUnits(`${typeTime}h`)); setInfoObj({ ...infoObj, timeUnits: `${t("hours_units")}: ${typeTime}` }); setShowUnits(false) }}>
                                                    <Text key={index} style={[styles.headers3, { color: color.infoHeaderNavText }]}>{typeTime}</Text>
                                                </Pressable>
                                            )
                                        })}
                                    </View>}
                                </Pressable>
                                <Pressable style={styles.selectItems} onPress={() => setShowTypeUnits("days")}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{t("days_units")}{showTypeUnits === "days" && ":"}</Text>
                                    {showTypeUnits === "days" && <View style={styles.selectItemsUnits}>
                                        {["1", "2", "3"].map((typeTime, index) => {
                                            return (
                                                <Pressable style={{ paddingBottom: heightS * 6 }} onPress={() => { dispatch(changeFlowTimeUnits(`${typeTime}d`)); setInfoObj({ ...infoObj, timeUnits: `${t("days_units")}: ${typeTime}` }); setShowUnits(false) }}>
                                                    <Text key={index} style={[styles.headers3, { color: color.infoHeaderNavText }]}>{typeTime}</Text>
                                                </Pressable>
                                            )
                                        })}
                                    </View>}
                                </Pressable>
                            </View>}
                        </Pressable> */}
                        <Pressable disabled={infoObj.start} style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "53%" }]} ref={selectZoneRef} onPress={() => setShowZone(!showZone)}>
                            <Text style={[styles.headers2, { color: infoObj.start ? color.placeholder : color.infoHeaderNavText }]}>{infoObj?.game_time_zone ? infoObj?.game_time_zone : t("time_zone")}</Text>
                            <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                            {showZone && <View style={[styles.timePicker, { backgroundColor: background.infoBody }]}>
                                <Pressable style={[styles.selectTop, { borderBottomColor: color.border }]} onPress={() => setShowZone(false)}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj?.game_time_zone ? infoObj?.game_time_zone : t("time_zone")}</Text>
                                    <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                                </Pressable>
                                <ScrollView style={{ maxHeight: "10vw", flex: 1, width: "100%" }} id='chat'>
                                    {TimesZone.game_time_zone.map((zone, index) => {
                                        if (infoObj?.game_time_zone?.toLowerCase()?.indexOf(zone?.toLowerCase()) >= 0) return;
                                        return (
                                            <Pressable key={index} style={styles.selectItemsLang} onPress={() => {
                                                setInfoObj({ ...infoObj, game_time_zone: zone });
                                                setShowZone(false);
                                            }}>
                                                <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{zone}</Text>
                                            </Pressable>
                                        )
                                    })}
                                </ScrollView>
                            </View>}
                        </Pressable>
                        <Pressable style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "43%" }]} ref={selectLangRef} onPress={() => setShowLang(!showLang)}>
                            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.lang ? flags.find(e => infoObj.lang.toLowerCase().indexOf(e.value.toLowerCase()) >= 0)?.name : t("language_select")}</Text>
                            <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                            {showLang && <View style={[styles.timePicker, { backgroundColor: background.infoBody }]}>
                                <Pressable style={[styles.selectTop, { borderBottomColor: color.border }]} onPress={() => setShowLang(false)}>
                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.lang ? flags.find(e => infoObj.lang.toLowerCase().indexOf(e.value.toLowerCase()) >= 0)?.name : t("language_select")}</Text>
                                    <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                                </Pressable>
                                {flags.map((lang, index) => {
                                    if (infoObj.lang.toLowerCase().indexOf(lang.value.toLowerCase()) >= 0) return;
                                    return (
                                        <Pressable key={index} style={styles.selectItemsLang} onPress={() => {
                                            setInfoObj({ ...infoObj, lang: lang.value });
                                            setShowLang(false);
                                            dispatch(changeLangType(getLang(lang.value)));
                                        }}>
                                            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{lang.name}</Text>
                                        </Pressable>
                                    )
                                })}
                            </View>}
                        </Pressable>
                    </View>
                    <View>
                        {clients?.length > 1 && canEditClient &&
                            <Pressable style={[styles.btnSelect, { backgroundColor: background.infoBody, width: "53%" }]}
                                ref={selectClientRef}
                                onPress={() => setShowClientsPicker(!showClientsPicker)}>
                                <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.client_id ? clients.find(e => infoObj.client_id === e.id)?.name : t("simulation_client")}</Text>
                                <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                                {showClientsPicker && <View style={[styles.timePicker, { backgroundColor: background.infoBody }]}>
                                    <Pressable style={[styles.selectTop, { borderBottomColor: color.border }]} onPress={() => setShowClientsPicker(false)}>
                                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{infoObj.client_id ? clients.find(e => infoObj.client_id === e.id)?.name : t("simulation_client")}</Text>
                                        <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                                    </Pressable>
                                    <ScrollView style={{ maxHeight: "10vw", flex: 1, width: "100%" }} id='chat'>
                                        {clients.map((client, index) => {
                                            if (infoObj.client_id === client.id) return;
                                            return (
                                                <Pressable key={index} style={styles.selectItemsLang} onPress={() => {
                                                    setInfoObj({ ...infoObj, client_id: client.id, lang: client?.language });
                                                    setShowClientsPicker(false);
                                                }}>
                                                    <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{client.name}</Text>
                                                </Pressable>
                                            )
                                        })}
                                    </ScrollView>
                                </View>}
                            </Pressable>
                        }
                    </View>
                </View>
            </View>
            {showAddLogo && <AddLogo flag={showAddLogo} setFlag={() => setShowAddLogo(false)} addLogoFlag={true} setInfoObj={setInfoObj} infoObj={infoObj} />}
            {showColorPicker && <AddLogo flag={showColorPicker} setFlag={() => setShowColorPicker(false)} addLogoFlag={false} setInfoObj={setInfoObj} infoObj={infoObj} />}
        </>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            height: "100%",
            width: "53%",
            zIndex: 3
        },
        simulationInfoBox: {
            flex: 1,
            borderRadius: widthS * 18,
            display: "flex",
            flexDirection: "column",
            paddingHorizontal: widthS * 20,
            paddingBottom: widthS * 40,
            paddingTop: widthS * 30
        },
        top: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        checkBoxView: {
            width: widthS * 12,
            height: widthS * 12,
            borderRadius: widthS * 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: widthS * 6
        },
        checkBoxfill: {
            width: widthS * 6,
            height: widthS * 6,
            borderRadius: widthS * 2,
        },
        nameView: {
            zIndex: 2
        },
        nameInput: {
            height: heightS * 30,
            borderRadius: widthS * 7,
            paddingHorizontal: widthS * 10,
            fontSize: widthS * 9,
            fontFamily: "Catamaran_500Medium",
            // width: widthS * 400,
            width: "100%",
            marginVertical: heightS * 3,
            outlineStyle: "none"
        },
        dateAndtimeView: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: 3
        },
        bottom: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: 2
        },
        btnSelect: {
            height: heightS * 30,
            borderRadius: widthS * 7,
            paddingHorizontal: widthS * 12,
            fontSize: widthS * 9,
            fontFamily: "Catamaran_500Medium",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: heightS * 3,
            position: "relative",
            zIndex: 2
        },
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 7,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_500Medium"
        },
        headers2: {
            fontSize: widthS * 9,
            fontFamily: "Catamaran_500Medium"
        },
        headers3: {
            fontSize: widthS * 9,
            fontFamily: "Catamaran_500Medium"
        },
        calendar: {
            width: widthS * 12,
            height: widthS * 12,
            resizeMode: "contain"
        },
        datePicker: {
            width: "100%",
            zIndex: 5,
            position: "absolute",
            minHeight: heightS * 70,
            borderRadius: widthS * 7,
            display: "flex",
            left: 0,
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.3,
            shadowRadius: 4.4
        },
        timePicker: {
            width: "100%",
            zIndex: 5,
            position: "absolute",
            minHeight: heightS * 130,
            borderRadius: widthS * 7,
            display: "flex",
            left: 0,
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.3,
            shadowRadius: 4.4
        },
        unitsPicker: {
            width: "100%",
            zIndex: 5,
            position: "absolute",
            minHeight: heightS * 70,
            borderRadius: widthS * 7,
            display: "flex",
            left: 0,
            top: 0,
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.3,
            shadowRadius: 4.4
        },
        select: {
            width: "40%",
            zIndex: 5,
            position: "absolute",
            minHeight: heightS * 70,
            borderRadius: widthS * 7,
            display: "flex",
            left: 0,
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.3,
            shadowRadius: 4.4
        },
        selectTop: {
            borderBottomWidth: 1,
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: widthS * 10,
            height: heightS * 30,
            paddingTop: widthS * 5
        },
        selectItems: {
            paddingHorizontal: widthS * 10,
            minHeight: heightS * 28,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
            paddingTop: heightS * 6
        },
        selectItemsLang: {
            paddingHorizontal: widthS * 10,
            minHeight: heightS * 28,
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "relative",
        },
        selectItemsUnits: {
            paddingHorizontal: widthS * 5,
            flex: 1,
        },
        colorsView: {
            flexDirection: "column",
        },
        colorsViewChild: {
            flexDirection: "row",
            justifyContent: "space-between"
        },
        mail_error: {
            borderColor: "red",
            borderWidth: "0.1vw",
            borderRadius: widthS * 9,
        }
    });
};