import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_NEWS_PATH, MARK_AS_READ_NEWS_PATH, URL_PATH } from "@env";
import {
  F_PHPSESSID,
  GAME_ID,
  USER_ID_IN_GAME,
  _F_PHPSESSID_,
  _ID_,
} from "../config.inc";
import { markNews, setNews } from "../redux/actions/index";
import { updateLastTime } from "./contextFunction";

export const NewsContext = createContext();

export default function NewsProvider(props) {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const permission = useSelector((state) => state.gameInfoArr.permission);
  const getNewsData = () => {
    const url = `${URL_PATH}${GET_NEWS_PATH}`;
    const body = new FormData();
    if (permission == 1) {
      body.append("admin_flow", true);
    }
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    const requestOptions = {
      method: "POST",
      body: body
    };

    try {
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res.status !== "false" && res?.news?.length > 0) {
            if (res?.last_update_time) {
              updateLastTime(sessionStorage.getItem("last_update_time"), `${res?.last_update_time} utc`);
            }
            dispatch(setNews(res.news));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) { }
  };

  const markNewsAsRead = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${MARK_AS_READ_NEWS_PATH}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(
        F_PHPSESSID
      ));
      body.append(_ID_, id);
      const requestOptions = {
        method: "POST",
        body: body
      };
      try {
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.status === "true") {// for local changes
              props.sentMessageWebSocket("news", sessionStorage.getItem(USER_ID_IN_GAME));
              resolve(true);
            } else {
              reject(false);
            }
          }).catch((err) => {
            reject(false);
          });
      } catch (e) { reject(false); }
    })
  };

  const addNewNews = (object) => {
    return new Promise((reslove, reject) => {
      const url = ``;

      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append();

      const requestOptions = {
        method: "POST",
        body: body
      }
    });
  }
  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setNews([]));
    }
  }, [props.loginType]);

  useEffect(() => {
    if (gameInfo?.game_name?.length > 0) {
      getNewsData();
    }
  }, [gameInfo]);

  return (
    <NewsContext.Provider
      value={{
        getNewsData,
        markNewsAsRead,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
}
