import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import "./post.css";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Platform,
  TextInput,
  Pressable,
} from "react-native";
import { WebView } from "react-native-webview";
import { SocialContext } from "../../../ContextData/Social";
import ListSharePost from "./ListSharePost";
import { useDispatch, useSelector } from "react-redux";
import * as DocumentPicker from "expo-document-picker";
import { t } from "i18n-js";
import { CREATE_GAME_PERMISSION, USER_ID_IN_GAME } from "../../../config.inc";
import { FontAwesome } from "@expo/vector-icons";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { createFileObject } from "../../modules/CreateFileObject";
import { AntDesign } from "@expo/vector-icons";
import { changeLikePost, loading, showFile } from "../../../redux/actions";
import TextCustomize from "../../Elements/TextCustomize";
import { editDate, getDateTimeObject } from "../../../EditDate";

export default function Post({ data, zIndex, from }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const dispatch = useDispatch();

  const postRef = useRef(null);
  const commentRef = useRef(null);
  const buttonRef = useRef(null);
  const commentInputRef = useRef(null);
  const videoRef = useRef(null);
  const { changeLike, addComment, addNewPost, markPostAsRead } =
    useContext(SocialContext);

  const isInViewport = useIsInViewport(postRef);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const players = useSelector((state) => state.gameInfoArr.gameInfoArr.players);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const [commentText, setCommentText] = useState("");
  const [commentFile, setCommentFile] = useState();
  const [showShareList, setShowShareList] = useState(false);
  const [shareIds, setShareIds] = useState("");
  const [shareAll, setShareAll] = useState(false);
  const [containerWidth, setContainerWidth] = useState();
  const [imageRatio, setImageRatio] = useState();

  // Expo Tel-Aviv
  const [postData, setPostData] = useState({
    post: data.text,
  });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [lastTap, setLastTap] = useState(0);

  const addNewComment = () => {
    if (commentText.trim().length > 0) {
      dispatch(loading(true));
      addComment(data.id, from, commentText, commentFile).then(() => {
        dispatch(loading(false));
        setCommentText("");
        commentRef.current.style.borderWidth = "1px";
      }).catch(() => {
        dispatch(loading(false));
        setCommentText("");
      });

    }
  };

  const sharePost = () => {
    dispatch(loading(true));
    addNewPost(postData).then(() => {
      dispatch(loading(false));
    }).catch(() => {
      dispatch(loading(false));
    });
  };

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  useEffect(() => {
    if (!data.is_read && isInViewport) {
      markPostAsRead(data.id);
    };
  }, [isInViewport]);

  useEffect(() => {
    // Expo Tel-Aviv
    if (data.image) {
      let file = { file: data.image };
      setPostData({ ...postData, file: file });
    }
    if (data.video) {
      let file = { file: data.video };
      setPostData({ ...postData, file: file });
    }
    setImageRatio(Image.getSize(data.image, (width, height) => setImageRatio(height / width)));
  }, [data]);

  return (
    <View ref={postRef} style={styles.container_main(zIndex)}>
      <View
        onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)}
        style={[
          styles.container,
          {
            shadowOpacity: !data.is_read ? 0.6 : 0,
            backgroundColor: background.socialPost,
          },
        ]}
      >
        {/* top-post */}
        <View style={styles.top}>
          <Image
            source={{
              uri: data.sender_img ? data.sender_img : images.user_image,
            }}
            style={styles.user_img}
          />
          <View style={styles.top_right}>
            <Text
              selectable={false}
              style={[styles.user_name, { color: background.socialLogoColor }]}
            >
              {data.sender_by.name}
            </Text>
            <Text selectable={false} style={styles.time}>
              {(() => {
                const { date, time } = getDateTimeObject(`${data.time} utc`);
                return `${date} | ${time}`
              })()}
            </Text>
          </View>
        </View>
        {/* body-post */}
        <View style={{ paddingVertical: heightS * 6 }}>
          <View style={styles.body}>
            {/* post-text */}
            <Text style={styles.body_text}>
              {Platform.OS === "web" ? (
                <div dangerouslySetInnerHTML={{ __html: data.text }} />
              ) : (
                <WebView
                  originWhitelist={["*"]}
                  source={{
                    html: data.text,
                  }}
                />
              )}
            </Text>
          </View>
          {/* post-file */}
          {data.image && (
            <Pressable
              style={{
                position: "relative",
                marginTop: heightS * 4,
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
              onPress={() => {
                let { link, name, type } = createFileObject(false, data.image);
                dispatch(
                  showFile({
                    name: name,
                    type: type,
                    link: link,
                  })
                );
              }}
            >
              <Image
                source={{ uri: data.image }}
                style={[
                  styles.image,
                  {
                    height: Math.min(containerWidth * imageRatio, height * 500),
                  },
                ]}
                loadingIndicatorSource={true}
              />
            </Pressable>
          )}
          {data.video && (
            <video style={{ marginTop: height * 4 }} controls={true}>
              <source src={data.video + '?timestamp=' + new Date().getTime()} />
            </video>
          )}
        </View>
        {/* footer-options */}
        {data?.like_post?.length > 0 && <View style={{ paddingHorizontal: "1.2vw", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
          <View style={styles.countchild}>
            <Image
              source={{
                uri: images.likeCount,
              }}
              style={styles.iconCountChild}
            />
            <TextCustomize colorCurrent={"#7e7e7e"} fontWeight={200} style={{ marginBottom: "0.1vw" }} text={data?.like_post?.length} />
          </View>
          <View style={styles.countchild}>
            {data?.comments?.length > 0 && <>
              <TextCustomize colorCurrent={"#7e7e7e"} fontWeight={200} style={{ marginBottom: "0.1vw" }} text={data?.comments?.length} />
              <Image
                source={{
                  uri: images.commentCount,
                }}
                style={styles.iconCountChild}
              />
            </>}
          </View>
        </View>}
        {playerPermission !== "17" && playerPermission !== "10" && (
          <View
            style={[
              styles.footer,
              {
                borderTopColor: color.socialBorder,
              },
            ]}
          >
            <Pressable
              style={styles.footer_item}
              onPress={() => {
                dispatch(changeLikePost(data.id, from));
                changeLike(data.id, from, data.like_post?.indexOf(from) >= 0 ? "0" : "1");
              }}
            >
              <Image
                source={{
                  uri: data.like_post?.indexOf(from) >= 0 ? images.likeSelectIcon : images.likeIcon,
                }}
                style={styles.footer_icon}
              />
              <Text selectable={false} style={styles.footer_item_text}>
                {t("like")}
              </Text>
            </Pressable>
            <Pressable
              style={styles.footer_item}
              onPress={() => {
                commentRef.current.style.borderWidth = "2px";
                commentInputRef.current.focus();
              }}
            >
              <Image
                source={{ uri: images.commentIcon }}
                style={styles.footer_icon}
              />
              <Text selectable={false} style={styles.footer_item_text}>
                {t("comment")}
              </Text>
            </Pressable>
            {/* {(sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" ||
              playerPermission === "1") && (
                <Pressable
                  style={[
                    styles.footer_item,
                    { backgroundColor: showShareList ? "#a29ca2" : "" },
                  ]}
                  ref={buttonRef}
                  onPress={() => {
                    setShowShareList((prev) => !prev);
                  }}
                >
                  <Image
                    source={{ uri: images.shareIconSocial }}
                    style={styles.footer_icon}
                  />
                  <Text selectable={false} style={styles.footer_item_text}>
                    {t("share")}
                  </Text>
                </Pressable>
              )} */}
            {/* popup list to share post */}
            {showShareList && (
              <ListSharePost
                id={data.id}
                shareIds={shareIds}
                setShareIds={(ids) => setShareIds(ids)}
                shareAll={shareAll}
                setShareAll={(flag) => setShareAll(flag)}
                showShareList={showShareList}
                buttonRef={buttonRef}
                setShowShareList={() => setShowShareList(false)}
                sharePost={sharePost}
              />
            )}
          </View>
        )}
        {/* comments */}
        {data && data.comments.length > 0 && (
          <View style={[styles.comm_c, { borderTopColor: color.socialBorder }]}>
            {data.comments.map((e, i) => {
              return (
                <View key={i} style={styles.comm_m}>
                  <Image
                    source={{
                      uri: e.profile_image ? e.profile_image : images.user_image,
                    }}
                    style={[
                      styles.user_img,
                      { width: widthS * 20, height: widthS * 20 },
                    ]}
                  />
                  <View style={styles.comm_body}>
                    <Text selectable={false} style={styles.comm_name}>
                      {e.name}
                    </Text>
                    <Text style={styles.comm_body_text}>
                      <div dangerouslySetInnerHTML={{ __html: e.body }} />
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}
        {/* write-comment */}
        {playerPermission !== "17" && playerPermission !== "10" && (
          <View
            style={[
              styles.comment_container,
              {
                borderTopColor: color.socialBorder,
              },
            ]}
          >
            <View
              style={[
                styles.comment_view,
                { borderColor: color.socialCommentBorder },
              ]}
              ref={commentRef}
            >
              <TextInput
                ref={commentInputRef}
                style={styles.comment_inp}
                value={commentText}
                onChangeText={(txt) => setCommentText(txt)}
                onSubmitEditing={addNewComment}
              />
            </View>
            <Pressable
              style={[
                styles.new_btn,
                {
                  backgroundColor: background.socialLogoColor,
                },
              ]}
              onPress={addNewComment}
            >
              <Text selectable={false} style={styles.new_btn_text}>
                {t("social-media_post")}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </View >
  );
}
const stylesR = (width, height, heightS, widthS, direction) => {
  return StyleSheet.create({
    container_main: (zIndex) => ({
      paddingHorizontal: widthS * 30,
      zIndex: zIndex + 1,
      direction: direction
    }),
    container: {
      width: "100%",
      marginBottom: heightS * 15,
      display: "flex",
      flexDirection: "column",
      borderRadius: widthS * 11,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 5,
      minHeight: heightS * 130,
    },
    top: {
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: widthS * 18,
    },
    user_img: {
      width: widthS * 25,
      height: widthS * 25,
      resizeMode: "cover",
      borderRadius: 25,
      margin: widthS * 4,
      marginRight: widthS * 6,
    },
    top_right: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    user_name: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_800ExtraBold",
      lineHeight: heightS * 10,
    },
    time: {
      color: "gray",
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 8,
      lineHeight: heightS * 10,
    },
    body: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingHorizontal: widthS * 22,
    },
    body_text: {
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      width: "100%",
    },
    image: {
      width: "100%",
      resizeMode: "cover",
      minHeight: heightS * 200,
      marginTop: heightS * 4,
    },
    countchild: {
      flexDirection: "row",
      alignItems: "center",
      gap: "0.4vw",
    },
    iconCountChild: {
      width: "1vw",
      height: "1vw",
      resizeMode: "contain",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      margin: widthS * 5,
      marginHorizontal: widthS * 10,
      borderTopWidth: widthS * 0.5,
      padding: widthS * 3,
      paddingHorizontal: widthS * 28,
      marginBottom: 0,
      zIndex: 2,
    },
    footer_item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: widthS * 5,
      paddingVertical: heightS * 3.5,
      paddingHorizontal: widthS * 14,
      gap: widthS * 3
    },
    footer_icon: {
      width: widthS * 10,
      height: heightS * 10,
      resizeMode: "contain",
    },
    footer_item_text: {
      fontSize: "0.9vw",
      color: "#7e7e7e",
      fontFamily: "Catamaran_600SemiBold",
    },
    comm_c: {
      display: "flex",
      flexDirection: "column",
      borderTopWidth: 1,
      paddingVertical: heightS * 3,
      paddingHorizontal: widthS * 3,
    },
    comm_m: {
      display: "flex",
      flexDirection: "row",
      paddingVertical: heightS * 5,
    },
    comm_body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#d0d0d0",
      borderRadius: widthS * 4,
      paddingHorizontal: widthS * 5,
      paddingTop: heightS * 2,
      paddingBottom: widthS * 4,
      maxWidth: widthS * 150,
      alignSelf: "flex-start",
    },
    comm_name: {
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 8,
    },
    comm_body_text: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 8,
    },
    comment_container: {
      borderTopWidth: widthS * 0.5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: widthS * 20,
    },
    comment_view: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "center",
      borderWidth: 1,
      borderRadius: widthS * 5,
      marginVertical: heightS * 8,
      backgroundColor: "#eeeeee",
      flex: 0.9,
    },
    comment_inp: {
      width: "93%",
      borderRadius: widthS * 5,
      outlineStyle: "none",
      paddingHorizontal: widthS * 10,
      fontSize: widthS * 8,
      padding: widthS * 1,
      writingDirection: direction,
      fontFamily: "Catamaran_400Regular",
    },
    new_btn: {
      // width: widthS * 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 4,
      paddingHorizontal: widthS * 8,
      flex: 0.06,
      height: heightS * 17,
      // paddingVertical: heightS * 10,
    },
    new_btn_text: {
      color: "white",
      fontFamily: "Catamaran_600SemiBold",
      fontSize: widthS * 8,
    },
  });
};
