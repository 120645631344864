import React from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { USER_GAME_NAME, USER_ID_IN_GAME } from '../../../../../config.inc';
import { CheckTimeZone } from '../../../../modules/CheckTimeZone';
import { setTimeUnits } from '../../../../modules/createTimeColumns';
import { blackList } from "../analyticsTabels/blackList";

export const WordMapFuncContext = createContext();

export default function WordMapFuncProvider(props) {
    // selector
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const challengesSelector = useSelector(state => state.actionsArr.allChallenges);

    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const eventsSelector = useSelector((state) => state.events.events);
    // select filter selector \\
    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const playersSelectSelector = useSelector(state => state.analytics.filter.players);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);
    // end - selector\\
    // state - data
    const [mails, setMails] = useState([]);
    const [social, setSocial] = useState([]);
    const [chat, setChat] = useState([]);
    const [players, setPlayers] = useState([]);
    const [events, setEvents] = useState([]);
    const [actions, setActions] = useState([]);
    const [challenges, setChallenges] = useState([]);
    //\\
    // state - data to show \\
    // expected \\
    const [arrScore, setArrScore] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    const [currentDate, setCurrentDate] = useState();
    // real \\
    const [arrScoreReal, setArrScoreReal] = useState([]);
    const [maxCountReal, setMaxCountReal] = useState(0);
    const [currentDateReal, setCurrentDateReal] = useState();
    //\\
    const [refresh, setRefresh] = useState(false);
    const [filterPlatform, setFilterPlatform] = useState("mail,sent,chat,social,challenges,");

    // select filter
    useEffect(() => {
        setActions(actionsArrSelector);
        setRefresh(!refresh);
    }, [actionsArrSelector]);

    useEffect(() => {
        if (Array.isArray(sendMailsSelector) && sendMailsSelector.length > 0) {
            setMails([...sendMailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [sendMailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (Array.isArray(mailsSelector) && mailsSelector.length > 0) {
            setMails([...mailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }

    }, [mailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            let arr = [...gameInfo?.players];
            arr = gameInfo.players.filter((e) => playersSelectSelector.indexOf(e.id) >= 0 && e.permission_for_game === "15");
            setPlayers(arr);
            setRefresh(!refresh);
        }
    }, [playersSelectSelector, gameInfo?.players]);
    //\\

    useEffect(() => {
        if (challengesSelector.length > 0) {
            setChallenges(challengesSelector);
            setRefresh(!refresh);
        }
    }, [challengesSelector, eventsSelectSelector]);

    useEffect(() => {
        if (socialSelector.length > 0) {
            setSocial(socialSelector);
            setRefresh(!refresh);
        }
    }, [socialSelector.length]);

    useEffect(() => {
        if (chatSelector.length > 0) {
            setChat(chatSelector);
            setRefresh(!refresh);
        }
    }, [chatSelector.length]);

    useEffect(() => {
        if (events.length > 0) {
            setEvents(eventsSelector);
            setRefresh(!refresh);
        }
    }, [eventsSelector.length]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [filterPlatform, actionSelectSelector]);

    const createArrStatistics = (players, events, actions, mails, social, chat, challenges, type, time, timeUnits, lang) => {
        switch (type) {
            case "expected":
                if (actions.length > 0 && players.length > 0) {
                    return addScoreToPlayerExpected(actions, time?.step || time?.time, players, lang);
                } else {
                    setMaxCount(0);
                    return setArrScore([]);
                }
            case "real":
                if (players.length > 0) {
                    return addScoreToPlayers(mails, social, chat, challenges, time?.date, timeUnits, players, lang);
                }
        }
    }

    const checkDate = (timeUnits, itemDate, date1) => {
        let itemDateCurrent = CheckTimeZone(itemDate);
        if (timeUnits.indexOf("h") >= 0) {
            itemDateCurrent.setMinutes(0);
        }
        if (timeUnits.indexOf("d") >= 0) {
            itemDateCurrent.setMinutes(0);
            itemDateCurrent.setHours(0);
        }
        if (date1) {
            if (itemDateCurrent > date1) {
                return false;
            }
        }
        return true;
    }

    const filterwordsByList = (word, lang) => {
        let words = blackList(lang);
        word = word.replace(/\s/g, "").replace(/['"’`]/g,"");
        if (word.match(/[0-9]/g)) {
            return false;
        }
        if (word?.length > 0 && words.length > 0) {
            let wordExsist = words.find((e) => e.toLocaleLowerCase() === word.toLocaleLowerCase()) ? false : true;
            return wordExsist;
        }
        return true;
    }

    const findName = (id) => {
        let names = "";
        if (id && id.length > 0) {
            if (id.indexOf(",") >= 0) {
                id.split(",").forEach((idItem) => {
                    if (idItem === sessionStorage.getItem(USER_ID_IN_GAME)) {
                        names += `${sessionStorage.getItem(USER_GAME_NAME)}, `;
                    } else {
                        gameInfo?.players.forEach((e) => {
                            if (e.id === idItem) {
                                names += `${e.name_for_game}, `;
                            };
                        });
                    }
                })
            } else {
                if (id === sessionStorage.getItem(USER_ID_IN_GAME)) {
                    names = sessionStorage.getItem(USER_GAME_NAME);
                } else {
                    gameInfo?.players.forEach((e) => {
                        if (e.id === id) {
                            names = e.name_for_game;
                        };
                    });
                }
            }
        }
        return names;
    }


    const addScoreToPlayerExpected = (arr, currentDateState, players, lang) => {
        let currentDate = currentDateState;
        let mail = [];
        let social = [];
        let words = [];
        let p1 = document.createElement("p");
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach((e) => {
                if (e.platform_type === "sm") {
                    social.push(e);
                } else if (e.platform_type === "mail" || e.platform_type === "") {
                    mail.push(e);
                }
            });
            if (mail.length > 0) {
                mail.forEach((mail, index) => {
                    if ((currentDate && +mail.time <= currentDate || !currentDate) && players.find((e) => e.id === mail.from_id)) {
                        if (filterPlatform.indexOf("sent") >= 0) {
                            let p = document.createElement("p");
                            p.innerHTML = mail.body;
                            p.innerText.split(" ").forEach((e) => {
                                e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                                if (e?.length > 1 && filterwordsByList(e, lang) && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                    let index = words.findIndex((word) => word.text.toLowerCase() === e.toLowerCase());
                                    p1.innerHTML = mail.body;
                                    if (index >= 0) {
                                        words[index].value += 1;
                                        words[index].messages.push({
                                            from: mail.from,
                                            to: mail.to_list,
                                            body: p1.innerText
                                        });
                                    } else {
                                        words.push({
                                            text: e, value: 1, id: Math.random(), messages: [{
                                                from: mail.from,
                                                to: mail.to_list,
                                                body: p1.innerText,
                                            }]
                                        });
                                    }
                                }
                            })
                        }
                    }
                });
            }
            if (social.length > 0) {
                let countSocial = 0;
                social.forEach((post) => {
                    if ((currentDate && +post.time <= currentDate || !currentDate) && players.find((e) => e.id === post.from_id)) {
                        if (filterPlatform.indexOf("social") >= 0) {
                            let p = document.createElement("p");
                            p.innerHTML = post.body;
                            p.innerText.split(" ").forEach((e) => {
                                e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                                if (e?.length > 1 && filterwordsByList(e, lang) && eventsSelectSelector.indexOf(post?.event_id) >= 0) {
                                    let index = words.findIndex((word) => word.text.toLowerCase() === e.toLowerCase());
                                    p1.innerHTML = post.body;
                                    if (index >= 0) {
                                        words[index].value += 1;
                                        words[index].messages.push({
                                            from: post.from,
                                            to: post.to_list,
                                            body: p1.innerText
                                        });
                                    } else {
                                        words.push({
                                            text: e, id: Math.random(), value: 1, messages: [{
                                                from: post.from,
                                                to: post.to_list,
                                                body: p1.innerText,

                                            }]
                                        });
                                    }
                                }
                            })
                        }
                    }
                });
            }
            words.sort((a, b) => b.value - a.value);
            return setArrScore([...words]);
        }
    }

    const addScoreToPlayers = (mails, social, chat, challenges, currentDate, timeUnits, players, lang) => {
        let datefilterCurrent1 = currentDate ? new Date(currentDate) : null;
        let words = [];
        let p1 = document.createElement("p");
        if (Array.isArray(mails) && mails.length > 0) {
            mails.forEach((mail, index) => {
                if (filterPlatform.indexOf("sent") >= 0 && checkDate(timeUnits, mail.time, datefilterCurrent1) && players.find((e) => e.id === mail.sender.id)) {
                    let p = document.createElement("p");
                    p.innerHTML = mail.body;
                    let wordsString = p.innerText.indexOf("From:") >= 0 ? p.innerText.slice(0, p.innerText.indexOf("From:")) : p.innerText;
                    wordsString.split(" ").forEach((e) => {
                        e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                        if (e?.length > 1 && filterwordsByList(e, lang)) {
                            let index = words.findIndex((word) => word.text.toLowerCase() === e.toLowerCase());
                            p1.innerHTML = mail.body;
                            if (index >= 0) {
                                words[index].value += 1;
                                words[index].messages.push({
                                    from: mail.sender.name,
                                    to: mail.to_list_names,
                                    body: p1.innerText
                                });
                            } else {
                                words.push({
                                    text: e, value: 1, id: Math.random(), messages: [{
                                        from: mail.sender.name,
                                        to: mail.to_list_names,
                                        body: p1.innerText
                                    }]
                                });
                            }
                        }
                    });
                }
            });
        }
        if (Array.isArray(social) && social.length > 0 && filterPlatform.indexOf("social") >= 0) {
            social.forEach((post, index) => {
                if (filterPlatform.indexOf("social") >= 0 && checkDate(timeUnits, post.time, datefilterCurrent1) && players.find((e) => e.id === post.sender_by.id)) {
                    let p = document.createElement("p");
                    p.innerHTML = post.text;
                    p.innerText.split(" ").forEach((e) => {
                        e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                        if (e?.length > 1 && filterwordsByList(e, lang)) {
                            let index = words.findIndex((word) => word.text.toLowerCase() === e.toLowerCase());
                            p1.innerHTML = post.text;
                            if (index >= 0) {
                                words[index].value += 1;
                                words[index].messages.push({
                                    from: post.sender_by.name,
                                    to: post.to_list ? post.to_list : "all",
                                    body: p1.innerText
                                })
                            } else {
                                words.push({
                                    text: e, value: 1, id: Math.random(), messages: [
                                        {
                                            from: post.sender_by.name,
                                            to: post.to_list ? post.to_list : "all",
                                            body: p1.innerText
                                        }
                                    ]
                                });
                            }
                        }
                    })
                }
            });
        }
        if (Array.isArray(chat) && chat.length > 0 && filterPlatform.indexOf("chat") >= 0 && actionSelectSelector.indexOf("Chat Messages") >= 0) {
            chat.forEach((chatItem) => {
                if (filterPlatform.indexOf("chat") >= 0 && checkDate(timeUnits, chatItem.time, datefilterCurrent1) && players.find((e) => e.id === chatItem.sender_id)) {
                    let p = document.createElement("p");
                    p.innerHTML = chatItem.message_body;
                    p.innerText.split(" ").forEach((e) => {
                        e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                        if (e?.length > 1 && filterwordsByList(e, lang)) {
                            let index = words.findIndex((word) => word.text.toLowerCase() === e.toLowerCase());
                            p1.innerHTML = chatItem.message_body;
                            if (index >= 0) {
                                words[index].value += 1;
                                words[index].messages.push({
                                    from: findName(chatItem.sender_id),
                                    to: findName(chatItem.recipient_id),
                                    body: p1.innerText
                                });
                            } else {
                                words.push({
                                    text: e, value: 1, id: Math.random(), messages: [
                                        {
                                            from: findName(chatItem.sender_id),
                                            to: findName(chatItem.recipient_id),
                                            body: p1.innerText
                                        }
                                    ]
                                });
                            }
                        }
                    });
                }
            });
        }
        if (Array.isArray(challenges) && challenges.length > 0 && filterPlatform.indexOf("challenges") >= 0 && actionSelectSelector.indexOf("Decisions") >= 0) {
            challenges.forEach((action, index) => {
                if (checkDate(timeUnits, action.action.time, datefilterCurrent1) && players.find((e) => e.id === action.action.creator_id)) {
                    let p = document.createElement("p");
                    p.innerHTML = action.action.description;
                    p.innerText.split(" ").forEach((e) => {
                        e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "");
                        if (e.length > 1 && filterwordsByList(e, lang)) {
                            let index = words.findIndex((word) => word.word === e);
                            p1.innerHTML = action.action.description
                            if (index >= 0) {
                                words[index].value += 1;
                                words[index].messages.push({
                                    from: findName(action.action.creator_id),
                                    to: findName(action.action.share_list),
                                    body: p1.innerText
                                })
                            } else {
                                words.push({
                                    text: e, value: 1, id: Math.random(), messages: [
                                        {
                                            from: findName(action.action.creator_id),
                                            to: findName(action?.action?.share_list),
                                            body: p1.innerText
                                        }
                                    ]
                                });
                            }
                        }
                    });
                }
            });
        }
        words.sort((a, b) => b.value - a.value);
        return setArrScoreReal([...words]);
    }

    const createtimes = (times, arr, type) => {
        let arrN = [...times];
        if (arr.length > 0) {
            if (type === "chat") {
                arr?.forEach((e) => {
                    e.newMsg?.forEach((e) => {
                        arrN.push(e.time);
                    });
                    e.msgHistory?.forEach((e) => {
                        arrN.push(e.time);
                    });
                });
            } else if (type === "challenges") {
                arr?.forEach((e) => {
                    arrN.push(e.action.time);
                });
            } else {
                arr?.forEach((e) => {
                    arrN.push(e.time);
                });
            }
        }
        return arrN;
    }

    const returnIfIsThefirstOrLast = (timeCurrent, time, type) => {
        switch (type) {
            case "start":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time < timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
            case "end":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time > timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
        }
    }

    const findLastTime = (chatP, challengesP, mailsP, socialP) => {
        let firstTime;
        let endTime;
        if (chatP?.length > 0) {
            chatP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (challengesP?.length > 0) {
            challengesP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.action.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.action.time}`), 'end');
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (socialP?.length > 0) {
            socialP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time} gmt`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time} gmt`), 'end');
            });
        }
        return {
            firstTime,
            endTime
        };
    }

    const filterStatistics = (timeUnits, type, time, lang) => {
        if (type === "expected") {
            if (actions.length > 0) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    timeUnits,
                    lang
                )
            } else {
                setMaxCount(0);
                setArrScore([]);
            }
        } else if (type === "real") {
            if ((mails.length > 0 || chat.length > 0 || social.length > 0 || challenges.length > 0)) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    timeUnits,
                    lang
                )
            } else {
                setMaxCountReal(0);
                setArrScoreReal([]);
            }
        }
    }

    return (
        <WordMapFuncContext.Provider value={{
            arrScore,
            arrScoreReal,
            maxCount,
            maxCountReal,
            filterPlatform,
            setFilterPlatform,
            filterStatistics,
            setCurrentDate,
            setCurrentDateReal,
            currentDate,
            currentDateReal,
            refresh,
            setRefresh
        }}>
            {props.children}
        </WordMapFuncContext.Provider>
    )
}
