import {
  StyleSheet,
  Text,
  View,
  Image,
} from "react-native";

import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { Pressable, TouchableHighlight } from "react-native-web";
import { useSelector, useDispatch } from "react-redux";
import { Ionicons } from "@expo/vector-icons";
import { t } from "i18n-js";
import "./style.css";
import { listenerAddBT } from "../../../../AddListener";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import Column from "./Column";
import DraggedColumn from "../../../modules/DraggedColumn";
import Zoom from "../../../modules/Zoom";
import { vwToPx } from "../../../modules/ConvertPXToVw";
import { ZoomStatic } from "../../../modules/ZoomStatic";
import { changeFlowTimeUnits, markPlayer, setFlowTimeColumns } from "../../../../redux/actions/creatorActions";
import { SwimlaneChartContext } from "../../../../ContextData/Swimlanechart";
import AnimationLoading from "../../../modules/AnimationLoading";

export default function Flow({
  infoObj,
  setInfoObj,
  buildRef,
  flowTable,
  newScroll,
  indexPlayer,
  round,
  setLoad,
  listPlayersRef,
  load,
  scrollRef,
  topPlayers,
  heightView
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS, Zoom);

  const itemRef = useRef(null);

  const unitsBtnRef = useRef();
  const unitsPopupRef = useRef();
  const actionsMetrix = useRef([]);
  const columnRef = useRef(null);

  const horizontalScroll = useRef(null);

  const { sendAction } = useContext(SwimlaneChartContext);

  const dispatch = useDispatch();
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const zoomSize = useSelector((state) => state.swimlane.zoom);
  const actionsArrState = useSelector((state) => state.swimlane.actions);
  const injects = useSelector((state) => state.swimlane.inject);
  const realPlayers = useSelector((state) => state.players.realPlayers);
  const repPlayers = useSelector((state) => state.players.repPlayers);
  const flowTimeColumns = useSelector(
    (state) => state.swimlane.flowTimeColumns
  );
  const playerCurrent = useSelector(state => state.swimlane.playerCurrent);
  const flowFilters = useSelector((state) => state.swimlane.filters);

  // const scroll = useSelector((state) => state.swimlane.scroll);

  const [flowTableSteps, setFlowTableSteps] = useState([]);
  const [showUints, setShowUnits] = useState(false);
  const [showTypeUnits, setShowTypeUnits] = useState("minutes");
  const [actionsArr, setActionsArr] = useState([]);
  const [indexFlag, setIndexFlag] = useState();
  const [hovered, setHovered] = useState();
  const [action, setAction] = useState({});
  const [loading, setLoading] = useState(true);

  const childComponents = [];

  const handleChildRenderComplete = () => {
    // טיפול בסיום רנדור של הקומפוננטה הילד
    if (childComponents.every(component => component.isRenderComplete)) {
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    }
  };

  const checkIfIsFromReal = (action) => {
    let flag = false;
    if (action && realPlayers?.length > 0) {
      realPlayers.forEach((player) => {
        if (player.id === action.from_id) {
          flag = true;
        }
      })
    }
    return flag
  }

  const filterActions = async () => {
    if (flowFilters.player.length > 0 ||
      flowFilters.event.length > 0 ||
      flowFilters.platform.length > 0) {
      let temp = [...actionsArrState];
      await setActionsArr([]);
      //await setFlowTableSteps([]);
      temp = temp.filter(
        (action) =>
          (!flowFilters.player ||
            flowFilters.player.indexOf(action.from_id) > -1 ||
            action.to_list_ids
              .split(",")
              .filter((id) => id.trim())
              .some((id) => flowFilters.player.indexOf(id.trim()) > -1)) &&
          (!flowFilters.event ||
            flowFilters.event.indexOf(action.event_id) > -1) &&
          (!flowFilters.platform ||
            flowFilters.platform.indexOf(action.platform_type) > -1)
      );
      setActionsArr([...temp]);
      if (temp.length == 0) {
        let arr = [];
        let j = actionsArrState[actionsArrState.length - 1].time + 1;
        for (let i = 0; i < flowTimeColumns.length; i++) {
          arr.push(j);
          j++;
        }
        setFlowTableSteps(arr);
      }
    } else {
      setActionsArr([...actionsArrState]);
    }
  };

  const sendInject = async (action) => {
    setLoad(true);
    await sendAction(action)
      .then((res) => setLoad(false))
      .catch((err) => console.log(err));
    setLoad(false);
    // let temp = [...actionsArr];
    // temp.forEach((e) => {
    //   if (e.id === action.id) {
    //     e.is_done = true;
    //   }
    // });
    // return setActionsArr([...temp]);
  };

  const popup = (
    <View
      style={styles.popupContainer}
      ref={unitsPopupRef}
      nativeID="action-tooltip"
    >
      <View
        style={[styles.triangle, { borderBottomColor: background.infoBody }]}
      />
      <View
        style={[styles.unitsPicker, { backgroundColor: background.infoBody }]}
      >
        <Pressable
          style={[
            styles.selectItems,
            {
              paddingHorizontal: showTypeUnits !== "minutes" && widthS * 10,
              paddingVertical: showTypeUnits !== "minutes" && heightS * 3,
            },
          ]}
          onPress={() => setShowTypeUnits("minutes")}
        >
          {showTypeUnits !== "minutes" && (
            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
              {t("minutes_units")}
            </Text>
          )}
          {showTypeUnits === "minutes" && (
            <View style={styles.selectItems}>
              {["05", "10", "30"].map((typeTime, index) => {
                return (
                  <Pressable
                    key={index}
                    style={({ hovered }) => [
                      styles.selectItemsUnits,
                      { backgroundColor: hovered && background.infoBox },
                    ]}
                    onPress={() => {
                      setInfoObj({
                        ...infoObj,
                        timeUnits: `${t("minutes_units")}: ${typeTime}`,
                      });
                      dispatch(changeFlowTimeUnits(`${typeTime}m`))
                      setShowUnits(false);
                    }}
                  >
                    <Text
                      style={[
                        styles.headers3,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {typeTime} {t("minutes_units")}
                    </Text>
                  </Pressable>
                );
              })}
            </View>
          )}
        </Pressable>
        <Pressable
          style={[
            styles.selectItems,
            {
              paddingHorizontal: showTypeUnits !== "hours" && widthS * 10,
              paddingVertical: showTypeUnits !== "hours" && heightS * 3,
            },
          ]}
          onPress={() => setShowTypeUnits("hours")}
        >
          {showTypeUnits !== "hours" && (
            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
              {t("hours_units")}
            </Text>
          )}
          {showTypeUnits === "hours" && (
            <View style={styles.selectItems}>
              {["1", "2", "3"].map((typeTime, index) => {
                return (
                  <Pressable
                    key={index}
                    style={({ hovered }) => [
                      styles.selectItemsUnits,
                      { backgroundColor: hovered && background.infoBox },
                    ]}
                    onPress={() => {
                      setInfoObj({
                        ...infoObj,
                        timeUnits: `${t("hours_units")}: ${typeTime}`,
                      });
                      dispatch(changeFlowTimeUnits(`${typeTime}h`))
                      setShowUnits(false);
                    }}
                  >
                    <Text
                      style={[
                        styles.headers3,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {typeTime} {t("hours_units")}
                    </Text>
                  </Pressable>
                );
              })}
            </View>
          )}
        </Pressable>
        <Pressable
          style={[
            styles.selectItems,
            {
              paddingHorizontal: showTypeUnits !== "days" && widthS * 10,
              paddingVertical: showTypeUnits !== "days" && heightS * 3,
            },
          ]}
          onPress={() => setShowTypeUnits("days")}
        >
          {showTypeUnits !== "days" && (
            <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
              {t("days_units")}
            </Text>
          )}
          {showTypeUnits === "days" && (
            <View style={styles.selectItems}>
              {["1", "2", "3"].map((typeTime, index) => {
                return (
                  <Pressable
                    key={index}
                    style={({ hovered }) => [
                      styles.selectItemsUnits,
                      { backgroundColor: hovered && background.infoBox },
                    ]}
                    onPress={() => {
                      setInfoObj({
                        ...infoObj,
                        timeUnits: `${t("days_units")}: ${typeTime}`,
                      });
                      dispatch(changeFlowTimeUnits(`${typeTime}d`))
                      setShowUnits(false);
                    }}
                  >
                    <Text
                      style={[
                        styles.headers3,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {typeTime} {t("days_units")}
                    </Text>
                  </Pressable>
                );
              })}
            </View>
          )}
        </Pressable>
      </View>
    </View>
  );

  const injectView = (i, step, action, lastAction, endColumn) => {
    let ifIsFromReal = checkIfIsFromReal(action);
    return (
      <View key={i} style={styles.injectView}>
        <View
          style={{
            height: "3vw",
            position: "sticky",
            zIndex: 2,
            paddingBottom: heightS * ZoomStatic(4, zoomSize),
            top: "2.5vw",
            borderBottomColor: background.infoBox,
            borderBottomWidth: heightS * ZoomStatic(6, zoomSize),
            borderRightColor: background.infoBox,
            borderRightWidth: !endColumn ? 0 : heightS * ZoomStatic(6, zoomSize),
            alignItems: "center",
            width: widthS * ZoomStatic(78, zoomSize),
            // widthS * Zoom(78)
            backgroundColor: background.infoBody
          }}
        >
          <Pressable
            onPress={() => { sendInject(action) }}
            disabled={
              !action || action.is_done || ifIsFromReal
            }
            style={[
              styles.sendBtn,
              {
                backgroundColor: !action || action == undefined || action.is_done || ifIsFromReal
                  ? color.infoBorder
                  : "#201C21",
              },
            ]}
          >
            <Ionicons
              name="md-send-outline"
              style={styles.iconSend}
              color="#61596c"
            />
          </Pressable>
        </View>

        <Column
          round={round}
          onRenderComplete={lastAction && handleChildRenderComplete}
          indexPlayer={indexPlayer}
          indexFlag={indexFlag}
          actionsArr={actionsArr}
          action={
            action
              ? { ...action }
              : null
          }
          buildRef={buildRef}
          index={i}
          columnIndex={i}
          actionsMetrixState={actionsMetrix}
        />
      </View>
    );
  };

  useEffect(() => {
    filterActions();
  }, [flowFilters]);

  useEffect(() => {
    setShowUnits(showUints);
  }, [zoomSize]);

  useEffect(() => {
    if (actionsArrState?.length > 0) {
      // setLoading(true);
      // setIndex(0);
      actionsMetrix.current = [];
      dispatch(setFlowTimeColumns(actionsArrState, round));
      setActionsArr([...actionsArrState].sort((a, b) => a.time - b.time));
    } else {
      setLoading(false);
      actionsMetrix.current = [];
      dispatch(setFlowTimeColumns(actionsArrState, round));
      setActionsArr([...actionsArrState]?.sort((a, b) => a.time - b.time));
    }
  }, [actionsArrState, round])

  const restartFlow = (actionsArr) => {
    dispatch(setFlowTimeColumns(actionsArr, round));
    actionsMetrix.current = [];
    actionsArr?.length > 0 &&
      actionsArr.map((e) => {
        actionsMetrix.current.push([]);
      });
    return;
  }

  useEffect(() => {
    if (actionsArr.length > 0) {
      restartFlow(actionsArr);
    }
  }, [actionsArr, realPlayers, repPlayers, round]);

  useEffect(() => {
    setFlowTableSteps([...flowTimeColumns]);
  }, [flowTimeColumns]);

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAddBT(unitsPopupRef, unitsBtnRef, showUints, () =>
        setShowUnits(false)
      )
    );
    return () => {
      document.addEventListener(
        "mousedown",
        listenerAddBT(unitsPopupRef, unitsBtnRef, showUints, () =>
          setShowUnits(false)
        )
      );
    };
  }, [showUints]);

  useEffect(() => {
    horizontalScroll.current?.scrollTo({
      x: newScroll,
      animated: false,
    });
  }, [newScroll]);

  return <>
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        minHeight: "57vh",
      }}
    >
      {/* <ScrollView
        nativeID="scroll-flow"
        horizontal
        ref={horizontalScroll}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[
          {
            borderColor: color.infoBorder,
          },
        ]}
      > */}
      {/* <View
        style={{
          width: widthS * 40,
          alignItems: "center",
          paddingTop: heightS * 10,
          zIndex: 3,
          position: "sticky",
          top: 0,
          backgroundColor: background.infoBody,
          borderBottomColor: background.infoBox,
          borderBottomWidth: "0.6vw",
          height: "5.5vw"
        }}
      >
        <Pressable
          style={{
            justifyContent: "center",
            marginLeft: widthS * 5,
          }}
          onPress={() => setShowUnits(!showUints)}
          ref={unitsBtnRef}
        >
          <Text
            style={{
              fontSize: widthS * 8,
              fontWeight: "300",
              color: color.infoHeaderNavText,
              flexWrap: "wrap",
            }}
          >
            {t("time_units_flow").toUpperCase()}
            <Ionicons
              size={widthS * 8}
              name="caret-down"
              color={color.infoHeaderNavText}
            />
            {showUints && popup}
          </Text>
        </Pressable>
      </View> */}
      <View nativeID='export-flow' ref={flowTable} style={{
        flexDirection: "row",
        height: "100%",
        minWidth: "70vw"
      }}>
        {(() => {
          let indexNew = 0;
          let flag = false;
          let lastActionIndex = -1;

          flowTableSteps.forEach((step, index) => {
            const actions = actionsArr.filter((action) => action.time === +step);
            if (actions.some(action => action != null)) {
              lastActionIndex = index;
            }
          });
          return flowTableSteps.map((step, i) => { //flowTableSteps[0] !== und &&
            let index = indexNew;
            const actions = actionsArr.filter(
              (action) => action.time === +step
            );
            if (+flowTableSteps[1] === 2) {
            }
            let widthColumn = ZoomStatic(widthS * ZoomStatic(78, zoomSize), zoomSize) * (actions.length || 1) - vwToPx(0.6);
            return (
              <DraggedColumn
                itemRef={itemRef}
                key={i}
                indexFlag={indexFlag}
                setIndexflag={() => setIndexFlag()}
                x={(actions.length > 0 ? widthS * 78 * actions.length : widthS * 78) + (flowTableSteps[i + 1] - flowTableSteps[i] > 1 ? widthS * ZoomStatic(20, zoomSize) : 0)}
                index={i}
                // y={listPlayersRef?.current?.getBoundingClientRect()?.height - 100}
                id={actionsArr[i]?.id}
                arrLength={actionsArr.length - 1}
                flowTableSteps={flowTableSteps}
              >
                <View
                  key={i}
                  style={[
                    styles.column,
                    {
                      backgroundColor: background.infoBody,
                      width: widthS * ZoomStatic(78, zoomSize) * (actions.length || 1),
                      zIndex: 2,
                      // height: heightView,
                      borderRightColor: background.infoBox,
                      borderRightWidth: flowTableSteps[i + 1] - flowTableSteps[i] > 1 ? 0 : widthS * ZoomStatic(6, zoomSize),
                    }, ,
                    // flowTableSteps[i] < flowTableSteps.length - 1 && +flowTableSteps[i + 1] - +flowTableSteps[i] > 1 ? {
                    //   border: "none",
                    // } : { borderRightColor: background.infoBox, }
                  ]}
                >
                  <View style={{ minHeight: "100%" }}>
                    <Pressable
                      onHoverIn={() => setHovered(i + 1)}
                      onHoverOut={() => setHovered("")}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "sticky",
                        backgroundColor: background.infoBody,
                        // width: "100%",
                        top: 0,
                        zIndex: 2,
                        height: "2.5vw",
                        width: widthColumn,
                      }}
                    ><Text
                      style={[
                        styles.stepText,
                        {
                          fontWeight: "300",
                          color: color.infoHeaderNavText,
                        },
                      ]}
                    >
                        {`T${step || i + 1}`}
                      </Text>
                      {/* {hovered === i + 1 && actionsArr[i] && (
                        <TouchableHighlight
                          underlayColor={""}
                          style={[
                            styles.btnDrag,
                            {
                              display: hovered ? "" : "none",
                              zIndex: 1,
                              position: "absolute",
                              transform: [{ scale: i === indexFlag ? 1.2 : 1 }],
                            },
                          ]}
                          onLongPress={() => setIndexFlag(i)}
                        >
                          <Image
                            source={{ uri: images.menuIcon }}
                            style={[styles.dragIcon, { resizeMode: "contain" }]}
                          />
                        </TouchableHighlight>
                      )} */}
                    </Pressable>
                    <View
                      style={{
                        flexDirection: "row",
                        // minWidth: widthS * ZoomStatic(78, zoomSize) * (actions.length || 1),
                        position: "relative",
                        minHeight: "100%",
                        justifyContent: "center"
                      }}
                    >
                      {(() => {
                        if (actions.length > 1) {
                          return actions.map((action, indexAction) => {
                            if (indexAction > 0 || flag) {
                              indexNew += 1;
                              index = indexNew;
                              flag = false;
                            }
                            if (indexAction === actions.length - 1) {
                              flag = true;
                            }
                            let realOrNot = checkIfIsFromReal(action);
                            return injectView(index, step, action, lastActionIndex === i, flag && i < flowTableSteps.length - 1 && +flowTableSteps[i + 1] - +flowTableSteps[i] === 1);
                          })
                        } else {
                          if (index > 0 || flag) {
                            indexNew += 1;
                            index = indexNew;
                          }
                          flag = true;
                          let realOrNot = checkIfIsFromReal(action);
                          return injectView(index, step, actions[0], lastActionIndex === i, i < flowTableSteps.length - 1 && +flowTableSteps[i + 1] - +flowTableSteps[i] >= 1);
                        }
                      })()}
                    </View>
                  </View>
                  {i < flowTableSteps.length - 1 && +flowTableSteps[i + 1] - +flowTableSteps[i] > 1 &&
                    <View
                      style={{
                        width: widthS * ZoomStatic(20, zoomSize),
                        height: itemRef?.current?.getBoundingClientRect()?.height,
                        backgroundColor: background.infoBox,
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "100%",
                        paddingVertical: widthS * ZoomStatic(40, zoomSize)
                      }}
                    >
                      {[{ text: t("time_gap") }, { text: t("time_gap") }, { text: t("time_gap") },].map((e, i) => {
                        return (
                          <Text
                            style={[{
                              transform: [{ rotate: "270deg" }],
                              width: "max-content",
                              fontSize: widthS * ZoomStatic(8, zoomSize),
                              textAlign: "center",
                              color: color.text2,
                            }]}
                            key={i}>*** {e.text} ***</Text>
                        )
                      })}
                    </View>
                  }
                </View>
              </DraggedColumn>
            );
          })
        }
        )()}
        <AnimationLoading flag={loading} />
      </View>
      {/* </ScrollView> */}
    </View>
    {/* {load && <View
      style={{
        flexDirection: "row",
        width: scrollRef?.current?.getBoundingClientRect()?.width,
        height: heightView,
      }}
    />} */}
  </>
}

const stylesR = (width, height, heightS, widthS, Zoom) => {
  return StyleSheet.create({
    popupContainer: {
      top: heightS * 26,
      left: widthS * 6,
      position: "absolute",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 2,
    },
    triangle: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: widthS * 10,
      borderRightWidth: widthS * 10,
      borderBottomWidth: widthS * 10,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      right: widthS * 22.5,
      zIndex: 6,
    },
    unitsPicker: {
      width: widthS * 93,
      marginTop: heightS * 0.01,
      zIndex: 5,
      minHeight: heightS * 110,
      borderRadius: widthS * 10,
      paddingVertical: heightS * 12,
      display: "flex",
      flexDirection: "column",
    },
    selectItems: {
      display: "flex",
      position: "relative",
    },
    selectItemsUnits: {
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 3,
      flex: 1,
      justifyContent: "flex-start",
    },
    injectView: {
      flex: 1,
      alignItems: "center",
      // position: "absolute",
      // marginTop: realPlayers?.length > 0 ? ZoomStatic(topPlayers?.current?.getBoundingClientRect().y -
      //   topPlayers?.current?.getBoundingClientRect().height -
      //   flowTable?.current?.getBoundingClientRect().y -
      //   heightS * 11, zoomSize) : ZoomStatic(topPlayers?.current?.getBoundingClientRect().y -
      //     topPlayers?.current?.getBoundingClientRect().height -
      //     flowTable?.current?.getBoundingClientRect().y -
      //     heightS * 40 - widthS * 15, zoomSize),
      // // width: "100%"
      // marginTop: Zoom("2.5vh"),
      width: widthS * Zoom(78),
    },
    sendBtn: {
      borderRadius: "50%",
      width: widthS * Zoom(15),
      height: widthS * Zoom(15),
      justifyContent: "center",
      alignItems: "center",
      marginVertical: heightS * 5,
    },
    column: {
      // width: widthS * Zoom(78),
      alignItems: "center",
      flexDirection: "row",
    },
    stepText: {
      fontSize: widthS * Zoom(9),
    },
    btnDrag: {
      right: widthS * Zoom(10),
    },
    dragIcon: {
      width: widthS * Zoom(8),
      height: widthS * Zoom(8),
    },
    iconSend: {
      fontSize: widthS * Zoom(8),
    },
  });
};
