import { StyleSheet, Text, View, TextInput, ScrollView, Pressable } from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import UseResponsiveSize from '../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../modules/UseResponsiveScreen';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { USER_ID_IN_GAME } from '../../../config.inc';
import InputRadio from '../../../InputRadio';
import { ChatContext } from '../../../ContextData/Chat';
import { listenerAddBT } from '../../../AddListener';
import { loading, newChat, setErrorMessage } from '../../../redux/actions';
import { EvilIcons } from '@expo/vector-icons';

export default function GroupChatPopup({
    type,
    flag,
    setFlag,
    btnRef,
    setIndex,
    setNewData,
    chatRef,
    fromAndTo,
    idsGroup,
    id,
    right,
    name,
    data,
    setTo,
    newGroup,
    resetIndex
}) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS, direction);

    const dispatch = useDispatch();

    const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const playerPermission = useSelector((state) => state.gameInfoArr.permission);

    const { createOrEditGroupData } = useContext(ChatContext);

    const [idsState, setIdsState] = useState()

    const [groupPlayersNames, setGroupPlayersNames] = useState("");
    const [searchTxt, setSearchTxt] = useState("");
    const [newGroupName, setNewGroupName] = useState("");
    const [groupPlayers, setGroupPlayers] = useState("");
    const [err, setErr] = useState(false);

    const players = useMemo(() => {
        let playersChatArr = [];
        if (gameInfo) {
            gameInfo.players.map((e) => {
                if (e.id === sessionStorage.getItem("creatorUserId") || (searchTxt && e.name_for_game?.toLowerCase().indexOf(searchTxt?.toLowerCase()) < 0) || e.permission_for_game === "17" || e.permission_for_game === "1") return;
                playersChatArr.push({ id: e.id, name: e.name_for_game });
            });
            return playersChatArr;
        }
    }, [searchTxt, gameInfo]);

    const realPlayers = useMemo(() => {
        let playersChatArr = [];
        if (gameInfo) {
            gameInfo.players.map((e) => {
                if (e.id === sessionStorage.getItem("creatorUserId") || (searchTxt && e.name_for_game?.toLowerCase().indexOf(searchTxt?.toLowerCase()) < 0) || e.permission_for_game === "17" || e.permission_for_game === "1" || e.is_represented === "1") return;
                playersChatArr.push({ id: e.id, name: e.name_for_game });
            });
            return playersChatArr;
        }
    }, [searchTxt, gameInfo]);

    const shareAll = useMemo(() => players.length === groupPlayers.split(",").filter((e) => e.trim()).length, [players, groupPlayers]);
    const shareReal = useMemo(() => realPlayers.every(p => groupPlayers.indexOf(p.id) >= 0), [realPlayers, groupPlayers]);

    const editGroupList = (id, name) => {
        let ids = groupPlayers;
        let names = groupPlayersNames;
        if (groupPlayers.indexOf(id) >= 0) {
            ids = ids.replace(`${id},`, "");
            names = names.replace(`${name},`, "");
        } else {
            ids += `${id},`;
            names += `${name},`;
        }
        setGroupPlayers(ids);
        setGroupPlayersNames(names);
    };

    const editAll = () => {
        if (shareAll) {
            setGroupPlayers("");
            setGroupPlayersNames("");
        } else {
            let ids = "";
            let names = "";
            players.forEach((e) => {
                ids += `${e.id}, `;
                names += `${e.name}, `;
            });
            setGroupPlayers(ids);
            setGroupPlayersNames(names);
        }
    };

    const editReal = () => {
        let ids = groupPlayers;
        let names = groupPlayersNames;
        if (shareReal) {
            let filteredIds = ids.split(",").filter(id => !realPlayers.some(player => player.id === id.trim())).join(",").trim();
            let filteredNames = names.split(",").filter(name => !realPlayers.some(player => player.name_for_game === name.trim())).join(",").trim();
            setGroupPlayers(filteredIds);
            setGroupPlayersNames(filteredNames);
        } else {
            realPlayers.filter(e => groupPlayers.indexOf(e.id) === -1).forEach((e) => {
                ids += `${e.id}, `;
                names += `${e.name}, `;
            });
            setGroupPlayers(ids);
            setGroupPlayersNames(names);
        }
    };

    const createChat = (name, id) => {
        setIndex(resetIndex());
        dispatch(newChat({
            sender_id: fromAndTo.from,
            recipient_id: id,
            name: name
        }));
        setNewData({
            sender_id: null,
            recipient_id: null
        });
        setTo(id);
        setFlag();
    };

    const createGroup = () => {
        let groupPlayersText = groupPlayers;
        if (groupPlayers.length === 0) {
            return setErr("players");
        }
        if (newGroupName.length === 0) {
            return setErr("name");
        }
        if (groupPlayersText.length > 0 && newGroupName.length > 0) {
            dispatch(loading(true));
            setErr(false);
            setIndex(resetIndex());
            if (groupPlayersText.indexOf(fromAndTo?.from) === -1) {
                groupPlayersText += `${fromAndTo?.from}`;
            }
            const chat = {
                group_name: newGroupName,
                players: groupPlayersText
            };
            if (!newGroup) {
                chat.id = fromAndTo?.to;
            }
            // dispatch(newChat(chat));
            createOrEditGroupData(chat).
                then(() => {
                    dispatch(loading(false));
                    setNewData(chat);
                    setNewGroupName("");
                    setGroupPlayersNames("");
                    setGroupPlayers("");
                    setFlag();
                }).catch(() => {
                    dispatch(loading(false));
                    dispatch(
                        setErrorMessage(
                            true,
                            t("oops"),
                            t("alert_mail_error")
                        )
                    );
                });
        }
    };

    const SearchElements = (
        <View
            style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: background.chatSearch,
                borderBottomWidth: 1,
                borderColor: color.border,
                padding: "0.3vw",
                writingDirection: direction,
            }}
        >
            <View
                style={[
                    styles.search_view,
                    { backgroundColor: background.popup1 },
                ]}
            >
                <TextInput
                    selectTextOnFocus={false}
                    placeholderTextColor={color.chatSearch}
                    style={[styles.search_inp, { outlineStyle: "none" }]}
                    placeholder={t("search")}
                    onChangeText={(txt) => setSearchTxt(txt)}
                />
                <EvilIcons
                    selectable={false}
                    style={styles.search_icon}
                    name="search"
                    size={widthS * 13}
                    color={color.chatSearch}
                />
            </View>
        </View>
    );

    useEffect(() => {
        document.addEventListener(
            "mousedown",
            listenerAddBT(chatRef, btnRef, flag, () =>
                setFlag()
            )
        );
        return () => {
            return document.removeEventListener(
                "mousedown",
                listenerAddBT(chatRef, btnRef, flag, () =>
                    setFlag()
                ),
                false
            );
        };
    }, [flag]);

    useMemo(() => {
        if (idsGroup && players.length > 0) {
            let idsGroupArr = idsGroup.split(",");
            idsGroupArr = idsGroupArr.map((e) => e.trim());
            let idsString = players.map(e => e.id);
            idsGroupArr.filter((e) => idsString.indexOf(e.id) >= 0);
            setGroupPlayers(`${idsGroupArr.join(",")},`);
            setGroupPlayersNames(`${players.filter(e => idsGroup.indexOf(e.id) >= 0).map((e) => e.name).join(",")},`);
            setNewGroupName(name);
        }
    }, [idsGroup, name, players]);

    return type === "group" ? (
        <View style={[styles.group_list_c, { right: right }]} nativeID="filter-shadow" ref={chatRef}>
            <View
                style={[
                    styles.triangular,
                    { borderBottomColor: background.chatSearch },
                ]}
            />
            <View

                style={[
                    styles.group_list_m,
                    { backgroundColor: background.chatSearch },
                ]}
            >
                <View
                    style={[
                        styles.name_group_view,
                        { borderBottomColor: color.border },
                    ]}
                >
                    <TextInput
                        style={[
                            styles.inp,
                            {
                                writingDirection: direction,
                                backgroundColor: background.popup1,
                            }, err === "name" && styles.err
                        ]}
                        value={newGroupName}
                        placeholder={t("group-name")}
                        maxLength={23}
                        onChangeText={(txt) => setNewGroupName(txt)}
                    />
                </View>
                <ScrollView
                    style={styles.scroll_group_c}
                    contentContainerStyle={styles.scroll_group_m}
                    nativeID="chat-popup-g"
                    stickyHeaderIndices={[0]}
                >
                    {SearchElements}
                    {players.length > 0 &&
                        players.map((e, i) => {
                            if (sessionStorage.getItem(USER_ID_IN_GAME) === e.id) return;
                            if (fromAndTo.from === e.id) return;
                            return (
                                <Pressable
                                    key={i}
                                    style={[
                                        styles.btn,
                                        { paddingTop: i === 0 ? 10 : 0 },
                                        { writingDirection: direction },
                                    ]}
                                    onPress={() => editGroupList(e.id, e.name)}
                                >
                                    <InputRadio
                                        background={background.chatLogo}
                                        backgroundBorder={background.chatCircle}
                                        borderWidth={widthS * 8}
                                        checkd={groupPlayers.indexOf(e.id) >= 0}
                                        circleWidth={widthS * 5}
                                        err={err === "players" ? true : false}
                                    />
                                    <Text
                                        selectable={false}
                                        style={[styles.chat_list_item_text]}
                                    >
                                        {e.name}
                                    </Text>
                                </Pressable>
                            );
                        })}
                </ScrollView>
                <View
                    style={[
                        styles.all_view,
                        {
                            writingDirection: direction,
                            borderTopColor: color.border,
                        },
                    ]}
                >
                    <View>
                        <Pressable
                            style={[
                                styles.btn,
                                {
                                    paddingBottom: 0,
                                    writingDirection: direction,
                                },
                            ]}
                            onPress={() => editAll()}
                        >
                            <InputRadio
                                background={background.chatLogo}
                                backgroundBorder={background.chatCircle}
                                borderWidth={widthS * 8}
                                checkd={shareAll}
                                circleWidth={widthS * 5}
                            />
                            <Text selectable={false} style={styles.chat_list_item_text}>
                                {t("all_players")}
                            </Text>
                        </Pressable>
                        <Pressable
                            style={[
                                styles.btn,
                                {
                                    paddingBottom: 0,
                                    writingDirection: direction,
                                },
                            ]}
                            onPress={() => editReal()}
                        >
                            <InputRadio
                                background={background.chatLogo}
                                backgroundBorder={background.chatCircle}
                                borderWidth={widthS * 8}
                                checkd={shareReal}
                                circleWidth={widthS * 5}
                            />
                            <Text selectable={false} style={styles.chat_list_item_text}>
                                {t("real_players")}
                            </Text>
                        </Pressable>
                    </View>
                    <Pressable
                        style={[
                            styles.create_btn,
                            { backgroundColor: background.chatLogo },
                        ]}
                        onPress={() => createGroup()}
                    >
                        <Text selectable={false} style={styles.create_btn_text}>
                            {t(idsGroup ? "save" : "create")}
                        </Text>
                    </Pressable>
                </View>
            </View>
        </View>
    ) : (
        <View style={styles.chat_list_c} nativeID="filter-shadow" ref={chatRef}>
            <View
                style={[
                    styles.triangular,
                    { borderBottomColor: background.chatSearch },
                ]}
            />
            <ScrollView
                style={[
                    styles.scroll_chat_list_c,
                    { backgroundColor: background.chatSearch },
                ]}
                contentContainerStyle={styles.scroll_chat_list_m}
                nativeID="chat-popup"
                stickyHeaderIndices={[0]}
            >
                {SearchElements}
                {players.length > 0 &&
                    players.map((e, i) => {
                        if (playerPermission === "1" && e.id === sessionStorage.getItem("creatorUserId")) return
                        if (sessionStorage.getItem(USER_ID_IN_GAME) == e.id) return
                        return (
                            <Pressable
                                key={i}
                                style={({ hovered }) => [styles.chat_list_item_btn]}
                                disabled={data[fromAndTo?.from] && Object.keys(data[fromAndTo?.from]).indexOf(e.id) >= 0}
                                // onPress={() => createChat(e.id)}
                                onPress={() => createChat(e.name, e.id)}
                            >
                                <Text
                                    selectable={false}
                                    style={[
                                        styles.chat_list_item_text,
                                        { opacity: data[fromAndTo?.from] && Object.keys(data[fromAndTo?.from]).indexOf(e.id) >= 0 ? 0.6 : 1 },
                                    ]}
                                >
                                    {e.name}
                                </Text>
                            </Pressable>
                        );
                    })}
            </ScrollView>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS, direction) => {
    return StyleSheet.create({
        group_list_c: {
            position: "absolute",
            top: "2.5vw",
            alignItems: "center"
        },
        group_list_m: {
            zIndex: 1003,
            borderRadius: widthS * 11,
            maxHeight: "16vw",
            paddingTop: heightS * 1,
            marginTop: heightS * 6,
            width: "14vw",
        },
        triangular: {
            position: "absolute",
            top: 0,
            width: 0,
            height: 0,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderRightWidth: widthS * 5,
            borderLeftWidth: widthS * 5,
            borderBottomWidth: widthS * 6,
            zIndex: 1004,
        },
        name_group_view: {
            padding: widthS * 5,
            borderBottomWidth: 1,
        },
        inp: {
            outlineStyle: "none",
            paddingHorizontal: widthS * 5,
            paddingVertical: heightS * 3,
            borderRadius: widthS * 5,
            fontSize: widthS * 8,
            fontFamily: "Catamaran_600SemiBold",
            color: "white",
        },
        btn: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: widthS * 5,
            paddingBottom: heightS * 5,
        },
        all_view: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderTopWidth: 1,
            paddingVertical: heightS * 3,
        },
        chat_list_item_text: {
            fontFamily: "Catamaran_400Regular",
            color: "white",
            fontSize: widthS * 8,
        },
        create_btn: {
            marginHorizontal: widthS * 3,
            paddingHorizontal: widthS * 5,
            paddingVertical: heightS * 3,
            borderRadius: widthS * 5,
        },
        create_btn_text: {
            color: "white",
            fontSize: widthS * 7,
            fontFamily: "Catamaran_600SemiBold",
        },
        chat_list_c: {
            position: "absolute",
            top: "2.5vw",
            alignItems: "center"
        },
        scroll_chat_list_c: {
            zIndex: 1003,
            borderRadius: widthS * 11,
            maxHeight: "15vw",
            paddingBottom: heightS * 1,
            marginTop: heightS * 6,
            width: "12vw",
        },
        scroll_chat_list_m: {
            paddingBottom: heightS * 3,
        },
        chat_list_item_btn: {
            padding: widthS * 3,
            paddingLeft: widthS * 8,
        },
        chat_list_item_text: {
            fontFamily: "Catamaran_400Regular",
            color: "white",
            fontSize: widthS * 8,
        },
        name_group_view: {
            padding: widthS * 5,
            borderBottomWidth: 1,
        },
        inp: {
            outlineStyle: "none",
            paddingHorizontal: widthS * 5,
            paddingVertical: heightS * 3,
            borderRadius: widthS * 5,
            fontSize: widthS * 8,
            fontFamily: "Catamaran_600SemiBold",
            color: "white",
        },
        btn: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: widthS * 5,
            paddingBottom: heightS * 5,
        },
        all_view: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderTopWidth: 1,
            paddingVertical: heightS * 3,
        },
        create_btn: {
            marginHorizontal: widthS * 3,
            paddingHorizontal: widthS * 5,
            paddingVertical: heightS * 3,
            borderRadius: widthS * 5,
        },
        create_btn_text: {
            color: "white",
            fontSize: widthS * 7,
            fontFamily: "Catamaran_600SemiBold",
        },
        err: {
            borderColor: "red",
            borderWidth: "0.1vw"
        },
        search_view: {
            borderRadius: widthS * 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: widthS * 5,
            width: "100%",
            writingDirection: direction,
        },
        search_inp: {
            padding: widthS * 2,
            paddingLeft: widthS * 10,
            color: "white",
            fontSize: widthS * 9,
            width: "100%",
            fontFamily: "Catamaran_300Light",
            writingDirection: direction,
        },
    })
}