import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';
import QuestionnaireItem from './QuestionnaireItem';

export default function QuestionnaireMap({ stopPlayScroll, refShot, isShot }) {

    const conatinerRef = useRef(null);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const questionnaireState = useSelector(state => state.analytics.surveyArr);

    const questionnaireObj = useMemo(() => {
        return questionnaireState.reduce((acc, question) => {
            if (!acc[question.message_id]) {
                acc[question.message_id] = {
                    id: question.message_id,
                    title: question.title,
                    questions: [],
                    // time: question?.send_date,
                };
            }
            acc[question.message_id].questions.push({
                all_answers: question.all_answers,
                question_id: question.question_id,
                question: question.question,
                answers: question.answers?.map(answer => ({
                    answer: answer.answer,
                    players: answer.players
                })).concat(
                    // הוספת אובייקט עבור תשובות שאינן קיימות בתוך המערך של answers
                    question.all_answers.filter(answer => !question.answers.find(a => a.answer === answer))
                        .map(answer => ({
                            answer: answer,
                            players: null
                        }))
                )
            });
            return acc;
        }, {})
    }, [questionnaireState]);


    // useEffect
    //     (() => {
    //         if (conatinerRef.current) {
    //             conatinerRef.current.addEventListener("mousemove", (e) => {
    //                 stopPlayScroll(false);
    //             });
    //             conatinerRef.current.addEventListener("mouseleave", (e) => {
    //                 stopPlayScroll(true);
    //             });
    //         }
    //     }, [conatinerRef.current]);

    // components

    const body = (isShot) => (
        !isShot ? <View nativeID='chat-popup' ref={conatinerRef} style={styles.tableMain}>
            {Object.keys(questionnaireObj).map((questionnaire, index) => {
                return <QuestionnaireItem item={questionnaireObj[questionnaire]} styles={styles} index={index} key={index} />;

            })}
        </View> : <View style={{ gap: "0.5vw" }}>
            {Object.keys(questionnaireObj).map((questionnaire, index) => {
                return <QuestionnaireItem item={questionnaireObj[questionnaire]} styles={styles} index={index} key={index} isShot={isShot} />;

            })}
        </View>
    );

    return (
        <>
            <View nativeID='questionnaire-table' style={styles.container} ref={refShot}
            >
                <View style={styles.headerView}>
                    <Text style={styles.headers}>{t("questionnaire_map").toUpperCase()}</Text>
                </View>
                <View style={styles.main}>
                    {body()}
                </View>
            </View>
            {isShot === "questionnaire" && <View
                ref={(ref) => {
                    if (!ref) {
                        return;
                    }
                    if (!refShot.current) {
                        refShot.current = [];
                    }
                    refShot.current[0] = ref;
                }}
                style={{ flex: 1, position: "absolute", width: "max-content", alignItems: "center", justifyContent: "center", height: "100%", zIndex: -1000, width: "100%", }}
            >
                <View style={{ height: "100%", width: "100%", position: "absolute", zIndex: 1, backgroundColor: background.infoBody, }} />
                <View style={styles.mainShot} id='questionnaire'>
                    {body(true)}
                </View>
            </View>}
        </>

    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxWidth: "100%",
            writingDirection: direction
        },
        headerView: {
            flexDirection: "row",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: "center"
        },
        headers: {
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_300Light",
            fontSize: "1.1vw",
            marginLeft: "0.5vw",
            marginBottom: "0.3vw",

        },
        main: {
            padding: "1vw",
            backgroundColor: background.infoBox,
            borderRadius: "1.5vw",
            // maxHeight: "40vw"
        },
        mainShot: {
            width: "66vw",
            padding: "1vw",
            backgroundColor: background.infoBox,
            borderRadius: "1.5vw",
        },
        questionsContainer: {
            width: "100%",
            borderRadius: "0.7vw",
            backgroundColor: background.infoBody,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: "2.5vw",
            paddingVertical: "1vw",
        },
        questionsContainerLeft: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "2.5vw",
        },
        tableMain: {
            flexDirection: "column",
            alignItems: "center",
            gap: "1vw",
            width: "100%",
        },

    })
} 