import { Image, StyleSheet, Text, View } from 'react-native'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Widget from '../Elements-btn/Widget';
import { useState } from 'react';
import { t } from 'i18n-js';
import { HeatMapFuncContext } from '../Analytics/analyticsFunction/HeatMapFunc';
import { useContext } from 'react';
import { useEffect } from 'react';
import { analyticsEvents, analyticsPlayers } from '../../../../redux/actions/creatorActions';
import { InteractionMapFuncContext } from '../Analytics/analyticsFunction/InteractionMapFunc';

export default function AnalyticsDashboard() {

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const dispatch = useDispatch();

    const colors = [
        "#A5A2A5",
        "#64F4D2",// 1 - 5
        "#00DBAF",// 6 - 10
        "#02B284",// 11 - 15
        "#FCF497",// 16 - 20
        "#FCF000",// 21 - 25
        "#E8C600",// 26 - 30
        "#F25CA0",// 31 - 35
        "#CC0053"
    ];

    const colorsInteraction = [
        { title: t("low"), colors: ["#5CE5CB", "#00A081"] },
        { title: t("medium"), colors: ["#55ABE5", "#006ABC"] },
        { title: t("high"), colors: ["#EFACED", "#D669D3"] },
        { title: t("extreme"), colors: ["#EAA553", "#D98000"] },
    ];
    const {
        arrScoreDashboard,
        filterStatistics,
        refresh
    } = useContext(HeatMapFuncContext);

    const {
        arrScoreReal: arrScoreRealInteraction,
        filterStatistics: filterStatisticsInteraction,
        refresh: refreshInteraction,
    } = useContext(InteractionMapFuncContext);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const players = useSelector((state) => state.players.realPlayers);
    const eventsState = useSelector(state => state.events.events);

    const [currentType, setCurrentType] = useState("1");
    const [heatArr, setHeatArr] = useState([]);
    const [interactionArr, setIntercationArr] = useState([]);

    const printBackground = (number) => {
        switch (true) {
            case (number >= 1 && number <= 5):
                return 1;
            case (number >= 6 && number <= 10):
                return 2;
            case (number >= 11 && number <= 15):
                return 3;
            case (number >= 16 && number <= 20):
                return 4;
            case (number >= 21 && number <= 25):
                return 5;
            case (number >= 26 && number <= 30):
                return 6;
            case (number >= 31 && number <= 35):
                return 7;
            case (number > 35):
                return 8;
            default:
                return 0;
        }
    }

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            let ids = [...gameInfo.players.map((e) => e.id)].join(",");
            dispatch(analyticsPlayers(ids))
        }
    }, [gameInfo?.players]);

    useEffect(() => {
        if (eventsState?.length > 0) {
            let ids = [...eventsState.map((e) => e.id)].join(",");
            dispatch(analyticsEvents(ids))
        }
    }, [eventsState]);

    useEffect(() => {
        let date = new Date();
        date.setDate(new Date().getDate() + 1);
        filterStatistics("3d", "dashboard", false, [{
            time: date.toLocaleTimeString(date.getTimezoneOffset(), { hour: "2-digit", minute: "2-digit" }),
            date: date.toDateString(),
            id: "3257698785482397",
        }]);
    }, [refresh]);

    useEffect(() => {
        filterStatisticsInteraction("3d", 0, "real", 0);
    }, [refreshInteraction]);

    useEffect(() => {
        if (arrScoreDashboard?.length > 0) {
            let arr = [...arrScoreDashboard[0]];
            arr.sort((a, b) => {
                if (a.total < b.total) {
                    return 1;
                } else {
                    return - 1
                }
            });
            setHeatArr([...arr]);
        }
    }, [arrScoreDashboard]);

    useEffect(() => {
        if (arrScoreRealInteraction?.nodes?.length > 0) {
            let arr = arrScoreRealInteraction.nodes.filter((e) => players.find((player) => player.id === e.id));
            arr = arr.sort((a, b) => b.level_num - a.level_num);
            setIntercationArr(arr);
        }
    }, [arrScoreRealInteraction]);

    const component = (type) => {
        switch (type) {
            case "1":
                return (
                    <View style={[styles.viewHeatMap]}>
                        <View style={[styles.heatMapLeft, styles.borderRight]}>
                            <Text style={styles.headerText(true)}>{t("pressure_widget_title")}</Text>
                            {heatArr.map((e, i) => {
                                if (i <= 2) {
                                    let player = gameInfo?.players.find((player) => player.id === e.id);
                                    return (
                                        <View key={i} style={styles.playerView()}>
                                            <View style={styles.playerLeft()}>
                                                <View style={styles.imgView}>
                                                    <Image style={styles.img} source={{ uri: player?.profile_image ? player?.profile_image : images.user_image }} />
                                                </View>
                                                <View>
                                                    <Text style={styles.realName}>{player.real_name?.toUpperCase()}</Text>
                                                    <Text style={styles.gameName}>{player.name_for_game}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )
                                }
                            })}
                        </View>
                        <View style={styles.heatMapRight}>
                            {heatArr.map((e, i) => {
                                if (i <= 2) {
                                    let score = 0;
                                    Object.keys(e.score).forEach((key) => {
                                        score += e.score[key];
                                    });
                                    return (
                                        <View key={i} style={styles.playerView()}>
                                            <View style={styles.scoreView(colors[printBackground(score)])}>
                                                <Text style={styles.scoreText}>{score}</Text>
                                            </View>
                                        </View>
                                    )
                                }
                            })}
                        </View>
                    </View>
                )
            case "2":
                return (
                    <View>
                        <Text style={styles.headerText()}>{t("influential_widget_title")}</Text>
                        {interactionArr.map((e, i) => {
                            if (i <= 2) {
                                let colorItem = colorsInteraction.find((color) => e.level.indexOf(color.title) >= 0);
                                colorItem = colorItem.colors[e.level.indexOf("+") > 0 ? 1 : 0]
                                let player = gameInfo?.players.find((player) => player.id === e.id);
                                return (
                                    <View key={i} style={styles.playerView(true)}>
                                        <View style={styles.playerLeft(true)}>
                                            <View style={styles.imgView}>
                                                <Image style={styles.img} source={{ uri: player?.profile_image ? player?.profile_image : images.user_image }} />
                                            </View>
                                            <View>
                                                <Text style={styles.realName}>{player.real_name?.toUpperCase()}</Text>
                                                <Text style={styles.gameName}>{player.name_for_game}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.influentialView}>
                                            <View style={styles.influential(colorItem, i)} />
                                        </View>
                                    </View>
                                )
                            }
                        })}
                    </View>
                )
        }
    }

    return (
        <View style={styles.container}>
            <Widget
                icon={images.pressure}
                title={t("pressure")}
                currentType={currentType}
                type={"multiple"}
                setCurrentType={setCurrentType}
                icon2={images.influential}
                component={component(currentType)}
                title2={t("most_influential")}
                heatMap={currentType === "1"}
            >
            </Widget>
        </View>
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 2,
            height: "100%",
            writingDirection: direction,
        },
        headerText: (flag) => ({
            color: color.text2,
            fontSize: "0.8vw",
            lineHeight: "1.2vw",
            marginBottom: flag ? "0.6vw" : "0.2vw",
            fontFamily: "Catamaran_400Regular",
        }),
        viewHeatMap: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
        },
        heatMapLeft: {
            flex: 2,
            paddingHorizontal: "1.2vw",
            paddingTop: "0.8vw",
            paddingBottom: "1.2vw",
        },
        heatMapRight: {
            flex: 1,
            alignItems: "center",
            paddingVertical: "1.2vw"
        },
        playerView: (flag) => ({
            flexDirection: "row",
            alignItems: "center",
            justifyContent: flag ? "" : "space-between",
            marginVertical: "0.3vw"
        }),
        playerLeft: (flag) => ({
            flexDirection: "row",
            alignItems: "center",
            flex: flag ? 1 : "",
        }),
        borderRight: {
            borderRightWidth: 1,
            borderRightColor: color.border,
        },
        imgView: {
            width: "1.7vw",
            height: "1.7vw",
            borderRadius: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: background.count,
            marginRight: "0.5vw"
        },
        img: {
            width: "1.6vw",
            height: "1.6vw",
            resizeMode: "cover",
            borderRadius: "100%"
        },
        realName: {
            color: color.text2,
            fontSize: "0.7vw",
            lineHeight: "0.7vw",
            fontFamily: "Catamaran_600SemiBold",
            writingDirection: "ltr",
        },
        gameName: {
            color: color.text2,
            fontSize: "0.7vw",
            lineHeight: "0.7vw",
            fontFamily: "Catamaran_400Regular",
            writingDirection: "ltr",
        },
        scoreView: (color) => ({
            backgroundColor: color,
            width: "3.5vw",
            height: "2vw",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0.6vw"
        }),
        scoreText: {
            fontSize: "1vw",
            fontFamily: "Catamaran_900Black",
        },
        influentialView: {
            flex: 1
        },
        influential: (color, width) => ({
            backgroundColor: color,
            width: `${11 - (width * 3)}vw`,
            height: "1.8vw"
        })
    })
};