import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  TextInput,
  Modal,
  ScrollView,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { t } from "i18n-js";
import { listenerAdd } from "../../../AddListener";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Step1({
  actionLength,
  addDetails,
  newChallenge,
  setFlag,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const events = useSelector((state) => state.events.events);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);

  const popupRef = useRef(null);

  const [event, setEvent] = useState(t("relate-event"));
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [showListEvent, setShowListEvent] = useState(false);
  const [err, setErr] = useState("");

  const convertToArray = (eventsString) => {
    let eventsArray = [];
    if (eventsString && eventsString.length > 0) {
      if (eventsString.indexOf(",") < 0) {
        eventsArray.push(eventsString);
      } else {
        while (eventsString.length > 0) {
          let comma =
            eventsString.indexOf(",") > 0 ? eventsString.indexOf(",") : 0;
          let eventStr = eventsString.slice(0, comma);
          eventsString = eventsString.slice(comma > 0 ? comma + 2 : comma);
          eventsArray.push(eventStr.length > 0 ? eventStr : eventsString);
          if (eventStr.length === 0) {
            break;
          }
        }
      }
    } else {
      eventsArray.push(t("no-event"));
    }
    return eventsArray;
  };

  const addDetailesStep = () => {
    if (!name || name.length < 2) {
      setErr("name");
      return;
    }
    if (!description || (description && description.length === 0)) {
      setErr("des");
      return;
    }
    if (event === t("relate-event")) {
      setErr("event");
      return;
    }
    setErr("");
    addDetails(event, description, name);
  };

  useEffect(() => {
    if (newChallenge?.action && Object.keys(newChallenge?.action).length > 0) {
      setEvent(events.find((e) => e.id === newChallenge?.action?.event_id));
      setDescription(newChallenge?.action?.description);
    }
  }, [newChallenge?.action]);

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAdd(popupRef, showListEvent, () => setShowListEvent(false))
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        listenerAdd(popupRef, showListEvent, () => setShowListEvent(false))
      );
    };
  }, [showListEvent]);
  return (
    <View style={styles.container}>
      <Text style={styles.text_header}>{t("new-action_q1")}</Text>
      <View
        style={[
          styles.body,
          {
            borderTopColor: color.border,
            borderTopWidth: 2,
            paddingHorizontal: widthS * 3,
            flex: 1,
          },
        ]}
      >
        <View style={styles.main}>
          <View style={styles.main_child}>
            <TextInput
              placeholder={t("challenge_name")}
              style={[
                styles.input,
                {
                  backgroundColor: background.actionInput,
                  marginBottom: "0.5vw"
                },
                err?.indexOf("name") >= 0 ? styles.mail_error : "",
              ]}
              textContentType="username"
              value={name}
              onChangeText={(text) => {
                setName(text);
              }}
            />
            <TextInput
              style={[
                styles.input,
                {
                  backgroundColor: background.actionInput,
                },
                err?.indexOf("des") >= 0 ? styles.mail_error : "",
              ]}
              textContentType="username"
              multiline={true}
              numberOfLines={4}
              value={description}
              nativeID="action"
              onChangeText={(text) => {
                setDescription(text);
              }}
            />
            <View style={styles.select_view}>
              <Pressable
                onPress={() => setShowListEvent(true)}
                style={[
                  styles.select,
                  {
                    backgroundColor: background.actionSelect,
                  },
                  err?.indexOf("event") >= 0 ? styles.mail_error : "",
                ]}
              >
                <Text style={styles.select_text}>{event.title || event}</Text>
                <Pressable
                  style={
                    lang === "he" ? styles.caretdown_rtl : styles.caretdown_ltr
                  }
                >
                  <AntDesign
                    onPress={() => setShowListEvent(true)}
                    name="caretdown"
                    color="white"
                    size={widthS * 10}
                  />
                </Pressable>
                {showListEvent && (
                  <View
                    ref={popupRef}
                    style={[
                      styles.select_active,
                      { backgroundColor: background.actionSelect },
                    ]}
                  >
                    <ScrollView showsVerticalScrollIndicator={false}>
                      {(events.length > 1 ? events.filter(event => event.title) : [t("no-event")]).map((e, i) => {
                        return (
                          <Pressable
                            style={({ pressed, hovered }) => [
                              { backgroundColor: hovered ? "gray" : "" },
                              styles.list_item,
                            ]}
                            key={i}
                            onPress={() => {
                              setEvent(e);
                              setShowListEvent(false);
                            }}
                          >
                            {({ pressed, hovered }) => (
                              <Text style={styles.list_text}>{e.title || e}</Text>
                            )}
                          </Pressable>
                        );
                      })}
                    </ScrollView>
                  </View>
                )}
              </Pressable>
            </View>
          </View>
        </View>
        <View
          style={[
            styles.footer,
            {
              borderTopColor: color.border,
              justifyContent: lang !== "he" ? "flex-start" : "flex-end",
            },
          ]}
        >
          <Pressable
            style={[styles.btn, { borderWidth: 0 }]}
            onPress={() => setFlag()}
          >
            <Text style={styles.btn_text}>{t("cancel")}</Text>
          </Pressable>
          <Pressable
            style={[
              styles.btn,
              {
                backgroundColor: background.actionButtonAdd,
                borderColor: background.actionButtonAdd,
              },
            ]}
            onPress={() => addDetailesStep()}
          >
            <Text style={styles.btn_text}>{t("next")}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      // minHeight: height > 600 ? "126%" : "111%",
      flex: 1,
      display: "flex",
    },
    text_header: {
      color: "white",
      fontSize: widthS * 13,
      fontFamily: "Catamaran_300Light",
      marginBottom: heightS * 15,
      paddingRight: widthS * 20,
      paddingLeft: widthS * 3,
    },
    main: {
      flex: 1,
      // marginTop: heightS * ,
      paddingTop: heightS * 15,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 10,
    },
    main_child: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    num: {
      color: "white",
      fontSize: widthS * 11,
      fontFamily: "Catamaran_300Light",
    },
    input: {
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: widthS * 5,
      color: "white",
      fontSize: widthS * 10,
      paddingVertical: heightS * 3,
      paddingHorizontal: widthS * 5,
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction,
      direction: direction
    },
    select_view: {
      alignSelf: "flex-start",
      position: "relative",
    },
    select: {
      borderRadius: widthS * 5,
      marginTop: heightS * 10,
      width: widthS * 170,
      height: heightS * 18,
      ...(direction === "rtl" ? { paddingRight: width * 3 } : { paddingLeft: widthS * 3, }),
      display: "flex",
      justifyContent: "center",
    },
    select_active: {
      top: 0,
      position: "absolute",
      borderRadius: widthS * 5,
      marginLeft: widthS * -3,
      width: widthS * 170,
      maxHeight: heightS * 120,
      display: "flex",
      justifyContent: "center",
    },
    select_text: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_300Light",
      writingDirection: direction,
    },
    caretdown_ltr: {
      position: "absolute",
      right: widthS * 7,
    },
    caretdown_rtl: {
      position: "absolute",
      left: widthS * 7,
    },
    list_item: {
      height: heightS * 18,
      padding: widthS * 3,
      borderRadius: widthS * 5,
      justifyContent: "center",
    },
    list_text: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_300Light",
    },
    footer: {
      borderTopWidth: 2,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      // paddingTop: heightS * 20,
      height: heightS * 50,
      // flex: 10,
      writingDirection: direction,
    },
    btn: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: "10px",
    },
  });
