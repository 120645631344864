import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  URL_PATH,
  GET_MAP_STATISTICS,
  GET_INTERACTION_MAP_STATISTICS,
  GET_SURVEY_ANALYTICS
} from "@env";
import {
  F_PHPSESSID,
  USER_ID_IN_GAME,
  _CHAT_MESSAGE_,
  _CREATOR_ID_,
  _CURRENT_USER_ID_,
  _F_PHPSESSID_,
  _GROUPS_COUNT_,
  _GROUP_ID_,
  _GROUP_MEMBERS_,
  _GROUP_NAME_,
  _ID_,
  _LAST_ID_,
  _SENDER_ID_,
  _STATUS_,
  _USER_ID_,
} from "../config.inc";
import { setSurveyAnalytics } from "../redux/actions/creatorActions";

export const AnalyticsContext = createContext();

export default function AnalyticsProvider(props) {

  const dispatch = useDispatch();
  const analyticsFlag = useSelector(state => state.analytics.flags.flag);
  const dashboardFlag = useSelector(state => state.build.dashboardFlag);
  const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

  const [realMapItems, setRealMapItems] = useState([]);
  const [mvpItems, setMvpItems] = useState([]);
  const [repMapItems, setRepMapItems] = useState([]);

  const getInteractionMap = (type) => {
    const url = `${URL_PATH}${GET_MAP_STATISTICS}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    body.append("mode", type);
    try {
      fetch(url, {
        method: "POST",
        body: body,
      })
        .then((res) => res.text())
        .then((res) => {
          try {
            res = JSON.parse(res);
          } catch (es) {
            console.log(es)
            return;
          }
          if (type === "map" && (res.map_stats !== realMapItems || res.mvp_stats !== mvpItems)) {
            setRealMapItems([...res.map_stats]);

            getRealInteractionMap()
          } else if (repMapItems !== res.map_stats) {
            setRepMapItems([...res.map_stats]);
          }
        })
    } catch (e) {
      console.log(e)
    }
  }
  const getRealInteractionMap = () => {
    const url = `${URL_PATH}${GET_INTERACTION_MAP_STATISTICS}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    try {
      fetch(url, {
        method: "POST",
        body: body,
      })
        .then((res) => res.text())
        .then((res) => {
          try {
            res = JSON.parse(res);
          } catch (es) {
            console.log(es)
            return;
          }

          setMvpItems([...res.mvp_stats]);
        })
    } catch (e) {
      console.log(e)
    }
  }

  ///
  const questionArrayEample = [
    {
      type: "single",
      question: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna?",
      answers: [
        { text: "decision", count: 3 },
        { text: "Call the police", count: 7 },
        { text: "mail", count: 5 }
      ]
    },
    {
      type: "single",
      question: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna?",
      answers: [
        { text: "decision", count: 9 },
        { text: "Call the police", count: 7 },
        { text: "mail", count: 2 }
      ]
    },
    {
      type: "single",
      question: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna?",
      answers: [
        { text: "decision", count: 9 },
        { text: "Call the police", count: 7 },
        { text: "mail", count: 2 }
      ]
    },
    {
      type: "multipiule",
      question: "what are you a?",
      answers: [
        { text: "nir", count: 7 },
        { text: "Camir", count: 4 },
        { text: "mail", count: 10 },
        { text: "nir", count: 4 },
        { text: "Camir", count: 4 },
        { text: "mail", count: 8 },
        { text: "nir", count: 4 },
        { text: "Camir", count: 3 },
        { text: "mail", count: 9 }
      ]
    }
  ]
  ///

  const getSurveyAnalytics = () => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${GET_SURVEY_ANALYTICS}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      try {
        fetch(url, {
          method: "POST",
          body: body,
        })
          .then(response => response.text())
          .then(result => {
            if (result.length !== 0) {
              result = JSON.parse(result);
              if (result?.questionnaires) {
                resolve(true);
                dispatch(setSurveyAnalytics(result?.questionnaires));
              } else {
                reject(false);
              }
            }
          })
          .catch(error => {
            reject(false);
          });
      } catch (e) {
        dispatch(setSurveyAnalytics(questionArrayEample));
        resolve(questionArrayEample);
      }
    });
  }

  useEffect(() => {
    if (props.typeRefresh?.length > 0 && (analyticsFlag || dashboardFlag)) {
      getInteractionMap("map");
      getInteractionMap("wanted_map");
      getSurveyAnalytics();
    }
  }, [props.typeRefresh, analyticsFlag, dashboardFlag]);

  useEffect(() => {
    if (gameInfo?.game_name?.length > 0 && (analyticsFlag || dashboardFlag)) {
      getInteractionMap("map");
      getInteractionMap("wanted_map");
      getSurveyAnalytics();
    }
  }, [gameInfo, analyticsFlag, dashboardFlag]);

  return (
    <AnalyticsContext.Provider
      value={{
        realMapItems,
        repMapItems,
        mvpItems,
        getSurveyAnalytics
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
}