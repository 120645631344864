import React, { useState, useEffect, useMemo } from "react";
import { StyleSheet, View, ScrollView, TextInput, Pressable, Image, Text } from "react-native";
import { useSelector } from "react-redux";
import "../mailbox/scroll.css";
import { t } from "i18n-js";
import { EvilIcons } from "@expo/vector-icons";
import ShortenWord from "../../modules/ShortenWord";

export default function ChatCreatorList({
  data,
  active,
  setActive,
  chatDate,
  dashboard
}) {

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, color, direction);

  const [dataArr, setDataArr] = useState([]);

  const images = useSelector((state) => state.style.images);

  const search = (txt) => {
    const enteredText = txt.toLowerCase();
    const temp = [...data].filter(
      (e) => e.name_for_game.toLowerCase().indexOf(enteredText) > -1
    );
    setDataArr(temp);
  };

  useEffect(() => {
    setDataArr([...data]);
  }, [data.length]);
  useEffect(() => {
    setDataArr([...data]);
  }, []);

  const searchComponent = (
    <View
      style={styles.main(dashboard)}
    >
      <View
        style={styles.search_view(dashboard)}
      >
        <TextInput
          selectTextOnFocus={false}
          placeholderTextColor={color.chatSearch}
          style={[styles.search_inp(dashboard)]}
          placeholder={dashboard ? t("select_from_player") : `${t("search")}...`}
          onChangeText={(txt) => search(txt)}
        />
        <EvilIcons
          selectable={false}
          style={styles.search_icon}
          name="search"
          size={"1.2vw"}
          color={color.chatSearch}
        />
      </View>
    </View>
  )

  return (
    <View
      style={[
        styles.container(dashboard),
      ]}
    >
      {dashboard && searchComponent}
      <ScrollView
        contentContainerStyle={[styles.scroll_body,]}
        nativeID={dashboard ? "chat-popup-g" : "mail-messages"}
        stickyHeaderIndices={[0]}
      >
        {!dashboard && searchComponent}
        {dataArr.length > 0 &&
          dataArr
            .map((e, i) => {
              return (
                <Pressable
                  key={i}
                  style={styles.itemContainer(e.id === active, dashboard)}
                  onPress={() => {
                    setActive(e.id);
                  }}
                >
                  <View
                    style={[
                      styles.containerChild(dashboard),
                    ]}
                  >
                    <View style={styles.left}>
                      <View style={styles.imgView(dashboard)}>
                        <Image
                          source={e && e.profile_image ? e.profile_image : images.user_image}
                          style={styles.logo(dashboard)}
                        />
                      </View>
                      <View style={styles.view}>
                        <View style={styles.top}>
                          <Text numberOfLines={1} selectable={false} style={styles.name(e.is_represented === "1", dashboard)}>
                            {ShortenWord(e.name_for_game, 15)}
                          </Text>
                          {chatDate && chatDate[e.id] && chatDate[e.id]["count"] > 0 && <View
                            style={[
                              styles.count_view(dashboard),
                              { backgroundColor: background.chatCount },
                            ]}
                          >
                            <Text selectable={false} style={styles.count_text(dashboard)}>
                              {chatDate[e.id]["count"]}
                            </Text>
                          </View>}
                        </View>
                      </View>
                    </View>
                  </View>
                </Pressable>
              );
            })}
      </ScrollView>
    </View>
  );
}
const stylesR = (background, color, direction) =>
  StyleSheet.create({
    container: (flag) => ({
      flex: 1,
      borderTopWidth: flag ? 0 : 1,
      borderTopColor: color.border,
      writingDirection: flag ? "ltr" : "rtl"
    }),
    scroll_body: {
      minHeight: "101%",
      writingDirection: "ltr"
    },
    main: (flag) => ({
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: "1vw",
      backgroundColor: background.popup1,
      borderBottomWidth: 1,
      borderColor: color.border,
      borderRightWidth: flag ? 1 : 0,
      padding: "0.8vw"
    }),
    search_view: (flag) => ({
      borderRadius: flag ? "5vw" : "0.5vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "0.5vw",
      width: "100%",
      height: "2vw",
      writingDirection: direction,
      backgroundColor: background.chatSearch
    }),
    search_inp: (flag) => ({
      padding: "1vw",
      paddingLeft: "1vw",
      color: "white",
      fontSize: "0.9vw",
      outlineStyle: "none",
      width: "100%",
      fontFamily: "Catamaran_300Light",
      writingDirection: direction,
    }),
    itemContainer: (flag, flag2) => ({
      height: flag2 ? "3vw" : "4.6vw",
      writingDirection: "ltr",
      backgroundColor: flag ? background.chatMsgUserActive : "",
    }),
    containerChild: (flag) => ({
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      marginLeft: "1vw",
      paddingRight: "0.6vw",
      borderBottomWidth: flag ? 0 : 1,
      paddingVertical: "0.4vw",
      height: flag ? "3vw" : "4.6vw",
      borderBottomColor: background.chatMsgUserActive
    }),
    left: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    imgView: (flag) => ({
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      padding: "0.1vw",
      shadowColor: color.logoColor,
      shadowOffset: flag ? {} : { height: 0, width: 0 },
      shadowOpacity: 0.7,
      shadowRadius: 10,
      marginRight: "1vw",
      backgroundColor: flag ? background.chatCreatorBorder : color.logoColor
    }),
    logo: (flag) => ({
      width: flag ? "1.8vw" : "2.5vw",
      height: flag ? "1.8vw" : "2.5vw",
      resizeMode: "cover",
      borderRadius: "2vw",
    }),
    view: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
    },
    top: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "1.7vw",
    },
    name: (flag, flag1) => ({
      fontSize: flag1 ? "0.8vw" : "0.9vw",
      lineHeight: "1.5vw",
      maxWidth: "100%",
      color: flag ? "#a7a7a7" : "white",
      fontFamily: flag ? "Catamaran_300Light" : "Catamaran_600SemiBold",
    }),
    count_view: (flag) => ({
      width: flag ? "0.8vw" : "1.2vw",
      height: flag ? "0.8vw" : "1.2vw",
      borderRadius: "100%",
      alignItems: "center",
      justifyContent: "center",
    }),
    count_text: (flag) => ({
      color: "black",
      fontSize: flag ? "0.6vw" : "1vw",
      fontFamily: "Catamaran_700Bold",
      padding: 0,
    }),
  });
