import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View, Text, Pressable, PixelRatio, TextInput, Modal, TouchableOpacity, Image } from "react-native";
import "./scroll.css";
import { useDispatch, useSelector } from "react-redux";
import MailBox_message from "./MailBox_message";
import FullMail from "./FullMail";

import { MailContext } from "../../../ContextData/Mail";
import { SystemContext } from "../../../ContextData/System";
import { t } from "i18n-js";
import { minimizeDashbord, setErrorMessage, setNewMessageFlag, setNewSystemFlag } from "../../../redux/actions";
import NewMailPopup from "./NewMailPopup";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { EvilIcons, Ionicons } from "@expo/vector-icons";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { GenerateId } from "../../modules/GenerateId";

export default function MessagesBody({
  messages,
  sentMessages,
  drafts,
  type,
  messageBodyRef,
  arrangeInbox,
  arrangeSent,
  arrangeDrafts,
  dashboard,
  minimizeMail,
  closeAndSave
}) {

  const images = useSelector((state) => state.style.images);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(heightS, widthS, background, color, direction);

  const scrollMessages = useRef(null);
  const dispatch = useDispatch();

  const { checkEnd } = onClickEndGameCheck();

  // const showNewMessageFlag = useSelector((state) => state.mailArr.newMessageFlag);
  // const showNewSystemFlag = useSelector((state) => state.systemMessages.newMessageFlag);
  const minimizeNewMailState = useSelector(state => state.minimize.minimizeNewMail);
  const minimizeNewSystemState = useSelector(state => state.minimize.minimizeNewSystem);
  const playersState = useSelector(state => state.gameInfoArr.gameInfoArr?.players);
  const events = useSelector(state => state.events.events);
  // const { markMailAsReadMail } = useContext(MailContext);
  // const { markSystemAsRead } = useContext(SystemContext);

  const [indexMessage, setIndexMessage] = useState("");
  const [inboxSentDrafts, setInboxSentDrafts] = useState("Inbox");
  const [messagesArr, setMessagesArr] = useState([]);
  const [showSearchPopup, setShowShearchPopup] = useState({ flag: false });


  const [messageType, setMessageType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showFullMessage, setShowFullMessage] = useState(false);
  /// dashboard state
  const [activePlayer, setActivePlayer] = useState("");
  const [activeMessageDashboard, setActiveMessageDashboard] = useState(null);
  const [showNewMailDashboard, setShowNewMailDashboard] = useState(false);
  const [messagesArrDashboard, setMessagesArrDashboard] = useState([]);
  const [searchDashboard, setSearchDasboard] = useState(null);
  ////////

  const searchMain = (txt, type) => {
    setSearchTerm(txt);
    let arr = [];
    if (type === "Drafts") {
      arr = drafts?.length > 0 ? arr.concat(drafts) : arr;
    } else if (type === "Sent") {
      arr = sentMessages?.length > 0 ? arr.concat(sentMessages) : arr;
    } else {
      arr = messages?.length > 0 ? arr.concat(messages) : arr;
    }
    // setIndexMessage("");
    return search(arr, txt);
  };

  const search = (arr, txt) => {
    let playersSearch = [];
    let toListSearch = [];
    let subjectSearch = [];
    if (arr.length > 0 && txt?.length > 0) {
      arr.forEach((e) => {
        if (playersState?.find((player) => player?.id === e.sender?.id) && e.sender?.name?.toLowerCase().indexOf(txt.toLowerCase()) >= 0 && !playersSearch.find((player) => player.id === e.sender.id)) {
          playersSearch.push(playersState.find((item) => item.id === e.sender.id));
        }
        if (e?.to_list_names?.toLowerCase().indexOf(txt?.toLowerCase()) >= 0) {
          toListSearch.push(e);
        }
        if (e?.subject.toLowerCase().indexOf(txt.toLowerCase()) >= 0) {
          subjectSearch.push(e);
        }
      });
    }
    return {
      players: playersSearch,
      to: toListSearch,
      subject: subjectSearch,
      arr: arr,
      text: txt
    };
  };

  const setSearchResult = (arr, type, playerId) => {
    switch (type) {
      case "player":
        return orderMessages(false, arr.filter((e) => e.sender.id === playerId), true);
      case "to":
        return orderMessages(false, arr, true);
      case "subject":
        return orderMessages(false, arr, true);
    }
  }

  const mapTypeMessages = (type, arr) => {
    if (!type) {
      type = inboxSentDrafts;
    }
    switch (type) {
      case "Inbox":
        if (arr && arr?.length > 0) {
          orderMessages(false, arr);
        } else if (messages && messages.length > 0) {
          orderMessages(false, messages);
        } else {
          setMessagesArr([]);
        }
        return;
      case "Sent":
        if (arr && arr?.length > 0) {
          orderMessages(false, arr);
        } else if (sentMessages && sentMessages.length > 0) {
          orderMessages(false, sentMessages);
        } else {
          setMessagesArr([]);
        }
        return;
      case "Drafts":
        if (arr && arr?.length > 0) {
          orderMessages(false, arr);
        } else if (drafts && drafts.length > 0) {
          orderMessages(false, drafts);
        } else {
          setMessagesArr([]);
        }
        return;
    }
  };
  const orderMessages = (flag, arr, flag1) => {
    let order_by = "";
    if (inboxSentDrafts === "Inbox") {
      order_by = arrangeInbox.value;
    } else if (inboxSentDrafts === "Drafts") {
      order_by = arrangeDrafts.value;
    } else if (inboxSentDrafts === "Sent") {
      order_by = arrangeSent.value;
    }
    let temp = arr?.length > 0 ? [...arr] : [...messagesArr];
    if (flag === "drafts") {
      if (drafts && drafts.length > 0) {
        temp = [...drafts];
      }
      else temp = []
    }
    switch (order_by) {
      case "From":
        temp.sort((a, b) => {
          if (a.sender.name > b.sender.name) {
            return 1;
          } else if (a.sender.name < b.sender.name) {
            return -1;
          }
          return 0;
        });
        break;
      case "To":
        temp.sort((a, b) => {
          if (a.to_list > b.to_list) {
            return 1;
          } else if (a.to_list < b.to_list) {
            return -1;
          }
          return 0;
        });
        break;
      case "Subject":
        temp.sort((a, b) => {
          if (a.subject > b.subject) {
            return 1;
          } else if (a.subject < b.subject) {
            return -1;
          }
          return 0;
        });
        break;
      case "Event":
        if (events?.length > 0) {
          temp.sort((a, b) => {
            let aName = events?.find((e) => e.id === a?.event?.id)?.name;
            let bName = events?.find((e) => e.id === b?.event?.id)?.name;
            if (aName > bName) {
              return 1;
            } else if (aName < bName) {
              return -1;
            }
            return 0;
          });
        }
        break;
      case "Body":
        temp.sort((a, b) => {
          if (a.body_search > b.body_search) {
            return 1;
          } else if (a.body_search < b.body_search) {
            return -1;
          }
          return 0;
        });
        break;
      case "Status":
        temp.sort((a, b) => {
          if (a.read_or_not && !b.read_or_not) {
            return 1;
          } else if (!a.read_or_not && b.read_or_not) {
            return -1;
          }
          return 0;
        });
        break;
      case "Flag":
        temp.sort((a, b) => {
          if (a.flag < b.flag) {
            return 1;
          } else if (a.flag > b.flag) {
            return -1;
          }
          return 0;
        });
        break;
      case "Attachments":
        temp.sort((a, b) => {
          if (a.has_attachments > b.has_attachments) {
            return -1;
          } else if (a.has_attachments < b.has_attachments) {
            return 1;
          }
          return 0;
        });
        break;
      case "Retime":
        temp.sort((a, b) => {
          if (new Date(a.time).getTime() > new Date(b.time).getTime()) {
            return 1;
          } else if (new Date(a.time).getTime() < new Date(b.time).getTime()) {
            return -1;
          }
          return 0;
        });
        break;
      case "Date":
        temp.sort((a, b) => {
          if (new Date(a.time).getTime() < new Date(b.time).getTime()) {
            return 1;
          } else if (new Date(a.time).getTime() > new Date(b.time).getTime()) {
            return -1;
          }
          return 0;
        });
        break;
    }
    if (flag === "index") setIndexMessage("");
    setMessagesArr(temp);
    if ((indexMessage?.length === 0 || flag1) && temp?.length > 0) {
      setIndexMessage(temp[0]?.id);
    }
  };

  const printOptions = (option) => {
    if (option && option.length > 10) {
      return `${option.slice(0, 10)}...`;
    } else if (option.indexOf(" ") === 10) {
      return `${option.slice(0, 9)}...`;
    }
    return option;
  };

  useEffect(() => {
    if (showSearchPopup?.type && searchTerm?.length > 0) {
      switch (showSearchPopup?.type) {
        case "player":
          return setSearchResult(messagesArr, showSearchPopup.type, showSearchPopup?.id);
        case "to":
          return setSearchResult(showSearchPopup.to, showSearchPopup.type, showSearchPopup?.id);
        case "subject":
          return setSearchResult(showSearchPopup.subject, showSearchPopup.type, showSearchPopup?.id);
      }
    }
  }, [showSearchPopup?.type, messages, sentMessages, drafts]);

  useEffect(() => {
    if (searchTerm.length === "0") {
      setMessagesArr([...messages]);
      if (indexMessage?.length === 0) {
        setIndexMessage(messages[0]?.id);
      }
    }
  }, [messages]);

  const searchDashboardFunc = (temp, searchDashboard) => {
    return temp.filter((e) =>
      e?.subject?.toLowerCase()?.indexOf(searchDashboard?.toLowerCase()) >= 0 ||
      e?.body_search?.toLowerCase()?.indexOf(searchDashboard?.toLowerCase()) >= 0 ||
      e?.sender?.name?.toLowerCase()?.indexOf(searchDashboard?.toLowerCase()) >= 0
    )
  }

  useEffect(() => {
    // if (searchTerm) return;
    if (dashboard) {
      let temp = searchDashboard ? [...searchDashboardFunc(messages, searchDashboard)] : [...messages];
      temp.sort((a, b) => {
        if (new Date(a.time).getTime() < new Date(b.time).getTime()) {
          return 1;
        } else if (new Date(a.time).getTime() > new Date(b.time).getTime()) {
          return -1;
        }
        return 0;
      });
      setMessagesArrDashboard(temp)
    } else {
      if (searchTerm?.length > 0) {
        mapTypeMessages(false, messagesArr);
      } else {
        mapTypeMessages();
      }
    }
  }, [drafts, messages, sentMessages,
    searchTerm,
    dashboard, activePlayer, searchDashboard]);
  useEffect(() => {
    if (scrollMessages.current) {
      scrollMessages.current.scrollTo(0, 0);
    }
    orderMessages("Inbox");
  }, [arrangeInbox, arrangeSent, arrangeDrafts, inboxSentDrafts]);

  useEffect(() => {
    mapTypeMessages(inboxSentDrafts);
  }, []);

  useEffect(() => {
    setSearchTerm("");
  }, [inboxSentDrafts]);

  const mailNavComponent = (
    <View
      style={[
        styles.header_left,
        {
          backgroundColor: background.mailBodyHeader,
          borderBottomColor: color.border,
        },
      ]}
    >
      <Pressable
        onPress={() => {
          setInboxSentDrafts(lang === "he" ? "Drafts" : "Inbox");
          mapTypeMessages(lang === "he" ? "Drafts" : "Inbox");
          setIndexMessage("");
          setMessageType("");
          setShowFullMessage(false);
          setShowShearchPopup({ flag: false });
        }}
      >
        <Text
          selectable={false}
          style={[
            styles.nav_text,
            {
              color:
                (lang !== "he" && inboxSentDrafts === "Inbox") ||
                  (lang === "he" && inboxSentDrafts === "Drafts")
                  ? color.mailText
                  : color.mailNav,
              fontWeight:
                (lang !== "he" && inboxSentDrafts === "Inbox") ||
                  (lang === "he" && inboxSentDrafts === "Drafts")
                  ? "500"
                  : "400",
            },
          ]}
        >
          {lang === "he"
            ? printOptions(t("drafts"))
            : printOptions(t("inbox"))}
          {/* {printOptions(t("inbox"))} */}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          mapTypeMessages("Sent");
          setInboxSentDrafts("Sent");
          setMessageType("");
          setIndexMessage("");
          setShowFullMessage(false);
          setShowShearchPopup({ flag: false });
        }}
      >
        <Text
          selectable={false}
          style={[
            styles.nav_text,
            {
              color:
                inboxSentDrafts === "Sent"
                  ? color.mailText
                  : color.mailNav,
              fontWeight: inboxSentDrafts === "Sent" ? "500" : "400",
            },
          ]}
        >
          {printOptions(t("sent"))}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          setInboxSentDrafts(lang === "he" ? "Inbox" : "Drafts");
          mapTypeMessages(lang === "he" ? "Inbox" : "Drafts");
          setMessageType("");
          setIndexMessage("");
          setShowFullMessage(false);
          setShowShearchPopup({ flag: false });
        }}
      >
        <Text
          selectable={false}
          style={[
            styles.nav_text,
            {
              color:
                (lang !== "he" && inboxSentDrafts === "Drafts") ||
                  (lang === "he" && inboxSentDrafts === "Inbox")
                  ? color.mailText
                  : color.mailNav,
              fontWeight:
                (lang !== "he" && inboxSentDrafts === "Drafts") ||
                  (lang === "he" && inboxSentDrafts === "Inbox")
                  ? "500"
                  : "400",
            },
          ]}
        >
          {lang === "he"
            ? printOptions(t("inbox"))
            : printOptions(t("drafts"))}
        </Text>
      </Pressable>
    </View>
  );

  const searchComponent = (
    <View
      style={styles.main(dashboard)}
    >
      <View
        style={styles.search_view(dashboard)}
      >
        <TextInput
          selectTextOnFocus={false}
          placeholderTextColor={color.chatSearch}
          style={[styles.search_inp(dashboard)]}
          placeholder={`${t("search")}...`}
          onChangeText={(txt) => setSearchDasboard(txt)}
        />
        <EvilIcons
          selectable={false}
          style={styles.search_icon}
          name="search"
          size={"1.2vw"}
          color={color.chatSearch}
        />
      </View>
      <TouchableOpacity style={styles.btnNew} onPress={() => {
        checkEnd(() => {
          // setShowNewMailDashboard(true);
          if (minimizeMail?.length == 2) {
            dispatch(setErrorMessage(true, false, t("dashboard_mail_error")));
          } else {
            dispatch(minimizeDashbord({ show: true, id: GenerateId(), message: {} }));
          }
        })
      }}>
        <Image source={{ uri: images.plusIcon }} style={styles.plusIcon} />
      </TouchableOpacity>
    </View>
  );

  const popupDashboard = (flag, component) => (
    <Modal visible={flag} animationType="fade" transparent={true}>
      <View style={styles.containerPopup}>
        {component}
      </View>
    </Modal>
  )

  const fullMailDashboardComponent = (
    <FullMail
      message={activeMessageDashboard}
      setActiveMessageDashboard={setActiveMessageDashboard}
      setShowNewMailDashboard={setShowNewMailDashboard}
      dashboard={dashboard}
      minimizeMail={minimizeMail}
      type={"mail"}
      inboxSentDrafts={"Inbox"}
    />
  );

  const newMailDashboardComponnent = (
    <NewMailPopup
      dashboard={true}
      type={"mail"}
      setFlag={() => setShowNewMailDashboard(false)}
    />
  )


  return (
    <View style={styles.container} ref={messageBodyRef}>
      <View style={styles.body}>
        {/* dashboard */}
        {popupDashboard(activeMessageDashboard, fullMailDashboardComponent)}
        {/* {popupDashboard(showNewMailDashboard, newMailDashboardComponnent)} */}
        {/* \\\/// */}
        <View style={styles.mail_messages_side}>
          {!dashboard && mailNavComponent}
          {dashboard && searchComponent}
          <MailBox_message
            dashboard={dashboard}
            scrollMessages={scrollMessages}
            setShowFullMessage={setShowFullMessage}
            messagesArr={dashboard ? messagesArrDashboard : messagesArr}
            inboxSentDrafts={inboxSentDrafts}
            index={indexMessage}
            setIndex={(id) => setIndexMessage(id)}
            orderMessages={orderMessages}
            type={type}
            setActiveMessageDashboard={setActiveMessageDashboard}
            arrangeInboxName={arrangeInbox.name}
            arrangeSentName={arrangeSent.name}
            arrangeDraftsName={arrangeDrafts.name}
          />
        </View>
        {!dashboard && <View style={styles.full_message_side}>
          {/* {inboxSentDrafts === "Drafts" && drafts?.length > 0 ? (
            <NewMailPopup
              // setFlag={() =>
              //   dispatch(
              //     type === "mail"
              //       ? setNewMessageFlag(true)
              //       : setNewSystemFlag(true)
              //   )
              // }
              searchMain={(txt) => searchMain(txt)}
              setShowNewMessage={() =>
                dispatch(
                  type === "mail"
                    ? setNewMessageFlag(true)
                    : setNewSystemFlag(true)
                )
              }
              type={type}
              messageType={"drafts"}
              message={messagesArr?.find((e) => e.id === indexMessage)}
              index={indexMessage}
              setIndex={setIndexMessage}
              messages={messagesArr}
            />
          ) : ( */}
          <FullMail
            searchMain={(txt) => searchMain(txt, inboxSentDrafts)}
            setType={(type) => setMessageType(type)}
            message={messagesArr?.find((e) => e.id === indexMessage)}
            type={type}
            showFullMessage={showFullMessage}
            inboxSentDrafts={inboxSentDrafts}
            setSearchResult={setSearchResult}
            setMessagesArr={(arr) => orderMessages(false, arr)}
            setIndexMessage={setIndexMessage}
            showSearchPopup={showSearchPopup}
            setShowShearchPopup={setShowShearchPopup}
          />
          {/* )} */}
        </View>}
      </View>
      {/* {showNewMessageFlag && type === "mail" && */}
      {type === "mail" && !dashboard && minimizeNewMailState?.map((newMail, indexMail) => {
        return <NewMailPopup
          key={newMail.id}
          indexPopupMail={indexMail}
          setIndex={setIndexMessage}
          id={newMail.id}
          show={newMail.show}
          message={newMail.message}
          messageType={newMail.type}
          type={"mail"}
        />
      })}
      {/* } */}
      {/* {showNewSystemFlag && type === "system" && */}
      {type === "system" && !dashboard && minimizeNewSystemState?.map((newSystem, indexSystem) => {
        return <NewMailPopup
          key={newSystem.id}
          indexPopupSystem={indexSystem}
          id={newSystem.id}
          message={newSystem?.message}
          messageType={newSystem.type}
          show={newSystem.show}
          type={"system"}
        />
      })}
      {/* } */}
      {dashboard &&
        <View style={styles.minimizeMessages}>
          {minimizeMail?.length > 0 && minimizeMail?.map((e) => {
            return !e?.show && (
              <View key={e?.id} style={styles.mailMinimize} >
                <Text selectable={false} style={styles.text_logo}>
                  {t("new-message")}
                </Text>
                <HeaderPopupButtons
                  smallSize={true}
                  close={() => closeAndSave(e.id, e.message)}
                  minimize={() => dispatch(minimizeDashbord(false, !e?.show, e?.id))}
                />
              </View>
            )
          })}
        </View>
      }
    </View>
  );
}
const stylesR = (heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      flex: 1
    },
    containerPopup: {
      backgroundColor: "#00000075",
      height: "100vh",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    body: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    btnNew: {
      backgroundColor: color.logoColor,
      borderRadius: "100%",
      width: "1.2vw",
      height: "1.2vw",
      alignItems: "center",
      justifyContent: "center"
    },
    plusIcon: {
      width: "0.6vw",
      height: "0.6vw",
      resizeMode: "contain"
    },
    mail_messages_side: {
      display: "flex",
      flexDirection: "column",
      flex: 2.3,
    },
    header_left: {
      height: heightS * 30,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      borderBottomWidth: 1,
      borderStyle: "solid",
      paddingLeft: widthS * 17.2,
    },
    nav_text: {
      fontSize: widthS * 12.5,
      fontFamily: "Catamaran_600SemiBold",
      paddingRight: widthS * 15,
    },
    full_message_side: {
      flex: 5.3,
      height: "100%",
    },
    main: (flag) => ({
      alignItems: "center",
      justifyContent: flag ? "space-between" : "center",
      borderTopLeftRadius: "1vw",
      backgroundColor: background.popup1,
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: color.border,
      borderRightWidth: flag ? 1 : 0,
      padding: "0.8vw",
      borderTopRightRadius: "1vw"
    }),
    search_view: (flag) => ({
      borderRadius: flag ? "5vw" : "0.5vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      ...(
        direction === "rtl" ? {
          paddingLeft: "0.5vw",
        } : {
          paddingRight: "0.5vw",
        }
      ),
      height: "2vw",
      backgroundColor: background.chatSearch,
      writingDirection: direction,
    }),
    search_inp: (flag) => ({
      padding: "1vw",
      paddingLeft: "1vw",
      color: "white",
      fontSize: "0.9vw",
      outlineStyle: "none",
      width: "100%",
      fontFamily: "Catamaran_300Light",
      writingDirection: direction,
    }),
    minimizeMessages: {
      position: "absolute",
      bottom: 0,
      ...(direction === "ltr" ? { right: "1.5vw" } : { left: "1.5vw" }),
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5vw"
    },
    mailMinimize: {
      backgroundColor: background.loginInput,
      paddingHorizontal: "0.5vw",
      ...(direction === "ltr" ? { paddingLeft: "1.3vw", } : { paddingRight: "1.3vw" }),
      borderTopLeftRadius: "1.2vw",
      borderTopRightRadius: "1.2vw",
      // borderColor: color.text2,
      // borderWidth: "0.1vw",
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 4.4,
      paddingVertical: "0.3vw",
      flexDirection: "row",
      alignItems: "center",
      writingDirection: direction
    },
    text_logo: {
      color: color.text2,
      fontFamily: "Catamaran_700Bold",
      fontSize: "1vw",
      ...(direction === "ltr" ? { paddingRight: "0.6vw", } : { paddingLeft: "0.6vw" }),
    },
  });
