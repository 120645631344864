import { Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import ShortenWord from '../../../modules/ShortenWord';
import { editActionCreator } from '../../../../redux/actions/creatorActions';

export default function InjectItem({ objectData, index, hideAction, setShowEvent, sendInject, recall, isReal }) {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const TITLE_LIMIT_LENGTH = 38;
    const TO_LIMIT_LENGTH = 40;

    const iconPlatform = (type) => {
        switch (type) {
            case "mail":
                return images.mail;
            case "sm":
                return images.social;
            case "news":
                return images.news;
        }
    }

    return (
        <Pressable onPress={() => {
            setShowEvent(objectData?.platform_type);
            dispatch(editActionCreator({
                platform_type: objectData?.platform_type,
                id: objectData.id,
            }))
        }} style={styles.container} key={index}>
            {({ hovered }) => (
                <>
                    <View style={styles.main(objectData?.is_done || isReal)}>
                        <View style={styles.left}>
                            <Text style={styles.num}>{objectData?.time.toString().padStart(2, "0")}</Text>
                            {/* {hovered && !objectData?.is_done && <TouchableOpacity onPress={() => {
                                // hideAction(objectData?.id) 
                            }}>
                                <Ionicons name="eye-off-outline" style={[styles.iconSend, styles.eyeIcon]} />
                            </TouchableOpacity>} */}
                        </View>
                        <View style={styles.body(hovered && !objectData?.is_done && !isReal)}>
                            <View style={styles.bodyTop}>
                                <Image source={{ uri: iconPlatform(objectData.platform_type) }} style={styles.icon} />
                                <View style={styles.eventColor(objectData.eventColor)} />
                                <Text style={styles.eventName}>{objectData?.event}</Text>
                            </View>
                            <View style={styles.bodyChild}>
                                <Text style={styles.eventName}>{t("title")}: {ShortenWord(objectData?.subject, TITLE_LIMIT_LENGTH)}</Text>
                                <View style={styles.fromToView}>
                                    <Text style={styles.text}>{t("from")}: {ShortenWord(objectData?.from, TO_LIMIT_LENGTH)}</Text>
                                    <Text style={styles.text}>{t("to")}: {ShortenWord(objectData?.to_list, TO_LIMIT_LENGTH)}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {!isReal && < TouchableOpacity style={styles.sendBtn(hovered)} onPress={() => {
                        if (objectData?.is_done) {
                            recall(objectData);
                        } else {
                            sendInject(objectData);
                        }
                    }}>
                        {!objectData?.is_done ? <Ionicons
                            name="md-send-outline"
                            style={styles.iconSend}
                        /> : <AntDesign name="back" style={styles.iconSend} />}
                    </TouchableOpacity>}
                </>
            )
            }
        </Pressable >
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxHeight: "6vw",
            writingDirection: direction,
        },
        main: (isDone) => ({
            flex: 1,
            flexDirection: "row",
            marginVertical: "0.2vw",
            opacity: isDone ? 0.6 : 1
        }),
        left: {
            paddingHorizontal: "0.3vw",
            marginVertical: "0.5vw",
            justifyContent: "space-between"
        },
        body: (hovered) => ({
            flex: 1,
            backgroundColor: hovered ? background.iconBackground : background.infoBody,
            borderRadius: "0.4vw",
            padding: "0.7vw"
        }),
        num: {
            minWidth: "1vw",
            textAlign: "right",
            fontSize: "0.8vw",
            fontFamily: "Catamaran_500Medium",
            color: color.text2,
        },
        bodyTop: {
            flexDirection: "row",
            alignItems: "center"
        },
        icon: {
            width: "0.9vw",
            height: "0.9vw",
            resizeMode: "contain"
        },
        eventColor: (color) => ({
            backgroundColor: color,
            width: "0.15vw",
            height: "0.7vw",
            marginHorizontal: "0.3vw"
        }),
        eventName: {
            fontSize: "0.9vw",
            fontFamily: "Catamaran_500Medium",
            color: color.text2,
            lineHeight: "1vw"
        },
        bodyChild: {
            marginLeft: "1.5vw"
        },
        fromToView: {
            marginTop: "0.3vw"
        },
        text: {
            fontSize: "0.8vw",
            fontFamily: "Catamaran_400Regular",
            color: color.text2,
            lineHeight: "0.9vw"
        },
        sendBtn: (hovered) => ({
            backgroundColor: hovered ? color.logoColor : background.infoBox,
            borderRadius: "100%",
            width: "1.4vw",
            height: "1.4vw",
            position: "absolute",
            ...(
                direction === "rtl" ? {
                    left: "0.5vw"
                } : {
                    right: "0.5vw"
                }
            ),
            bottom: "0.5vw",
            alignItems: "center",
            justifyContent: "center"
        }),
        iconSend: {
            color: color.text2,
            fontSize: "0.8vw",
            ...(direction === "rtl" ? {
                transform: [{ scaleX: "-1" }]
            } : {})
        },
        eyeIcon: {
            transform: [{ scaleX: "-1" }]
        }
    })
}