import { StyleSheet, View, ScrollView, TextInput, Pressable, Image, TouchableOpacity, Animated, FlatList } from 'react-native'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { useState } from 'react';
import { loading, setErrorMessage, showFile } from '../../../../../redux/actions';
import { useContext } from 'react';
import { SwimlaneChartContext } from '../../../../../ContextData/Swimlanechart';
import { useRef } from 'react';
import CheckBox from '../../../../Elements/CheckBox';
import AnimationLoading from '../../../../modules/AnimationLoading';
import TextCustomize from '../../../../Elements/TextCustomize';
import OptionsListBtn from '../../../../Elements/OptionsListBtn';
import { editActionCreator } from '../../../../../redux/actions/creatorActions';
import { UpdateContext } from '../../../../../ContextData/Update';
import { vwToPx } from '../../../../modules/ConvertPXToVw';
import RowItem from './RowItem';

export default function FlowTable({ actionsArrState, setActionsState }) {

    const scrollRef = useRef(null);
    const tableRef = useRef(null);
    const popupRef = useRef(null);
    const maxItemsRef = useRef(10);

    const flowFilters = useSelector((state) => state.swimlane.filters);

    const setPopupRef = useCallback((node) => {
        popupRef.current = node;
    }, []);

    const dispatch = useDispatch();

    const scrollX = useRef(new Animated.Value(0)).current;
    const tooltipOpacityAnimation = useRef(new Animated.Value(0)).current;

    const { images, background, color } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color
    }));

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color, direction);

    const { addAction, recallFlowObject } = useContext(SwimlaneChartContext);
    const { getUpdateData } = useContext(UpdateContext);

    const tableHead = useMemo(() => [
        { label: `${t("time")} #`, width: "5vw" },
        { label: t("type"), width: "7vw" },
        { label: t("event"), width: "11vw" },
        { label: t("platform"), width: "7vw" },
        { label: 'KPI', width: "3vw" },
        { label: t("from"), width: "12vw" },
        { label: t("to"), width: "16vw" },
        { label: t("cc"), width: "16vw" },
        { label: 'BCC', width: "16vw" },
        { label: `${t("title")}/${t("subject")}`, width: "18vw" },
        { label: t("body"), width: "28vw" },
        { label: t("attachments"), width: "17vw" }
    ], [t]);

    const [actions, setActions] = useState([]);
    const [actionsToPrint, setActionsToPrint] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [selectRow, setSelectRow] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState("");
    const [delay, setDelay] = useState(true);
    const [isNewRow, setIsNewRow] = useState(false);
    const [checkNewRow, setCheckNewRow] = useState(false);

    const [newRowfloating, setNewRowfloating] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const setActionPost = useCallback((post, type, isLoad) => {
        return new Promise(async (reslover, reject) => {
            let newAction = {
                ...post,
                from: post?.from?.name_for_game || post?.from,
                from_id: post?.from_id,
                to_list: post?.to_list,
                to_list_ids: post?.to_list_ids,
                cc_list: post?.cc_list,
                cc_list_ids: post?.cc_list_ids,
                bcc_list: post?.bcc_list,
                bcc_list_ids: post?.bcc_list_ids,
                platform_type: type || post?.platform_type,
                message_id: post.message_id,
                subject: post.subject,
                body: post.body,
                file: post.file
            };
            newAction.phase_location = 0;
            if (isLoad) {
                dispatch(loading(true));
            }
            await addAction(newAction, actionsArrState.length || "0").then((res) => {
                reslover(true);
                dispatch(loading(false));
            }).catch((err) => {
                reject(err);
                dispatch(loading(false));
                dispatch(
                    setErrorMessage(
                        true,
                        t("oops"),
                        t("alert_mail_error")
                    )
                );
            });
        })
    }, [dispatch, actionsArrState, addAction]);

    const updateAction = useCallback((index, valueObject, close, isNew) => {
        if (close) {
            setIsPopupOpen("");
        }
        if (isNew) {
            setIsNewRow((prevState) => ({ ...prevState, ...valueObject, }));
        } else {
            setActionsToPrint((prevActions) => {
                const newActions = [...prevActions];
                newActions[index] = { ...newActions[index], ...valueObject };
                return newActions;
            });
            if (close) {
                setActionsToPrint(prevActions => {
                    let temp = [...prevActions];
                    temp[index] = { ...temp[index], ...valueObject, };
                    setActionPost(temp[index]);
                    return prevActions;
                })
            }
        }
    }, []);

    const filterByValue = (inputString, inputArray) => {
        return inputArray.filter((obj) => {
            return Object.values(obj).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(inputString.toLowerCase())
            );
        });
    }

    const scrollToEnd = () => {
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollTo({
                    y: scrollRef.current.scrollHeight + 100,
                    x: 0,
                    behavior: "smooth",
                });
                setIsPopupOpen(actions?.length + 1);
            }
        }, 1000);
    }

    const handleAddRow = useCallback(() => {
        let height = (scrollRef.current._listRef?._scrollRef?.offsetHeight - 30) <= ((actionsToPrint.length + 1) * vwToPx(3.4));
        if (height) {
            setNewRowfloating(true);
        } else {
            setNewRowfloating(false);
        }
        const newRow = {
            time: +actions[actions?.length - 1]?.time + 1 || 1,
            type_action: "",
            event_id: "",
            platform_type: "",
            is_kpi: false,
            from_id: "",
            to_list_ids: "",
            to_list: "",
            cc_list_ids: "",
            cc_list: "",
            bcc_list_ids: "",
            bcc_list: "",
            subject: "",
            body: "",
            file: "",
            new: true
        }; // add the new row to the data array
        setIsNewRow(newRow);
    }, [actions, actionsToPrint, setNewRowfloating, setIsNewRow]);

    const checkNewRowValidity = useCallback(() => {
        const current = isNewRow;
        if (!current) return;

        const isCompletedForm =
            current.subject?.length > 1 &&
            current.type_action &&
            Number(current.time) &&
            current.event_id;

        let isComplete = false;

        switch (current?.platform_type) {
            case "mail":
                isComplete =
                    (current.from || current.from_id) &&
                    current.to_list?.length > 0 &&
                    current.to_list_ids?.length > 0 &&
                    ((current.type_action !== "cfaChallenges" && current.body?.length > 0) ||
                        current.type_action === "cfaQuestionnaire" && current?.questionArr?.length > 0 ||
                        current.type_action === "cfaChallenges") &&
                    isCompletedForm;
                break;
            case "sm":
                isComplete =
                    (current.from || current.from_id) &&
                    current.to_list_ids?.length > 0 &&
                    current.body?.length > 0 &&
                    isCompletedForm;
                break;
            case "news":
                isComplete =
                    current.subject?.length > 0 &&
                    current.body?.length > 0 &&
                    (current.file?.uri || current.file[0]?.uri) &&
                    isCompletedForm;
                break;
            default:
                isComplete = false;
                break;
        }

        setCheckNewRow(isComplete); // עדכון סטייט פעם אחת בסוף הפונקציה
    }, [isNewRow, setCheckNewRow]);

    const loadMore = useCallback(() => {
        if (maxItemsRef.current < actions.length) {
            dispatch(loading(true));

            const newItems = actions.slice(maxItemsRef.current, maxItemsRef.current + 10);

            setActionsToPrint(prevItems => [...prevItems, ...newItems]);

            maxItemsRef.current += 10;
            setTimeout(() => {
                dispatch(loading(false));
            }, 1000);
        }
    }, [actions, actionsToPrint, dispatch]);


    const saveNewRow = useCallback(() => {
        setLoadingState(true);

        setActionPost(isNewRow)
            .then(() => {
                setNewRowfloating(false);
                setLoadingState(false);
                console.log(isPopupOpen); // נראה שהשורה הזו לצורך דיבאגינג ואפשר להסיר אותה אם היא לא נחוצה יותר
                setIsPopupOpen("");

                // מכיוון שאתה מעדכן את המצב בהסתמך על מצב קודם, כדאי להשתמש בפונקציה עדכון
                setActionsToPrint(prevActionsToPrint => [...prevActionsToPrint, isNewRow]);
                setActions(prevActions => [...prevActions, isNewRow]);
                setIsNewRow(false);
                // if (+actions[actions.length - 1].time < +isNewRow.time) {
                //     if (maxItems < actionsToPrint.length) {
                //         setMaxItems(actionsToPrint.length);
                //     }
                //     scrollToEnd();
                // }
            })
            .catch(() => {
                setLoadingState(false);
                setNewRowfloating(false);
            });
    }, [
        isNewRow, setLoadingState, setActionPost,
        setNewRowfloating, setIsPopupOpen, setActionsToPrint, setActions
    ]);

    const removeNewRow = useCallback(() => {
        setIsNewRow(false);
    }, [setIsNewRow]);

    const deleteAction = useCallback(async (selectedRows) => {
        dispatch(loading(true));



        // טיפול במחיקות בצורה אסינכרונית ומחכה
        for (const action of selectedRows) {
            try {
                await dispatch(editActionCreator(false));
                if (action.is_done) {
                    await recallFlowObject(action);
                }
                await addAction(action, 0, "delete");
            } catch (error) {
                // טיפול בשגיאה
            }
        }

        dispatch(loading(false));
        getUpdateData();
    }, [dispatch, addAction, recallFlowObject, getUpdateData, editActionCreator]);

    const onScroll = useCallback((event) => {
        const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
        const isScrolledToEnd = layoutMeasurement.height + contentOffset.y >= (contentSize.height - 8);
        if (isScrolledToEnd && maxItemsRef.current < actions.length && isPopupOpen === "") {
            loadMore();
        }
        scrollX.setValue(contentOffset.x);
        if (contentOffset.x > 5 * vwToPx(1)) {
            Animated.timing(tooltipOpacityAnimation, {
                toValue: 1,
                duration: 200, // Duration of animation
                useNativeDriver: true
            }).start();
        } else {
            Animated.timing(tooltipOpacityAnimation, {
                toValue: 0,
                duration: 200, // Duration of animation
                useNativeDriver: true
            }).start();
        }
    }, [actions.length, isPopupOpen]);

    const renderCell = useCallback(({ index, style, ...props }) => {
        const maxLength = 1499;
        const zIndex = index === 0 ? 1500 : maxLength - index; // `zIndex` גבוה עבור השורה הראשונה, קבוע עבור שאר השורות
        const cellStyle = {
            ...style,
            zIndex,
            ...(index === 0 && {
                position: "sticky",
                top: 0
            }),
        };

        return <View style={cellStyle} {...props} />;
    }, []); // תלות ריקה - הפונקציה לא תיצור מחדש

    useEffect(() => {
        checkNewRowValidity();
    }, [checkNewRowValidity]);


    useEffect(() => {
        actionsArrState.sort((a, b) => a.time - b.time);
        setActions([...actionsArrState]);
    }, [actionsArrState]);



    const filteredActions = useMemo(() => {
        if (searchText?.length === 0) return actions.slice(0, maxItemsRef.current);
        return filterByValue(searchText, actions).slice(0, maxItemsRef.current);
        // return filterByValue(searchText, actions);
    }, [actions, searchText, maxItemsRef.current]);

    useEffect(() => {
        setActionsToPrint(filteredActions);
        const timer = setTimeout(() => {
            setDelay(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, [filteredActions]);

    useEffect(() => {
        let timerId;
        if (isPopupOpen !== "") {
            timerId = setTimeout(() => {
                const currentX = scrollRef.current._listRef?._scrollRef.scrollLeft;
                const currentY = scrollRef.current._listRef?._scrollRef.scrollTop;

                // Measure the layout of the popup
                popupRef.current.measureLayout(
                    scrollRef.current.getScrollableNode(),
                    (popupX, popupY, popupWidth, popupHeight) => {
                        // Get the screen dimensions
                        const screenWidth = scrollRef.current._listRef?._scrollRef.offsetWidth;
                        const screenHeight = scrollRef.current._listRef?._scrollRef.offsetHeight;

                        // Calculate the positions considering current scrolling
                        const startPopup = currentX + popupX;
                        const endPopup = startPopup + popupWidth;
                        const startPopupY = currentY + popupY;
                        const endPopupY = startPopupY + popupHeight;

                        let newScrollX = currentX;
                        let newScrollY = currentY;

                        if (lang === "he") {
                            // RTL scrolling logic for Hebrew
                            if (startPopup + popupWidth > screenWidth) {
                                // If the popup's starting position plus its width is greater than the screen width
                                newScrollX = currentX + ((startPopup + popupWidth) - screenWidth + 50);
                            } else if (endPopup - popupWidth < currentX) {
                                // If the popup's ending position minus its width is less than the current scroll position
                                newScrollX = currentX - (currentX - (endPopup - popupWidth) + 50);
                            }
                        } else {
                            if (endPopup > currentX + screenWidth) {
                                // If the popup's ending position is after the viewport's ending position on X-axis
                                newScrollX = (endPopup + 50) - screenWidth;
                            } else if (startPopup < currentX) {
                                // If the popup's starting position is before the viewport's starting position on X-axis
                                newScrollX = currentX - (startPopup - 50);
                            }
                        }

                        if ((endPopupY + 50) > currentY + screenHeight) {
                            // If the popup's ending position is after the viewport's ending position on Y-axis
                            newScrollY = (endPopupY + 50) - screenHeight;
                        } else if (startPopupY < currentY) {
                            // If the popup's starting position is before the viewport's starting position on Y-axis
                            newScrollY = (currentY - 50) - startPopupY;
                        }

                        scrollRef.current._listRef?._scrollRef.scrollTo({ x: newScrollX, y: newScrollY, animated: true });
                    }
                );
            }, 400);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [isPopupOpen, scrollRef]);

    const filterActions = async () => {
        if (flowFilters.player.length > 0 ||
            flowFilters.event.length > 0 ||
            flowFilters.platform.length > 0) {
            let temp = [...actions];
            await setActionsToPrint([]);
            //await setFlowTableSteps([]);
            temp = temp.filter(
                (action) =>
                    (!flowFilters.player ||
                        flowFilters.player.indexOf(action.from_id) > -1 ||
                        action.to_list_ids
                            .split(",")
                            .filter((id) => id.trim())
                            .some((id) => flowFilters.player.indexOf(id.trim()) > -1)) &&
                    (!flowFilters.event ||
                        flowFilters.event.indexOf(action.event_id) > -1) &&
                    (!flowFilters.platform ||
                        flowFilters.platform.indexOf(action.platform_type) > -1)
            );
            setActionsToPrint([...temp]);
        } else {
            setActionsToPrint([...actions]);
        }
    };

    useEffect(() => {
        filterActions();
    }, [flowFilters]);

    return {
        handleAddRow,
        saveNewRow,
        removeNewRow,
        tableRef,
        component: (
            <>
                <View style={styles.container}>
                    <OptionsListBtn
                        saveBtnShow={""}
                        keyHeaderText={"table_view"}
                        upperCase={true}
                        isNew={isNewRow}
                        deleteItems={selectRow.length > 0}
                        disabledSave={!checkNewRow}
                        table={true}
                        loadingState={loadingState}
                        onChangeText={(txt) => setSearchText(txt)}
                        onPressAddItem={() => handleAddRow()}
                        onCancel={() => removeNewRow()}
                        onSave={() => saveNewRow()}
                        onPressDeleteItems={() => deleteAction(selectRow)}
                        dark={true}
                    />
                    <View style={styles.main}>
                        <FlatList
                            data={[{ type: "header" }, ...actionsToPrint]}
                            CellRendererComponent={renderCell}
                            windowSize={5}
                            renderItem={({ item, index }) => {
                                if (item?.type === "header") {
                                    return <View style={styles.tableHeaderView}>
                                        {tableHead.map((e, index) => (
                                            <View style={styles.tableHeaderItem(e.width)} key={index}>
                                                <TextCustomize text={e.label} style={{ textAlign: "center" }} fontWeight={700} fontSize={"0.8vw"} />
                                            </View>
                                        ))}
                                    </View>
                                }
                                let e = item;
                                let isDone = e.is_done;
                                return <RowItem
                                    key={e?.id}
                                    e={e}
                                    index={index - 1}
                                    isDone={isDone}
                                    selected={selectRow.findIndex((elem) => e.id === elem.id) >= 0}
                                    // zIndex={isPopupOpen === index - 1 ? 10 : 1}
                                    isNew={newRowfloating && !e?.id}
                                    updateAction={updateAction}
                                    selectRow={selectRow}
                                    setSelectRow={setSelectRow}
                                    isPopupOpen={isPopupOpen === index - 1 ? true : false}
                                    setIsPopupOpen={setIsPopupOpen}
                                    isBottom={false}
                                    loadingState={loadingState}
                                    popupRef={popupRef}
                                    setPopupRef={setPopupRef}
                                    scrollRef={scrollRef}
                                    setActionsState={setActionsState}
                                    tooltipOpacityAnimation={tooltipOpacityAnimation}
                                    scrollX={scrollX}
                                />
                            }}
                            maxToRenderPerBatch={10}
                            style={styles.scroll_h_c}
                            nativeID="flow_table-horizontal"
                            showsVerticalScrollIndicator={true}
                            onScroll={onScroll}
                            removeClippedSubviews={true}
                            ref={scrollRef}
                            keyExtractor={(item, index) => item.id || index}
                            onEndReachedThreshold={0.5} // קובע מתי לפעול onEndReached ביחס לסוף הרשימה
                            onEndReached={loadMore}
                            ListFooterComponentStyle={[newRowfloating && actionsToPrint.length > 5 ? {
                                zIndex: 1500,
                                position: "sticky",
                                bottom: 0,
                            } : {}]}
                            ListFooterComponent={isNewRow && <RowItem
                                e={isNewRow}
                                index={1500}
                                isDone={false}
                                isNew={true}
                                updateAction={updateAction}
                                selectRow={selectRow}
                                setSelectRow={setSelectRow}
                                isPopupOpen={isPopupOpen}
                                setIsPopupOpen={setIsPopupOpen}
                                isBottom={newRowfloating && actionsToPrint.length > 5}
                                loadingState={loadingState}
                                popupRef={popupRef}
                                setPopupRef={setPopupRef}
                                scrollRef={scrollRef}
                                setActionsState={setActionsState}
                                tooltipOpacityAnimation={tooltipOpacityAnimation}
                                scrollX={scrollX}
                            />}
                        />
                    </View>
                </View>
                {<AnimationLoading flag={true} widget={true} />}
            </>

        )
    }
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            width: "100%",
            flex: 1,
            zIndex: 100,
            width: "82vw",
            paddingVertical: "1.5vw",
            paddingHorizontal: "2vw",
            writingDirection: direction
        },
        main: {
            backgroundColor: background.infoHeader,
            flex: 1,
            borderRadius: "1.5vw",

            margin: "1vw",
            paddingHorizontal: "1.2rem",
            paddingBottom: "0.05rem"
        },
        scroll_h_c: {
            borderRadius: "1.5vw",
            position: "relative",
            overflowX: "auto",
            overflowY: "auto",
            backgroundColor: background.infoHeader,
        },
        tableHeaderView: {
            flexDirection: "row",
            backgroundColor: background.infoHeader,
        },
        tableHeaderItem: (width) => ({
            width: width,
            textAlign: "center",
            paddingVertical: "0.8vw"
        }),
        kpiCell: {
            alignItems: "center",
            justifyContent: "center",
        },
        menuBtn: {
            position: "absolute",
            top: "0.1vw",
            width: "1vw",
            height: "1vw",
            left: "0.6vw",
            alignItems: "center",
            justifyContent: "center",
        },
        menuIcon: {
            width: "0.6vw",
            height: "0.6vw",
            tintColor: color.placeholder
        },
    })
}

