import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Pressable,
  TextInput,
} from "react-native";

import {
  USER_ID_IN_GAME,
} from "../../../config.inc";

import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18n-js";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import GroupChatPopup from "./GroupChatPopup";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";

export default function AddChat({ setIndex, setNewData, data, fromAndTo, setTo, resetIndex, dashboard }) {
  const { widthS, heightS } = UseResponsiveScreen();
  const background = useSelector((state) => state.style.background);
  const styles = stylesR(heightS, widthS, background);
  const { checkEnd } = onClickEndGameCheck();


  const chatBtnRef = useRef(null);
  const groupBtnRef = useRef(null);
  const chatRef = useRef(null);
  const chatGroupRef = useRef(null);

  const [showNewChat, setShowNewChat] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);

  const printOption = (option) => {
    if (option && option.length > 15) {
      return `${option.slice(0, 15)}...`;
    }
    return option;
  };

  return (
    <View style={styles.container}>
      <View style={styles.header(dashboard)}>
        <Pressable
          style={[
            styles.header_btn(false, dashboard),
            {},
          ]}
          ref={chatBtnRef}
          onPress={() => checkEnd(() => setShowNewChat(!showNewChat))}
        >
          <MaterialCommunityIcons
            selectable={false}
            name="plus-thick"
            size={widthS * 10}
            color="white"
          />
          <Text selectable={false} style={styles.btn_text(dashboard)}>
            {t("new-chat")}
          </Text>
        </Pressable>
        {showNewChat && (
          <GroupChatPopup
            type={""}
            flag={showNewChat}
            setFlag={() => setShowNewChat(false)}
            btnRef={chatBtnRef}
            setIndex={setIndex}
            setNewData={setNewData}
            chatRef={chatRef}
            right={"-1vw"}
            data={data}
            fromAndTo={fromAndTo}
            setTo={setTo}
            resetIndex={resetIndex}
          />
        )}
        <Pressable
          style={styles.header_btn(true, dashboard)}
          ref={groupBtnRef}
          onPress={() => checkEnd(() => setShowNewGroup(!showNewGroup))}
        >
          <MaterialIcons
            selectable={false}
            name="group-add"
            size={widthS * 18}
            color="white"
          />
          <Text selectable={false} style={styles.btn_text(dashboard)}>{` ${printOption(
            t("new-g-chat")
          )}`}</Text>
        </Pressable>
        {showNewGroup && (
          <GroupChatPopup
            type={"group"}
            flag={showNewGroup}
            setFlag={() => setShowNewGroup(false)}
            btnRef={groupBtnRef}
            setIndex={setIndex}
            setNewData={setNewData}
            chatRef={chatGroupRef}
            right={"-1vw"}
            data={data}
            fromAndTo={fromAndTo}
            setTo={setTo}
            newGroup={true}
            resetIndex={resetIndex}
          />
        )}
      </View>
    </View>
  );
}
const stylesR = (heightS, widthS, background) =>
  StyleSheet.create({
    header: (flag) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: flag ? "1vw" : "2vw",
      paddingRight: flag ? "0.5vw" : "1vw",
      zIndex: 1002,
    }),
    header_btn: (flag, flag1) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: flag1 ? "1.8vw" : "2vw",
      borderRadius: widthS * 6,
      backgroundColor: background.chatLogo,
      flex: flag ? 1 : 0.7,
      marginLeft: flag ? widthS * 8 : "",
    }),
    btn_text: (flag) => ({
      color: "white",
      fontFamily: "Catamaran_600SemiBold",
      fontSize: flag ? "0.6vw" : "0.7vw",
    }),
    triangular: {
      position: "absolute",
      top: 0,
      left: "2vw",
      width: 0,
      height: 0,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderRightWidth: widthS * 5,
      borderLeftWidth: widthS * 5,
      borderBottomWidth: widthS * 6,
      zIndex: 1004,
    },
  });
