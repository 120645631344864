import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_DOCE_PATH, GET_DOC_PATH, URL_PATH, DELETE_DOC_PATH } from "@env";
import { F_PHPSESSID, GAME_ID, _FILE0_, _F_PHPSESSID_, _ID_, _NAME_, _SUBJECT_ } from "../config.inc";
import { setDocs, addDoc } from "../redux/actions/index";

export const DocsContext = createContext();
export default function DocsProvider(props) {

	const dispatch = useDispatch();
	const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

	const getDocsData = () => {
		const url = `${URL_PATH}${GET_DOC_PATH}`;
		const body = new FormData();
		body.append(_F_PHPSESSID_, sessionStorage.getItem(
			F_PHPSESSID
		))
		try {
			fetch(url, { method: "POST", body: body })
				.then((res) => res.text())
				.then((res) => {
					res = JSON.parse(res);
					if (res.status === "true") {
						dispatch(setDocs(res.docs));
					} else {
						dispatch(setDocs([]));
					}
				})
				.catch((err) => {
					// expo Tel-Aviv
					let res = {
						status: "true",
						docs: [
							{ name: "image.jpg", type: "image/jpg", link: "https://s3.eu-west-1.amazonaws.com/dev.be-strategic.solutions/games/1329/29234aee7125829fe42348e2eae5370433349316.png", description: "This is show images example " }
						]
					}
					if (res.status === "true") {
						dispatch(setDocs(res.docs));
					}
				});
		} catch (e) { }
	};
	const addFile = (files, name, description) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append(_FILE0_, files.file);
			formData.append(_NAME_, name);
			formData.append(_SUBJECT_, description);
			formData.append(_F_PHPSESSID_, sessionStorage.getItem(
				F_PHPSESSID
			));
			formData.append(_ID_, sessionStorage.getItem(GAME_ID));
			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "*/*",
				},
				body: formData,
			};
			const url = `${URL_PATH}${ADD_DOCE_PATH}`;
			try {
				fetch(url, requestOptions)
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.doc_sent === "true") {
							dispatch(addDoc(res.doc));
							props.sentMessageWebSocket("doc", "all");
							resolve(true);
						} else {
							reject(false);
						}
					})
					.catch((err) => {
						reject(false);
					});
			} catch (e) { reject(false); }
		})
	};

	const deleteDocData = (id) => {
		return new Promise((resolve, reject) => {
			const url = `${URL_PATH}${DELETE_DOC_PATH}`;
			const body = new FormData();
			body.append(_F_PHPSESSID_, sessionStorage.getItem(
				F_PHPSESSID
			))
			body.append(_ID_, id)
			try {
				fetch(url, { method: "POST", body: body })
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.deleted === "true") {
							getDocsData().then(() => {
								resolve(true);
							}).catch(() => {
								reject(false);
							})
						} else {
							reject(false);
						}
					}).catch(() => {
						reject(false);
					})
			} catch (e) { reject(false); }
		})
	}

	useEffect(() => {
		let interval;
		if (props.typeRefresh === "doc") {
			console.log("refreshing doc")
			getDocsData();
		}
		// if(props.typeRefresh === "true"){
		// 	interval = setInterval(() => {
		// 	  getDocsData();
		// 	},15000);
		//   }
		//   return () => {
		// 	clearInterval(interval);
		//   }
	}, [props.typeRefresh]);
	useMemo(() => {
		if (props.loginType !== "in") {
			dispatch(setDocs([]));
		}
	}, [props.loginType]);
	useEffect(() => {
		if (gameInfo.game_name) {
			getDocsData();
		}
	}, [gameInfo]);
	return (
		<DocsContext.Provider
			value={{
				getDocsData,
				addFile,
				deleteDocData,
			}}
		>
			{props.children}
		</DocsContext.Provider>
	);
}
