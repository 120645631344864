import { getContent } from "../../componnets/modules/getContent";
import { createF, createFilesArr, createFilesArrilesArrcreateFilesArr } from "../../componnets/modules/CreateFileObject";
import {
  MAIL_FLAG,
  SET_MAIL,
  SET_SENT_MAIL,
  CHANGE_ARRANGE_MAIL,
  NEW_MASSGAE_FLAG,
  NEW_MESSAGE_CURRENT,
  NEW_MESSAGE_CURRENT_TYPE,
  SET_DRAFTS_MAIL,
  DELETE_DRAFT,
  MSG_FLAG_MAIL,
  MARK_MAIL,
} from "../actions/index";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { createQuestionArr } from "../../componnets/modules/createQuestionArr";
import { editDate } from "../../EditDate";
import { addOrReplaceItemInArr } from "../function/addOrReplaceItemInArr";
import { getLang } from "../../getLang";

const checkRead = (flag) => {
  return flag === "1" ? true : false;
}

const convertTimeFormatString = (time, lang) => {
  if (time) {
    const formattedTime = time?.replace(" ", "T") + "Z";
    const inputDate = new Date(`${formattedTime}`);
    // Get the month abbreviation
    const monthAbbreviation = new Intl.DateTimeFormat(getLang("en", true), { month: 'short' }).format(inputDate);

    // Get the day, year, hour, minute, and second components
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();
    const hour = inputDate.getHours().toString().padStart(2, '0');
    const minute = inputDate.getMinutes().toString().padStart(2, '0');
    const second = inputDate.getSeconds();

    // Create the output string in the desired format
    return `${monthAbbreviation} ${day} ${year} | ${hour}:${minute}`;
  }
  return "";
}

export const mailReducer = (
  state = {
    sentMails: {},
    messages: [],
    drafts: [],
    unReadMessages: 0,
    unReadMessagesArr: [],
    flag: false,
    newMessageFlag: false,
    newMessageType: "",
    newMessageCurrent: {},
    questionniareArr: [],
    arrangeSent: { name: "date", value: "Date" },
    arrangeInbox: { name: "date", value: "Date" },
    arrangeDrafts: { name: "date", value: "Date" },
  },
  action
) => {
  switch (action.type) {
    case SET_MAIL: {
      let messages = [...state.messages];
      let questionniareArr = [...state.questionniareArr];
      action.payload.forEach((e, i) => {
        if (true) {
          e.body = e.body.replace("\\", "");
          let mail = {
            id: e.id,
            message_parent_id: e.message_parent_id ? e.message_parent_id : "",
            subject: e.subject ? checkIfIsEncodeText(e.subject, "string") : "",
            body: e.body ? checkIfIsEncodeText(e.body, "html") : "",
            body_search: e.body ? getContent(checkIfIsEncodeText(e.body, "line")) : "",
            sender: {
              id: e.sender.id,
              name: e.sender.name ? checkIfIsEncodeText(e.sender.name, "string") : 'System',
            },
            to_list: e.to_list_ids,
            to_list_names: checkIfIsEncodeText(e.to_list),
            cc_list: e.cc_list_ids ? e.cc_list_ids : null,
            cc_list_names: e.cc_list ? checkIfIsEncodeText(e.cc_list) : null,
            bcc_list: e.bcc_list_ids ? e.bcc_list_ids : null,
            bcc_list_names: e.bcc_list ? checkIfIsEncodeText(e.bcc_list) : null,
            flag: e.flag && e.flag === "1" ? true : false,
            event: e.event ? { id: e.event.id, name: e.event.name } : { id: "", name: "" },
            has_attachments:
              e.has_attachments && e.has_attachments !== "0" ? true : false,
            time: e.time ? e.time : null,
            is_survey: e.is_survey ? e.is_survey : "0",
            read_or_not: checkRead(action.permission === "1" ? e.is_admin_read : e.read_or_not),
            status: e?.status === "1" ? true : false
          };
          if (e.timestamp_hashes) {
            Object.keys(e.timestamp_hashes).forEach((time) => {
              // let date = `${editDate(convertTimeFormatString(e.timestamp_hashes[time], action?.lang), "mail", "mail")} | ${editDate(
              //   convertTimeFormatString(e.timestamp_hashes[time], action?.lang),
              //   "mail",
              //   "clock"
              // )}`;
              let date = convertTimeFormatString(e.timestamp_hashes[time], action?.lang);
              mail.body = mail.body.replace(time, date);
            })
          }
          if (mail.has_attachments >= 1) {
            mail.attachments = createFilesArr(e.attachments);
          }
          if (mail.is_survey === "1" && (mail?.status || mail?.status === "1")) {
            e.questions = createQuestionArr(e.questions);
            mail.questions = e.questions;
            if (action.permission === "15" && !e.questions.submitted) {
              questionniareArr.push(mail);
            }
          }
          const { addFlag, arrCurrent } = addOrReplaceItemInArr(messages, mail);
          messages = arrCurrent;
          if (addFlag && !state.unReadMessagesArr.find((unRead) => unRead.id === e.id) && (mail.read_or_not === "0" || !mail.read_or_not)) {
            console.log("push to unRead");
            state.unReadMessagesArr.push(mail);
            state.unReadMessagesArr.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
            state.unReadMessagesArr.map((unRead, index) => unRead.index = index);
          } else if (!addFlag && state.unReadMessagesArr.find((un) => un.id === e.id) && (mail.read_or_not === "1" || mail.read_or_not)) {
            let index = state.unReadMessagesArr.findIndex((m) => m.id === mail?.id);
            if (index >= 0) {
              state.unReadMessagesArr.splice(index, 1);
            }
          }
        }
      });
      console.log("count" + state.unReadMessagesArr?.length);
      console.log("arr unRead" + state.unReadMessagesArr);
      return {
        ...state,
        messages: messages,
        unReadMessages: state.unReadMessagesArr?.length,
        unReadMessagesArr: [...state.unReadMessagesArr],
        questionniareArr: questionniareArr
      };
    }
    case SET_SENT_MAIL: {
      let sentMail = [];
      let messages = [];
      let messagesUnreadArr = [];
      let count = 0;
      action.payload.forEach((e, i) => {
        if (true) {
          e.body = e.body.replace("\\", "");
          let mail = {
            id: e.id,
            message_parent_id: e.message_parent_id ? e.message_parent_id : null,
            subject: e.subject ? checkIfIsEncodeText(e.subject, "string") : "",
            body: e.body ? checkIfIsEncodeText(e.body, "html") : "",
            body_search: e.body ? getContent(checkIfIsEncodeText(e.body, "line")) : "",
            sender: {
              id: e.sender.id,
              name: checkIfIsEncodeText(e.sender.name, "string"),
            },
            to_list: e.to_list_ids,
            to_list_names: checkIfIsEncodeText(e.to_list),
            cc_list: e.cc_list_ids ? e.cc_list_ids : null,
            cc_list_names: e.cc_list ? checkIfIsEncodeText(e.cc_list) : null,
            bcc_list: e.bcc_list_ids ? e.bcc_list_ids : null,
            bcc_list_names: e.bcc_list ? checkIfIsEncodeText(e.bcc_list) : null,
            flag: e.flag && e.flag === "1" ? true : false,
            event: e.event ? { id: e.event.id, name: e.event.name } : { id: "", name: "" },
            has_attachments:
              e.has_attachments && e.has_attachments !== "0" ? true : false,
            time: e.time ? e.time : null,
            is_survey: e.is_survey ? e.is_survey : "0",
            read_or_not: checkRead(action.permission === "1" ? e.is_admin_read : e.read_or_not),
          };
          if (e.timestamp_hashes) {
            Object.keys(e.timestamp_hashes).forEach((time) => {
              let date = convertTimeFormatString(e.timestamp_hashes[time], action?.lang);
              mail.body = mail.body.replace(time, date);
            })
          }
          if (e.has_attachments >= 1) {
            mail.attachments = createFilesArr(e.attachments);
          }
          messages.push(mail);
          if (e.read_or_not === "0") {
            messagesUnreadArr.push({
              ...mail,
              index: messagesUnreadArr.length,
            });
            count++;
          }

        }
      });
      return {
        ...state,
        sentMails: messages
      };
    }
    case MAIL_FLAG: {
      return { ...state, flag: action.flag };
    }
    case SET_DRAFTS_MAIL: {
      // const temp = [...state.drafts];
      // const index = temp.findIndex((draft) => draft.id === action.payload.id);
      // if (action.remove) {
      //   temp.splice(index, 1);
      //   return { ...state, drafts: temp };
      // }
      let arr = [...state.drafts];
      action.payload?.map((e, i) => {
        e.body = e.body?.replace("\\", "") || null;
        let draft = {
          is_draft_message: true,
          sender: {
            id: e.sender.id,
            name: e.sender.name ? checkIfIsEncodeText(e.sender.name, "string") : null,
          },
          id: e.id,
          message_parent_id: e.message_parent_id
            ? e.message_parent_id
            : null,
          subject: e.subject ? checkIfIsEncodeText(e.subject, "string") : "",
          body: e.body && e.body?.length > 0 ? checkIfIsEncodeText(e.body, "html") : "",
          body_search: e.body && e.body?.length > 0
            ? getContent(checkIfIsEncodeText(e.body, "line"))
            : "",
          to_list_names: e.to_list ? checkIfIsEncodeText(e.to_list) : "",
          to_list: e.to_list_ids ? e.to_list_ids : "",
          cc_list_names: e.cc_list ? checkIfIsEncodeText(e.cc_list) : "",
          cc_list: e.cc_list_ids ? e.cc_list_ids : "",
          bcc_list_names: e.bcc_list ? checkIfIsEncodeText(e.bcc_list) : null,
          bcc_list: e.bcc_list_ids ? e.bcc_list_ids : null,
          flag: e.flag && e.flag === "1" ? true : false,
          event: e.event ? { id: e.event.id, name: e.event.name } : { id: "", name: "" },
          has_attachments:
            e.has_attachments &&
              Number(e.has_attachments) > 0
              ? true
              : false,
          time: e.time ? e.time : null,
          post: e.post ? checkIfIsEncodeText(e.post, "string") : null,
          message: e.message ? checkIfIsEncodeText(e.message, "string") : null,
          is_survey: "0"
        };
        if (e.timestamp_hashes) {
          Object.keys(e.timestamp_hashes).forEach((time) => {
            if (!e.timestamp_hashes[time]) {
              draft.body = draft.body.replace(e.timestamp_hashes[time], "");
              return
            }
            let date = convertTimeFormatString(e.timestamp_hashes[time], action?.lang);
            draft.body = draft.body.replace(time, date);
          })
        }
        if (draft.has_attachments) {
          draft.attachments = createFilesArr(e.attachments);
        }
        let index = arr.findIndex((item) => item.id === e.id)
        if (index >= 0) {
          arr[index] = draft;
        } else {
          arr.push(draft);
        }
      });
      // if (index >= 0) {
      //   temp.splice(index, 1, draft);
      //   return { ...state, drafts: temp };
      // }
      return { ...state, drafts: [...arr] };
    }
    case DELETE_DRAFT: {
      const newDrafts = state.drafts.filter(draft => draft.id !== action.payload.id);
      return { ...state, drafts: newDrafts };
    }

    case CHANGE_ARRANGE_MAIL: {
      let newArrange = {
        name: action.name,
        value: action.value,
      };
      if (action.typeMsg === "Inbox") {
        return { ...state, arrangeInbox: newArrange };
      } else if (action.typeMsg === "Drafts") {
        return { ...state, arrangeDrafts: newArrange };
      }
      return { ...state, arrangeSent: newArrange };
    }
    case MSG_FLAG_MAIL: {
      if (action.typeMsg == "Inbox") {
        let current = state.messages.findIndex((e) => e.id === action.payload);
        state.messages[current].flag = !state.messages[current].flag;
        return { ...state, messages: [...state.messages] }
      } else if (action.typeMsg == "Sent" && action.permission != "1") {
        let current = state.sentMails.findIndex((e) => e.id === action.payload);
        state.sentMails[current].flag = !state.sentMails[current];
        return { ...state, sentMails: [...state.sentMails] }
      }
    }
    case NEW_MASSGAE_FLAG: {
      return { ...state, newMessageFlag: action.flag };
    }
    case NEW_MESSAGE_CURRENT: {
      return { ...state, newMessageCurrent: action.payload };
    }
    case NEW_MESSAGE_CURRENT_TYPE: {
      return { ...state, newMessageType: action.payload };
    }
    case MARK_MAIL:
      let current = state.messages.findIndex((e) => e.id === action.payload);
      if (current >= 0) {
        state.messages[current].read_or_not = true;
        let index = state.unReadMessagesArr.findIndex((e) => e.id === action?.payload);
        if (index >= 0) {
          state.unReadMessagesArr.splice(index, 1);
          state.unReadMessagesArr.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
          state.unReadMessagesArr.map((unRead, index) => unRead.index = index);
        }
        if (action?.isQuest) {
          state.questionniareArr = state.questionniareArr.filter((e) => e.id !== action?.payload);
        }
      }
      state.unReadMessagesArr.map((unRead, index) => unRead.index = index);
      return {
        ...state,
        messages: state.messages,
        unReadMessagesArr: [...state.unReadMessagesArr],
        unReadMessages: state.unReadMessagesArr?.length,
        questionniareArr: state.questionniareArr
      }
    default: {
      return state;
    }
  }
};
