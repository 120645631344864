import { StyleSheet, Text, View, Dimensions, Pressable, Image, TextInput, ScrollView, Touchable, TouchableHighlight } from 'react-native';
import React, { useRef, useState, useMemo, useEffect, useContext } from 'react';
import { t } from "i18n-js";
import { useDispatch, useSelector } from 'react-redux';
import { listenerAdd, listenerAddBT } from '../../../../AddListener';
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import ShortenWord from "../../../modules/ShortenWord";
import { changePositionRealPlayers, changePositionRepPlayers, openRealPlayer, openRepPlayer } from '../../../../redux/actions/creatorActions';
import DraggedPlayer from "../../../modules/DraggedPlayer";
import { GameInfoContext } from '../../../../ContextData/GameInfo';
import { loading, setErrorMessage } from '../../../../redux/actions';
import OptionsListBtn from '../../../Elements/OptionsListBtn';

export default function PlayerSimulationBody({ setLoad }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const { deletePlayerData, invitePlayerData } = useContext(GameInfoContext);

    const realPlayerScrollRef = useRef(null);
    const repPlayerScrollRef = useRef(null);

    const dispatch = useDispatch();

    const images = useSelector(state => state.style.images);
    const background = useSelector(state => state.style.background);
    const color = useSelector(state => state.style.color);

    const realPlayersSelector = useSelector(state => state.gameInfoArr.gameInfoArr.players);
    const repPlayersSelector = useSelector(state => state.gameInfoArr.gameInfoArr.players);

    const typesSelectOptions = [
        { name: t("game_controller"), value: "1" },
        { name: `${t("game_controller_observer")}`, value: "10" },
        { name: t("player"), value: "15" },
        { name: `${t("player_observer")}`, value: "17" },
    ];

    const [realPlayers, setRealPlayers] = useState([]);
    const [repPlayers, setRepPlayers] = useState([]);

    const [realPlayersState, setRealPlayersState] = useState([]);
    const [repPlayersState, setRepPlayersState] = useState([]);

    const [realPlayersSelect, setRealPlayersSelect] = useState("");
    const [repPlayersSelect, setRepPlayersSelect] = useState("");

    const [realSelectAll, setRealSelectAll] = useState(false);
    const [repSelectAll, setRepSelectAll] = useState(false);   

    const [indexRep, setIndexRep] = useState();
    const [indexReal, setIndexReal] = useState();
    const [indexCurrent, setIndexCurrent] = useState(0);

    const addAllPlayers = (type) => {
        let players = "";
        switch (type) {
            case "real":
                realPlayers.forEach((player, i) => {
                    players += `${player.id},`;
                });
                return setRealPlayersSelect(players);
            case "rep":
                repPlayers.forEach((player, i) => {
                    players += `${player.id},`;
                });
                return setRepPlayersSelect(players);
            default: return
        }
    }

    const setNewIndex = (index, newIndex, type) => {
        if (type === "real") {
            dispatch(changePositionRealPlayers(index, newIndex));
        } else {
            dispatch(changePositionRepPlayers(index, newIndex));
        }
    }
    const setNewIndexState = (index, newIndex, type) => {
        if (type === "real") {
            let currentPlayer = realPlayers[index];
            let playersTemp = realPlayers.filter((e, i) => i !== index);
            playersTemp.splice(newIndex, 0, currentPlayer);
        } else {
            dispatch(changePositionRepPlayers(index, newIndex));
        }
    }

    const sortPlayers = (type) => {
        let temp = [...realPlayers];
        switch (type) {
            case "az":
                temp.sort((a, b) => {
                    if (a.name_for_game === b.name_for_game) {
                        return 0;
                    }
                    else if (a.name_for_game > b.name_for_game) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                });
                return setRealPlayers([...temp]);
            case "department":
                temp.sort((a, b) => {
                    if (a.department === b.department) {
                        return 0;
                    }
                    else if (a.department > b.department) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                });
                return setRealPlayers([...temp]);
            case "type":
                temp.sort((a, b) => {
                    if (a.permission_for_game === b.permission_for_game) {
                        return 0;
                    }
                    else if (a.permission_for_game > b.permission_for_game) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                });
                return setRealPlayers([...temp]);
            case "status":
                temp.sort((a, b) => {
                    if (a.active === "true") {
                        return 0;
                    }
                    else if (b.active === "true") {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                });
                return setRealPlayers([...temp]);
        }
    }

    const search = (txt, type) => {
        if (type === "real") {
            let temp = realPlayersState.filter((e) => e.name_for_game.toLowerCase().indexOf(txt.toLowerCase()) === 0);
            setRealPlayers([...temp]);
        } else {
            let temp = repPlayersState.filter((e) => e.name_for_game.toLowerCase().indexOf(txt.toLowerCase()) === 0);
            setRepPlayers([...temp]);
        }
    }

    const deletePlayer = (id) => {
        dispatch(loading(true));
        deletePlayerData(id)
            .then(() => closeLoadingWithDelay())
            .catch(() => closeLoadingWithDelay());

        function closeLoadingWithDelay() {
            setTimeout(() => {
                dispatch(loading(false));
            }, 1000);
        }
    }

    const deletePlayers = (selectedIdsPlayers, setReset) => {
        if (selectedIdsPlayers.match(/,/g)?.length > 0) {
            selectedIdsPlayers.split(",").forEach((id) => {
                deletePlayer(id);
            });
            setReset();
        } else {
            deletePlayer(selectedIdsPlayers);
            setReset();
        }
    }

    const invitePlayer = (ids) => {
        dispatch(loading(true));
        invitePlayerData(ids).then(() => {
            setRealPlayersSelect("");
            dispatch(loading(false));
            dispatch(
                setErrorMessage(
                    true,
                    t("Done"),
                    t("alert_mail_send"),
                    false,
                )
            )
        }).catch(() => {
            dispatch(loading(false));
            dispatch(
                setErrorMessage(
                    true,
                    t("oops"),
                    t("alert_mail_error")
                )
            );
        })
    }

    useMemo(() => {
        // mapPlayers(players);
        setRealPlayers(realPlayersSelector?.filter((e) => e.is_represented === "0"));
        setRepPlayers(repPlayersSelector?.filter((e) => e.is_represented === "1"));
        setRealPlayersState(realPlayersSelector?.filter((e) => e.is_represented === "0"));
        setRepPlayersState(repPlayersSelector?.filter((e) => e.is_represented === "1"));
    }, [realPlayersSelector, repPlayersSelector]);
    

    const scrollTo = (type, ref) => {
        if (type === "+") {
            ref.scrollTo({
                y: heightS * 100,
                x: 0,
                animated: "auto",
            });
        } else {
            ref.scrollTo({
                y: heightS * -100,
                x: 0,
                animated: "auto",
            });
        }
    }

    return (
        <View style={styles.container}>
            {/* left - real players */}
            {realPlayersState && realPlayersState.length > 0 ?
                <View style={styles.realPlayers}>
                    <OptionsListBtn
                        flagAll={realSelectAll}
                        keyHeaderText={"real"}
                        onChangeText={(txt) => search(txt, "real")}
                        onPressAddItem={() => dispatch(openRealPlayer("", true))}
                        onPressCheckbox={() => {
                            if (realSelectAll) {
                                setRealSelectAll(false);
                                setRealPlayersSelect("");
                            } else {
                                setRealSelectAll(true);
                                addAllPlayers("real");
                            }
                        }}
                        invitePlayer={() => invitePlayer(realPlayersSelect)}
                        onPressDeleteItems={() => deletePlayers(realPlayersSelect, () => setRealPlayersSelect(""))}
                        selectedItems={realPlayersSelect}
                        sortPlayers={sortPlayers}
                        dark={true}
                        listOrTable={true}
                        sortAndMail={true}
                    />
                    <ScrollView ref={realPlayerScrollRef} style={{ flex: 1, paddingHorizontal: widthS * 10 }} showsVerticalScrollIndicator={false}>
                        {realPlayers.map((player, i) => {
                            return (
                                <DraggedPlayer
                                    items={realPlayers}
                                    key={i}
                                    index={i}
                                    indexCurrent={indexCurrent}
                                    setIndexCurrent={(i) => setIndexCurrent(i)}
                                    indexFlag={indexReal}
                                    height={heightS * 55}
                                    refScroll={realPlayerScrollRef}
                                    scrollTo={(type) => scrollTo(type, realPlayerScrollRef.current)}
                                    setIndex={(i) => setIndexReal(i)}
                                    zIndex={indexReal === i ? 5 : 4}
                                    setNewIndex={(index, newIndex) => setNewIndex(index, newIndex, "real")}
                                    setNewIndexState={(index, newIndex) => setNewIndexState(index, newIndex, "real")}
                                    renderItem={
                                        <Pressable key={i}
                                            style={({ hovered, focused, actived }) => [styles.realP, { backgroundColor: hovered || actived || realPlayersSelect.indexOf(player.id) >= 0 ? background.infoHeader : background.infoBox }]}
                                            onPress={() => dispatch(openRealPlayer(player, true))}
                                            onPressOut={() => setIndexReal()}
                                        >
                                            {({ hovered, focused, actived }) => (
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1, userSelect: "none" }}>
                                                    {(hovered || actived) &&
                                                        <TouchableHighlight underlayColor={""} style={styles.menuBtn} activeOpacity={1} onPressIn={() => setIndexReal(i)}>
                                                            <Image source={{ uri: images.menuIcon }} style={styles.iconMenu} />
                                                        </TouchableHighlight>}
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                                                        <Pressable
                                                            style={[styles.checkBoxView, { backgroundColor: background.infoBody }]}
                                                            onPress={() => {
                                                                if (realPlayersSelect.indexOf(player.id) >= 0) {
                                                                    setRealPlayersSelect((e) => e = e.replace(`${player.id},`, ""));
                                                                    setRealSelectAll(false);
                                                                } else {
                                                                    setRealPlayersSelect((e) => e += `${player.id},`);
                                                                }
                                                            }}>
                                                            {realPlayersSelect.indexOf(player.id) >= 0 && <View style={[styles.checkBoxfill, { backgroundColor: background.count }]} />}
                                                        </Pressable>
                                                        <View style={[styles.userIconView, { borderColor: color.logoColor, shadowColor: color.logoColor }]}>
                                                            <Image source={{ uri: player.profile_image ? player.profile_image : images.user_image }} style={styles.userIcon} />
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 9 }}>
                                                        <Text selectable={false} style={[styles.title, { color: color.buildText, width: widthS * 60, textAlign: "center" }]}>{player.real_name ? player.real_name : ""}</Text>
                                                        <Text selectable={false} style={[styles.details, { color: color.buildText, width: widthS * 60, textAlign: "center" }]}>{ShortenWord(player.name ? player.name : player.name_for_game, 15)}</Text>
                                                        <Text selectable={false} style={[styles.details, { color: color.buildText, width: widthS * 90, textAlign: "center" }]}>{ShortenWord(player.email, 20)}</Text>
                                                        {/* <Text selectable={false} style={[styles.details, { color: color.buildText }]}>{player.deparment ? player.deparment : `Management`}</Text> */}
                                                        <Text selectable={false} style={[styles.details, { color: color.buildText, width: widthS * 80, textAlign: "center" }]}>{player.permission_for_game && typesSelectOptions.find((e) => e.value === player.permission_for_game).name}</Text>
                                                        {player.active === "false" && <TouchableHighlight
                                                            onPress={() => {
                                                                invitePlayer(player.id);
                                                            }}
                                                            style={[styles.btnInvite, { backgroundColor: background.infoBody }]}
                                                        >
                                                            <Image source={{ uri: images.mail }} style={styles.iconPlus} />
                                                        </TouchableHighlight>}
                                                        <View style={[styles.dot, { backgroundColor: player.color_status }]} />
                                                    </View>
                                                </View>
                                            )}
                                        </Pressable>}
                                />
                            )
                        })}
                    </ScrollView>
                </View>
                :
                //  left real players without players yet 
                <View style={styles.realPlayers}>
                    <Text style={[styles.headers, { color: color.buildText, marginBottom: heightS * 10, marginLeft: widthS * 10 }]}>{t("real")}</Text>
                    <View style={[styles.addPlayer, { backgroundColor: background.infoBox }]}>
                        <Pressable
                            style={[styles.btnPlus, { backgroundColor: background.infoBody }]}
                            onPress={() => dispatch(openRealPlayer("", true))}
                        >
                            <Image source={{ uri: images.plusIcon }} style={styles.iconPlus} />
                        </Pressable>
                        <Text style={[styles.headers2, { color: color.buildText }]}>{t("add_real_p")}</Text>
                    </View>
                </View>
            }
            {/* left represented players */}
            {repPlayersState && repPlayersState.length > 0 ?
                <View style={[styles.repPlayers, { backgroundColor: background.infoBox }]}>
                    {/* options */}
                    <OptionsListBtn
                        keyHeaderText={"represented"}
                        onPressCheckbox={() => {
                            if (repSelectAll) {
                                setRepSelectAll(false);
                                setRepPlayersSelect("");
                            } else {
                                setRepSelectAll(true);
                                addAllPlayers("rep");
                            }
                        }}
                        flagAll={repSelectAll}
                        onChangeText={(txt) => search(txt, "rep")}
                        selectedItems={repPlayersSelect}
                        onPressDeleteItems={() => deletePlayers(repPlayersSelect, () => setRepPlayersSelect(""))}
                        onPressAddItem={() => dispatch(openRepPlayer("", true))}
                        listOrTable={true}
                    />
                    <ScrollView ref={repPlayerScrollRef} style={{ flex: 1, paddingHorizontal: widthS * 10 }} showsVerticalScrollIndicator={false}>
                        {repPlayers && repPlayers.map((player, i) => {
                            return (
                                <DraggedPlayer
                                    items={repPlayers}
                                    key={i}
                                    index={i}
                                    refScroll={repPlayerScrollRef}
                                    scrollTo={(type) => scrollTo(type, repPlayerScrollRef.current)}
                                    indexCurrent={indexCurrent}
                                    setIndexCurrent={(i) => setIndexCurrent(i)}
                                    indexFlag={indexRep}
                                    height={heightS * 55}
                                    setIndex={(i) => setIndexRep(i)}
                                    zIndex={indexRep === i ? 5 : 4}
                                    setNewIndex={(index, newIndex) => setNewIndex(index, newIndex, "rep")}
                                    setNewIndexState={(index, newIndex) => setNewIndexState(index, newIndex, "rep")}
                                    renderItem={<Pressable key={i}
                                        style={({ hovered, focused, actived }) => [styles.realP, { backgroundColor: hovered || actived || repPlayersSelect.indexOf(player.id) >= 0 ? background.infoHeader : background.infoBody }]}
                                        onPress={() => dispatch(openRepPlayer(player, true))}
                                        onPressOut={() => setIndexRep()}
                                    >
                                        {({ hovered, focused, actived }) => (
                                            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1, userSelect: "none" }}>
                                                {(hovered || actived) &&
                                                    <TouchableHighlight underlayColor={""} style={styles.menuBtn} activeOpacity={1} onPressIn={() => setIndexRep(i)} >
                                                        <Image source={{ uri: images.menuIcon }} style={styles.iconMenu} />
                                                    </TouchableHighlight>}
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                                                    <Pressable
                                                        style={[styles.checkBoxView, { backgroundColor: background.infoBox }]}
                                                        onPress={() => {
                                                            if (repPlayersSelect.indexOf(player.id) >= 0) {
                                                                setRepPlayersSelect((e) => e = e.replace(`${player?.id},`, ""));
                                                                setRepSelectAll(false);
                                                            } else {
                                                                setRepPlayersSelect((e) => e += `${player?.id},`);
                                                            }
                                                        }}>
                                                        {repPlayersSelect.indexOf(player.id) >= 0 && <View style={[styles.checkBoxfill, { backgroundColor: background.count }]} />}
                                                    </Pressable>
                                                    <View style={[styles.userIconView, { borderColor: color.logoColor, shadowColor: color.logoColor }]}>
                                                        <Image source={{ uri: player.profile_image ? player.profile_image : images.user_image }} style={styles.userIcon} />
                                                    </View>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 4, marginRight: widthS * 13 }}>
                                                    <Text selectable={false} style={[styles.details, { color: color.buildText, marginLeft: widthS * 16 }]}>{ShortenWord(player.name ? player.name : player.name_for_game, 30)}</Text>
                                                    {/* <Text selectable={false} style={[styles.details, { color: color.buildText }]}>{player.deparment ? player.deparment : `Management`}</Text> */}
                                                </View>
                                            </View>
                                        )}
                                    </Pressable>}
                                />
                            )
                        })}
                    </ScrollView>
                </View>
                :
                // left represented players without players yet
                <View style={[styles.repPlayers, { backgroundColor: background.infoBox }]}>
                    <Text style={[styles.headers, { color: color.buildText, marginBottom: heightS * 10, marginLeft: widthS * 10 }]}>{t("represented")}</Text>
                    <View style={[styles.addPlayer, { backgroundColor: background.infoBody }]}>
                        <Pressable
                            style={[styles.btnPlus, { backgroundColor: background.infoBox }]}
                            onPress={() => dispatch(openRepPlayer("", true))}
                        >
                            <Image source={{ uri: images.plusIcon }} style={styles.iconPlus} />
                        </Pressable>
                        <Text style={[styles.headers2, { color: color.buildText }]}>{t("add_rep_p")}</Text>
                    </View>
                </View>
            }
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
        },
        realPlayers: {
            flex: 2,
            paddingVertical: widthS * 20,
            paddingHorizontal: widthS * 10,
            display: "flex",
            flexDirection: "column"
        },
        repPlayers: {
            flex: 1,
            paddingVertical: widthS * 20,
            paddingHorizontal: widthS * 10,
            height: "100%",
        },
        headers: {
            fontSize: widthS * 15.6,
            fontWeight: "300",
        },
        headers2: {
            fontSize: widthS * 10,
            fontWeight: "300",
        },
        addPlayer: {
            height: heightS * 45,
            borderRadius: widthS * 13,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingHorizontal: widthS * 12
        },
        btnPlus: {
            width: widthS * 23,
            height: widthS * 23,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: widthS * 10
        },
        btnInvite: {
            width: widthS * 18,
            height: widthS * 18,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: width * 15
        },
        iconPlus: {
            width: widthS * 11,
            height: widthS * 11,
            resizeMode: "contain",
        },
        checkBoxView: {
            width: widthS * 12,
            height: widthS * 12,
            borderRadius: widthS * 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: widthS * 6
        },
        checkBoxfill: {
            width: widthS * 6,
            height: widthS * 6,
            borderRadius: widthS * 2,
        },
        realP: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: heightS * 45,
            marginBottom: heightS * 10,
            borderRadius: widthS * 16,
            paddingHorizontal: widthS * 15,
            zIndex: 3
        },
        userIconView: {
            borderRadius: "50%",
            borderWidth: 2,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.8,
            shadowRadius: 15
        },
        userIcon: {
            width: widthS * 24,
            height: widthS * 24,
            borderRadius: "50%",
        },
        title: {
            fontWeight: "Catamaran_800ExtraBold",
            fontSize: widthS * 9,
            marginLeft: widthS * 10
        },
        details: {
            fontWeight: "Catamaran_300Light",
            fontSize: widthS * 8,
        },
        dot: {
            width: widthS * 8,
            height: widthS * 8,
            borderRadius: "50%"
        },
        menuBtn: {
            position: "absolute",
            top: heightS * -5,
            left: widthS * 1,
            width: widthS * 10,
            height: widthS * 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        iconMenu: {
            width: widthS * 6,
            height: widthS * 6,
            resizeMode: "contain"
        }
    });
}