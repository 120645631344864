import { t } from 'i18n-js';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { StyleSheet, View, TextInput, Text, Keyboard } from 'react-native';
import { useSelector } from 'react-redux';

export default function Objectives({ objectives, setInfoObj }) {

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const styles = stylesR(color);

    const strategyRef = useRef(null);

    const [rows, setRows] = useState([{ text: "", height: 0 }]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [focusRequired, setFocusRequired] = useState(true)
    const inputRefs = useRef([]);

    useEffect(() => {
        if (focusRequired && inputRefs.current[currentIndex]) {
            inputRefs.current[currentIndex].focus();
            setFocusRequired(false); // אחרי שהפוקוס נקבע, אפס את הצורך לקביעת פוקוס
        }
    }, [currentIndex, focusRequired]);

    useEffect(() => {
        setRows(objectives);
    }, [objectives]);

    const handleKeyDown = (e, index) => {
        if (e.nativeEvent.key === 'Enter') {
            e.preventDefault();
            const newRows = [...rows];
            newRows.splice(index + 1, 0, { text: "", height: 0 });
            setRows(newRows);
            setCurrentIndex(index + 1);
            setFocusRequired(true);
        } else if (e.nativeEvent.key === 'Backspace' && rows[index].text === '') {
            e.preventDefault();
            if (rows.length > 1 && index > 0) {
                const newRows = [...rows];
                newRows.splice(index, 1);
                setRows(newRows);
                setCurrentIndex(index - 1 >= 0 ? index - 1 : 0);
                setFocusRequired(true);
            }
        } else if (e.nativeEvent.key === 'ArrowUp') {
            e.preventDefault();
            if (index > 0) {
                setCurrentIndex(index - 1);
                setFocusRequired(true);
            }
        } else if (e.nativeEvent.key === 'ArrowDown') {
            e.preventDefault();
            if (index < rows.length - 1) {
                setCurrentIndex(index + 1);
                setFocusRequired(true);
            }
        }
    };

    const handleChangeText = (text, index) => {
        setRows(currentRows => {
            const newRows = [...currentRows];
            newRows[index] = { ...newRows[index], text: text }; // אל תאפס את הגובה כאן
            return newRows;
        });
    };

    const handleContentSizeChange = (event, index) => {
        const contentHeight = event.nativeEvent.contentSize.height;
        setRows(currentRows => {
            const newRows = [...currentRows];
            newRows[index] = { ...newRows[index], height: contentHeight }; // שמירה על הטקסט הקיים, רק עדכון הגובה
            return newRows;
        });
    };

    function onHoverOutSetBlur({ target }) {
        let temp = rows.length > 1 ? `${rows.map(e => e.text).join("@!$&*")}` : `${rows[0].text}@!$&*`;
        setInfoObj(temp);
    }

    useEffect(() => {
        if (strategyRef.current) {
            strategyRef.current.addEventListener("mouseleave", onHoverOutSetBlur);
            return () => {
                strategyRef.current?.removeEventListener("mouseleave", onHoverOutSetBlur)
            }
        }
    }, [strategyRef.current, rows])

    return (
        <View ref={strategyRef} style={{ direction: direction }}>
            {rows.map((row, index) => (
                <View key={index} style={styles.row} >
                    <Text selectable={false} style={{ alignSelf: "flex-start", color: color.infoHeaderNavBorder, fontSize: "2vw", lineHeight: "1vw" }}>{'\u2022'}</Text>
                    <TextInput
                        onChangeText={(text) => handleChangeText(text?.replace("%", ""), index)}
                        onKeyPress={(e) => handleKeyDown(e, index)}
                        value={row.text}
                        ref={(ref) => {
                            inputRefs.current[index] = ref; // אוסף רפרנסים לכל ה-TextInput
                        }}
                        multiline
                        onContentSizeChange={(event) => handleContentSizeChange(event, index)}
                        style={[styles.input, { height: row.height ? row.height : 20 }]}
                        blurOnSubmit={false} // מונע בליעת event של 'Enter'
                        placeholder={index === 0 && row.text === '' && rows.length === 1 ? t("simulation_objectives") : ''}
                        placeholderTextColor="gray"
                    />
                </View>
            ))}
        </View>
    );
}

const stylesR = (color) => StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "center",
        marginVertical: 5,
        maxWidth: '100%',
        gap: "0.5vw"
    },
    input: {
        textAlignVertical: 'center',
        borderWidth: 0, // הסרת הגבול
        width: '100%',
        minHeight: "1.5vw",
        color: color.buildText,
        fontFamily: "Catamaran_500Medium",
        transtions: "all .5s",
        fontSize: "0.9vw",
        outlineStyle: 'none',
        backgroundColor: 'transparent', // רקע שקוף אם יש צורך
    },
});
